import { Directive, HostBinding } from "@angular/core";
import { Lang, LangSection, LangText } from "../../types/language";
import * as english from './en.json';
import * as french from './fr.json';

@Directive()
export abstract class LocalizableComponent {
  langSection = LangSection;
  langText = LangText;

  @HostBinding('class.french') get isFrench() {
    return this.getLanguage() === Lang.French;
  }

  private languageCode: Lang;
  private languageModel: any;

  constructor() {
    this.languageCode = localStorage.getItem('lang') as Lang | undefined ||
      navigator.languages.find(x => x.indexOf('-') === -1 && (x == 'fr' || x == 'en')) as Lang | undefined ||
      Lang.English;

    this.languageModel = ((
      this.languageCode === Lang.French ? french
        : english) as any).default;
  }

  /**
   * Used to retrieve the correct language variant text
   */
  localize(section: LangSection, key: LangText) {
    if (!this.languageModel[section] || !this.languageModel[section][key]) {
      //console.log(`Localization text not found: ${section}.${key}`);
      return localStorage.getItem('show_lang_errors') ? `FIX_ME (${section}.${key})` : '';
    }
    return this.languageModel[section][key];
  }

  /**
   * Used to convert content that is already in English to the correct language variant
   * @example Text coming back from the API
   */
  localizeValue(section: LangSection, value: string) {
    let lowerValue = value?.toLowerCase() ?? '';
    const key = Object.keys(this.languageModel[section]).find(key => ((english as any).default[section][key])?.toLowerCase() === lowerValue);
    if (!key) {
      //console.log(`No conversion value found for ${section}.${key}`);
      return value;
    }

    return this.languageModel[section][key];
  }

  getLanguageCode() {
    return this.languageCode;
  }

  private getLanguage(): Lang {
    return this.languageCode;
  }
}
