import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/app.state';

@Component({
  selector: 'notification-location',
  templateUrl: './notification-location.component.html',
  styleUrls: ['./notification-location.component.scss']
})
export class NotificationLocationComponent {
  @Input() countryCode: string;
  @Input() slicNumber: string;

  slicName: string;
  customName: string;
  address: string;

  emptyAddressRegex = /^[\s,]+$/;
  disconnect$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(locationList => {
        let location = locationList?.locations?.find(l => l.countryCode === this.countryCode && l.slicNumber === this.slicNumber);
        if (location) {
          this.slicName = location.slicName;
          this.customName = location.profile.customName;
          this.address = location.profile.address;
        }
      });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }
}
