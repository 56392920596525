  export interface Toast {
    id: string;
    text: string;
    duration: number;
    callback?: () => void;
    hideDismissIcon?: boolean;
    timer?: number;
    toastType?: ToastType;
  }

  export enum ToastType {
    Error = 0,
    Info = 1,
    Success = 2
  }