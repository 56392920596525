import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AppState } from '../app.state';
import { AuthService } from '../auth/auth.service';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { DateService } from '../services/date.service';
import { MaintenanceService } from '../services/maintenance.service';
import { refreshTrailerRequests, trailerRequestStatusChange, updateTrailerRequest } from "../store/actions/trailer-requests.actions";
import { DestinationInfo } from '../types/destinationInfo';
import { DestinationSortInfo } from '../types/destinationSortInfo';
import { DocumentStatus } from '../types/documentStatus';
import { DropdownOption } from '../types/dropdownOption';
import { LocationProfile } from '../types/locationProfile';
import { LocationTrailer } from '../types/locationTrailer';
import { Role } from '../types/role';
import { RoutingRule } from '../types/routingRule';
import { TrailerRequest } from '../types/trailerRequest';
import { TrailerRequestResponse } from '../types/trailerRequestResponse';
import { DefaultResponsibleSiteInformationModalComponent } from './default-responsible-site-information-modal/default-responsible-site-information-modal.component';

@Component({
  selector: 'request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() requestId;

  breadcrumbs: { url: string, name: string }[] = [
    { url: '', name: this.localize(this.langSection.Breadcrumb, this.langText.Home) }
  ];
  is12Hour: boolean;
  isNewRequest: boolean = false;
  isDirty: boolean = false;
  customerLocation: string;
  originalSlicName: string;
  model: TrailerRequest | null = null;
  submitting: boolean = false;
  defaultResponsibleSitePhone: string;
  defaultResponsibleSiteEmail: string;
  defaultResponsibleSiteCompanyName : string;

  disconnect$: Subject<boolean> = new Subject<boolean>();

  destinationSortOptions = [
    { name: "Afternoon", value: "A" },
    { name: "Customer", value: "C" },
    { name: "Day", value: "D" },
    { name: "Empty", value: "E" },
    { name: "Local", value: "L" },
    { name: "Morning", value: "M" },
    { name: "Night", value: "N" },
    { name: "Preload", value: "P" },
    { name: "Retain", value: "R" },
    { name: "Sunrise", value: "S" },
    { name: "Twilight", value: "T" },
    { name: "Dropship", value: "X" }
  ];

  serviceLevelOptions = [
    { name: "Early AM", value: "EAM" },
    { name: "Next Day Air", value: "1DA" },
    { name: "Second Day Air", value: "2DA" },
    { name: "Three Day Select", value: "3DS" },
    { name: "Ground", value: "GRD" }
  ];

  //Hardcoded Peak season dates. TODO: Make configurable
  //peakSeasonStartDate: Date = new Date(2021, 11 - 1, 20, 0, 0, 0);
  //peakSeasonEndDate: Date = new Date(2022, 1 - 1, 15, 23, 59, 59);
  peakSeasonStartDate: Date = new Date(2019, 10 - 1, 12, 0, 0, 0);
  peakSeasonEndDate: Date = new Date(2020, 10 - 1, 23, 23, 59, 59);
  peakSeasonWarning: string = null;

  set selectedDestination(slic: string) {
    const hadDestination = !!this.model.destinationOrganizationAbbreviatedName;
    const hadDestinationSortCode = !!this.model.destinationSortCode;
    const hadTrailerType = !!this.model.trailerType;
    const hadServiceLevel = !!this.model.serviceLevel;
    if (this.destinationInfos && this.destinationInfos.length > 0) {
      this.model.destinationOrganizationAbbreviatedName = slic;
      this.model.destinationOrganizationNumber = this.destinationInfos.find(dest => slic == dest.slicAbbreviation)?.slicNumber;
      this.model.destinationSortCode = '';
      this.model.trailerType = '';
      this.model.serviceLevel = '';

      this.refineDropdowns().then(() => {
        this.validateEmptyFieldsIfLostValue(hadDestination, hadDestinationSortCode, hadTrailerType, hadServiceLevel);
        this.validateLoadedTrailerInfo();

        if (this.selectedDestination && this.isNewRequest && (!this.sortTypes?.filter(s => s.value).length || 
        (this.model.destinationSortCode && (!this.serviceLevels?.length || !this.trailerTypes?.length)))) {
          this.showDefaultResponsibleSiteInformationModal();
        }
      });
    } else {
      this.validateEmptyFieldsIfLostValue(hadDestination, hadDestinationSortCode, hadTrailerType, hadServiceLevel);
    }
  }

  get selectedDestination() {
    return this.model.destinationOrganizationAbbreviatedName;
  }

  set destinationSortCode(sortCode: string) {
    const hadDestinationSortCode = !!this.model.destinationSortCode;
    const hadTrailerType = !!this.model.trailerType;
    const hadServiceLevel = !!this.model.serviceLevel;

    if (this.destinationInfos && this.destinationInfos.length > 0) {
      this.model.destinationSortCode = sortCode;
      this.model.trailerType = '';
      this.refineDropdowns().then(() => {
        this.validateEmptyFieldsIfLostValue(false, hadDestinationSortCode, hadTrailerType, hadServiceLevel);
        this.validateLoadedTrailerInfo();

        if (this.isNewRequest && (!this.serviceLevels?.length || !this.trailerTypes?.length)) {
          this.showDefaultResponsibleSiteInformationModal();
        }
      });
    } else {
      this.validateEmptyFieldsIfLostValue(false, hadDestinationSortCode, hadTrailerType, hadServiceLevel);
    }
  }

  get destinationSortCode() {
    return this.model.destinationSortCode;
  }

  get showWeekendAlert() {
    const day = new Date().getDay();
    return day === 0 || day === 6;
  }

  submissionErrors: { [field: string]: string } = {};
  destinationInfos: DestinationInfo[] = [];

  destinations: DropdownOption[] = [{ value: "", name: "" }];
  trailerTypes: DropdownOption[] = [{ value: "", name: "" }];
  serviceLevels: DropdownOption[] = [{ value: "", name: "" }];
  sortTypes: DropdownOption[] = [{ value: "", name: "" }];

  earliestPickupDate: Date | null = null;
  earliestPickupHour: string | number | null = null;
  earliestPickupMinute: string | number | null = null;
  earliestPickupAmPm: string = "AM";
  earliestPickupDateIsDirty: boolean = false;
  earliestPickupHourElement: ElementRef;
  earliestPickupMinuteElement: ElementRef;
  earliestPickupAmPmSelectIsDirty: boolean = false;

  @ViewChild('earliestHours') set earliestHours(content: ElementRef) {
    if (content) {
      this.earliestPickupHourElement = content;
    }
  }

  @ViewChild('earliestMinutes') set earliestMinutes(content: ElementRef) {
    if (content) {
      this.earliestPickupMinuteElement = content;
    }
  }

  latestPickupDate: Date | null = null;
  latestPickupHour: string | number | null = null;
  latestPickupMinute: string | number | null = null;
  latestPickupAmPm: string = "AM";
  latestPickupDateIsDirty: boolean = false;
  latestPickupHourElement: ElementRef;
  latestPickupMinuteElement: ElementRef;
  latestPickupAmPmSelectIsDirty: boolean = false;

  @ViewChild('latestHours') set latestHours(content: ElementRef) {
    if (content) {
      this.latestPickupHourElement = content;
    }
  }

  @ViewChild('latestMinutes') set latestMinutes(content: ElementRef) {
    if (content) {
      this.latestPickupMinuteElement = content;
    }
  }

  locationProfile: LocationProfile = null;
  locationOffsetDifference: number = 0;
  hasValidationErrors: boolean = false;
  receivedBadResponse: boolean = false;
  originalServiceLevel: string = null;
  navigationSlic: string = null;

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    centered: true
  };

  constructor(private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private dateService: DateService,
    private store: Store<AppState>,
    private authService: AuthService,
    private modalService: NgbModal,
    private maintenanceService: MaintenanceService) {
    super();

    this.is12Hour = this.getLanguageCode() === 'en';

    const navigationState = this.router.getCurrentNavigation()?.extras?.state;
    if (navigationState) {
      this.navigationSlic = navigationState.location;
    }
  }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.disconnect$))
      .subscribe(params => {
        if (this.requestId) {
          //If a request ID is provided, we're editing a request. Pull it down via the API and set the model.
          this.apiService.getTrailerRequest(this.requestId).pipe(takeUntil(this.disconnect$)).subscribe(async data => await this.onRequestLoaded(data));
        } else if (params?.requestType && this.getTypeFromSlug(params?.requestType) !== null && params?.location) {
          this.store
            .select(s => s.locationList)
            .pipe(takeUntil(this.disconnect$))
            .subscribe(state => {
              this.locationProfile = state.locations.find(loc => params.location == loc.slicName)?.profile;
              if (!!this.locationProfile && !this.model) {
                this.locationOffsetDifference = this.locationProfile.timezoneOffset ? this.dateService.getTimezoneOffsetDifferenceInMs(this.locationProfile.timezoneOffset) : 0;
                if (this.locationProfile.documentStatus == DocumentStatus.SoftDelete
                  || !this.authService.hasLocationRole(this.locationProfile.slicName, Role.User) || this.locationProfile.defaultResponsibleSite == null || this.locationProfile.numberOfDestinations < 1) {
                  this.router.navigateByUrl('/add-request');
                } else {
                  //A specific request type was specified, so pre-fill our new request
                  this.isNewRequest = true;
                  this.model = new TrailerRequest(this.getTypeFromSlug(params.requestType), params.location);
                  this.setCustomerLocationAndLoadDropdowns(params.location);
                  this.breadcrumbs.push({ url: '/add-request', name: this.localize(this.langSection.Breadcrumb, this.langText.AddNewRequest) });
                  this.breadcrumbs.push({ url: `add-request/${params.requestType}`, name: this.localizeValue(this.langSection.RequestType, this.model.requestType) });
                  this.originalSlicName = this.locationProfile ? this.locationProfile.countryCode + '::' + this.locationProfile.slicNumber + '::' + this.locationProfile.slicName : '';
                }
                this.setDefaultUSEarliestAndLatestDates();
                this.setDefaultResponsibleSitePhoneAndEmail();
              }
            }
            );
        } else if (!this.requestId && !params?.requestType) {
          //If no request ID or type is provided, we must be adding a new request. Instantiate a blank request.
          this.isNewRequest = true;
          this.model = new TrailerRequest("", "");
          this.breadcrumbs.push({ url: '/add-request', name: this.localize(this.langSection.Breadcrumb, this.langText.AddNewRequest) });
        }
      });

  }

  setDefaultResponsibleSitePhoneAndEmail() {
    const splitResponsibleSite = this.locationProfile?.defaultResponsibleSite?.split(" - ");
    let responsibleSiteCountryCode = "";
    let responsibleSiteSlicNumber = "";

    if (splitResponsibleSite?.length == 3) {
      responsibleSiteCountryCode = splitResponsibleSite[2];
      responsibleSiteSlicNumber = splitResponsibleSite[1];
    }

    if (responsibleSiteCountryCode && responsibleSiteSlicNumber) {
      this.apiService.getLocation(responsibleSiteCountryCode, responsibleSiteSlicNumber)
        .pipe(take(1)).subscribe(responsibleSite => {
          this.defaultResponsibleSitePhone = responsibleSite.telephoneNumber;
          this.defaultResponsibleSiteEmail = responsibleSite.email;
          this.defaultResponsibleSiteCompanyName = responsibleSite.customer || responsibleSite.crdSlicName;
        });
    }
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleNavigateFromApp($event) {
    if (!this.maintenanceService.maintenance.getValue() && this.isDirty) {
      $event.preventDefault();
      $event.returnValue = `${this.localize(this.langSection.Term, this.langText.AreYouSure)}\n${this.localize(this.langSection.RequestFormPage, this.langText.IfYouContinue)}, ${this.localize(this.langSection.RequestFormPage, this.isNewRequest ? this.langText.ThisRequestWillNotBeCreated : this.langText.ThisRequestWillNotBeSaved)}.`;
    }
  }

  private setCustomerLocationAndLoadDropdowns(location: string) {
    this.customerLocation = location;
    this.checkNonDestinationTrailerTypes();
    this.loadDestinationInfo();
  }

  private validateEmptyFieldsIfLostValue(hadDestination: boolean, hadDestinationSortCode: boolean, hadTrailerType: boolean, hadServiceLevel: boolean) {
    if (hadDestination && this.model.destinationOrganizationAbbreviatedName == '') {
      this.validate("destinationOrganizationAbbreviatedName");
    }

    if (hadDestinationSortCode && this.model.destinationSortCode == '') {
      this.validate("destinationSortCode");
    }

    if (hadTrailerType && this.model.trailerType == '') {
      this.validate("trailerType");
    }

    if (hadServiceLevel && this.model.serviceLevel == '') {
      this.validate("serviceLevel");
    }
  }

  async refineDropdowns() {
    const info = this.destinationInfos.find(x => x.slicAbbreviation === this.selectedDestination);
    if (!info) {
      this.sortTypes = [{ value: "", name: "" }];
      this.serviceLevels = [{ value: "", name: "" }];
      this.trailerTypes = [{ value: "", name: "" }];
      return;
    }
    const blankOption: DropdownOption = { name: '', value: '' };

    let sortTypes = this.getSortTypesForDropdown(info.sortInfos, blankOption);
    this.sortTypes = await this.addMissingSortTypeIfNotPresent(sortTypes);
    if (this.sortTypes.length == 1) {
      this.model.destinationSortCode = this.sortTypes[0].value;
    }

    let serviceLevels = this.getAvailableServiceLevels(info, this.model.destinationSortCode, this.earliestPickupDate)
      ?.map(level => ({ name: level.name, value: level.serviceCode }))
      ?? [{ value: "", name: "" }];
    if (serviceLevels.length > 1) {
      serviceLevels = [blankOption, ...serviceLevels];
    }
    this.serviceLevels = await this.addMissingServiceLevelIfNotPresent(serviceLevels);
    if (this.serviceLevels.length == 0) {
      this.model.serviceLevel = '';
    } else if (this.serviceLevels.length == 1) {
      this.model.serviceLevel = this.serviceLevels[0].value;
    }
    this.originalServiceLevel = null; // no need to keep track of this after dropdowns are populated for the first time

    let trailerTypes = this.getTrailerTypesForDropdowns(info.sortInfos, this.destinationSortCode, blankOption);
    this.trailerTypes = await this.addMissingTrailerTypeIfNotPresent(trailerTypes);
    if (this.trailerTypes.length === 1) {
      this.model.trailerType = this.trailerTypes[0].value;
    }

    this.localizeDropdowns();
  }

  async addMissingServiceLevelIfNotPresent(serviceLevels: DropdownOption[]): Promise<DropdownOption[]> {
    if (!this.isNewRequest && this.model.serviceLevel && !serviceLevels.find(s => this.model.serviceLevel == s.value)) {
      let option = this.serviceLevelOptions.find(st => this.model.serviceLevel == st.value);
      if (option) {
        serviceLevels.push({ name: option.name, value: option.value });
      }
    }

    return serviceLevels;
  }

  getSortTypesForDropdown(sortInfos: DestinationSortInfo[], defaultSelection: DropdownOption = { name: "", value: "" }) {
    let sortTypes: DropdownOption[] = []
    sortTypes = sortInfos?.map(x => ({ name: x.name, value: x.sortCode })) || sortTypes;
    if (sortTypes.length > 1) {
      return [defaultSelection, ...sortTypes];
    } else {
      return sortTypes;
    }
  }

  async addMissingSortTypeIfNotPresent(sortTypes: DropdownOption[]): Promise<DropdownOption[]> {
    if (!this.isNewRequest && this.model.destinationSortCode && !sortTypes.find(st => this.model.destinationSortCode == st.value)) {
      let option = this.destinationSortOptions.find(st => this.model.destinationSortCode == st.value);
      if (option) {
        sortTypes.push({ name: option.name, value: option.value });
      }
    }

    return sortTypes;
  }

  getTrailerTypesForDropdowns(sortInfos: DestinationSortInfo[], destinationSortCode: string, defaultSelection: DropdownOption = { name: "", value: "" }): DropdownOption[] {
    let trailerTypesList: DropdownOption[] = [];

    if (destinationSortCode) {
      const equipment = sortInfos?.find(x => x.sortCode === destinationSortCode);
      if (equipment) {
        const locationTrailers = (equipment.locationTrailers || []).reduce((list, trailer) => {
          if (trailer.equipmentAllowedAtLocationIndicator && trailer.isDefault && trailer.groupName?.trim())
            return [...list, trailer];
          else
            return [...list];
        }, []) as LocationTrailer[];

        if (locationTrailers.length > 0 && destinationSortCode !== '') {
          trailerTypesList = locationTrailers.map(x => ({ name: x.groupName, value: x.trailerTypeCode }));
        }
      }
    }

    if (trailerTypesList.length > 1) {
      return [defaultSelection, ...trailerTypesList];
    } else {
      return trailerTypesList;
    }
  }

  async addMissingTrailerTypeIfNotPresent(trailerTypes: DropdownOption[]): Promise<DropdownOption[]> {
    if (!this.isNewRequest && this.model.trailerType && !trailerTypes.find(type => type.value == this.model.trailerType)) {
      let type = await this.apiService.getTrailerInfo(this.model.trailerType).toPromise();
      if (type) {
        let existingGroup = trailerTypes.find(existing => existing.name === type.group);
        if (existingGroup) {
          if (this.trailerTypeDisabled) { // keep original value if it is disabled
            existingGroup.value = this.model.trailerType;
          }
          else { // use existing group type
            this.model.trailerType = existingGroup.value;
          }
        }
        else {
          trailerTypes.push({ name: type.group, value: this.model.trailerType });
        }
      }
    }

    return trailerTypes;
  }

  get trailerTypeDisabled() {
    return !['PENDING', 'APPROVED', 'SCHEDULED'].includes(this.model?.requestStatus?.toUpperCase());
  }

  getAvailableServiceLevels(destination: DestinationInfo, sort: string, day: Date): { serviceCode: string; name: string; }[] {
    if (destination && sort && day) {
      let serviceLevels: string[];
      let sortInfo: DestinationSortInfo;
      let dayOfTheWeek = day.toLocaleDateString('en-US', { weekday: 'short' });

      // Check routing rules
      sortInfo = destination.sortInfos?.find(info => info.sortCode == sort);
      if (sortInfo) {
        let rule: RoutingRule = sortInfo.routingRules?.find(rule => rule.daysOfTheWeek?.includes(dayOfTheWeek) ?? false);
        serviceLevels = (rule?.serviceLevels ?? sortInfo.defaultServiceLevels) ?? [];

        return destination.serviceInfos?.filter(service => serviceLevels.includes(service.serviceCode)
          || (!this.isNewRequest && this.hasServiceLevel && this.originalServiceLevel == service.serviceCode)) || []; // include original service level, if editing a request
      }
    }

    // service levels could not be determined above. For example, if the sort was removed from the destination list
    if (!this.isNewRequest && this.hasServiceLevel) {
      return destination.serviceInfos?.filter(service => this.originalServiceLevel == service.serviceCode) || []; // include original service level, if editing a request
    }

    return [];
  }

  get hasServiceLevel(): boolean {
    return ['Live Load', 'Retrieve Load', 'Switch'].includes(this.model.requestType);
  }

  localizeDropdowns() {
    this.serviceLevels = this.serviceLevels.map(item => ({
      ...item,
      name: this.localizeValue(this.langSection.ServiceLevel, item.name)
    }));

    this.sortTypes = this.sortTypes.map(item => ({
      ...item,
      name: this.localizeValue(this.langSection.Sort, item.name)
    })).reduce((uniques, sort) => uniques.some(x => x.name === sort.name) ? uniques : [...uniques, sort], []);

    this.trailerTypes = this.trailerTypes.map(item => ({
      ...item,
      name: this.localizeValue(this.langSection.TrailerType, item.name)
    }));
  }

  //Retrieves destinations, sort types, and service levels from the API and creates dropdowns for them
  loadDestinationInfo() {
    this.apiService.getDestinationInfo(this.customerLocation)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(async destinations => {
        if (this.model.requestCategory === 'Planned' || !destinations || destinations.length === 0) {
          this.fillFallbackDestinationDropdowns();
        }
        else {
          if (['Request Empty', 'Retrieve Empty'].includes(this.model.requestType)) {
            this.checkNonDestinationTrailerTypes();
          }
          else {
            const blankOption: DropdownOption = { name: '', value: '' };

            if (this.model.destinationOrganizationAbbreviatedName && !destinations.find(o => o.slicAbbreviation == this.model.destinationOrganizationAbbreviatedName)) {
              destinations.push({
                slicAbbreviation: this.model.destinationOrganizationAbbreviatedName,
                slicNumber: this.model.destinationOrganizationNumber
              } as DestinationInfo);
            }

            this.destinationInfos = destinations;
            this.destinations = [
              blankOption,
              ...destinations.map(dest =>
                ({ name: !dest.customer ? dest.slicAbbreviation : dest.slicAbbreviation + " - " + dest.customer, value: dest.slicAbbreviation })
              )
            ];

            await this.refineDropdowns();
          }
        }
      });
  }

  checkNonDestinationTrailerTypes() { // Need to fix this as well to add missing option
    if (['Request Empty', 'Retrieve Empty'].includes(this.model?.requestType)) {
      this.apiService.getLocationNames(this.customerLocation)
        .pipe(takeUntil(this.disconnect$))
        .subscribe(list => {
          const foundSlic = list.find(x => x.slicName.toLowerCase() === this.customerLocation.toLowerCase());
          if (foundSlic) {
            this.apiService.getTrailerTypes(foundSlic.countryCode + '::' + foundSlic.slicNumber + '::' + foundSlic.slicName)
              .pipe(takeUntil(this.disconnect$))
              .subscribe(async types => {
                const blankOption: DropdownOption = { name: '', value: '' };
                let trailerTypes = types.map(t => ({ name: t.groupName, value: t.trailerTypeCode }));
                if (trailerTypes.length > 1) {
                  trailerTypes = [blankOption, ...trailerTypes];
                }

                trailerTypes = await this.addMissingTrailerTypeIfNotPresent(trailerTypes);
                this.trailerTypes = trailerTypes;
                if (this.trailerTypes.length === 1) {
                  this.model.trailerType = this.trailerTypes[0].value;
                }

                this.localizeDropdowns();
              });
          }
        });
    }
  }

  //When editing a request, this function is called with that request's data from the API
  async onRequestLoaded(data: TrailerRequestResponse) {
    if (data) {
      this.originalSlicName = data.originalSlicName;
      const customerSlic = data.originalSlicName.split('::')[2];
      this.model = new TrailerRequest(data.requestType, customerSlic, data);

      Object.keys(data).forEach(dataKey => {
        if (typeof (this.model[dataKey]) !== 'undefined') {
          this.model[dataKey] = data[dataKey];
        }
      });

      this.store
        .select(s => s.locationList)
        .pipe(takeUntil(this.disconnect$))
        .subscribe(state => {
          this.locationProfile = state.locations.find(loc => customerSlic == loc.slicName)?.profile;
          if ((this.locationProfile?.documentStatus == DocumentStatus.SoftDelete && (data.attentionNeeded || data.requestStatus === "Pending")) || data.driverCreated) {
            if (!this.canEditLocation(customerSlic)) {
              this.router.navigate(['gateway']);
            }
            else {
              this.router.navigateByUrl("/");
            }
          } else if (this.locationProfile?.forceReadOnly || this.locationProfile?.locationEnabled === false) {
            this.router.navigate(['gateway']);
          }
        });

      if (data.earliestPickupDateTime) {
        this.earliestPickupDate = new Date(data.earliestPickupDateTime);
        this.earliestPickupHour = this.earliestPickupDate.getHours();
        this.earliestPickupMinute = (this.earliestPickupDate.getMinutes()).toString().padStart(2, '0');
        this.earliestPickupAmPm = this.earliestPickupHour >= 12 ? 'PM' : 'AM';
        if (this.is12Hour) {
          if (this.earliestPickupHour > 12) {
            this.earliestPickupHour -= 12;
          }
          if (this.earliestPickupHour === 0) {
            this.earliestPickupHour = 12;
          }
        }
        this.earliestPickupHour = this.earliestPickupHour.toString().padStart(2, '0');
      }

      if (data.latestPickupDateTime) {
        this.latestPickupDate = new Date(data.latestPickupDateTime);
        this.latestPickupHour = this.latestPickupDate.getHours();
        this.latestPickupMinute = (this.latestPickupDate.getMinutes()).toString().padStart(2, '0');
        this.latestPickupAmPm = this.latestPickupHour >= 12 ? 'PM' : 'AM';
        if (this.is12Hour) {
          if (this.latestPickupHour > 12) {
            this.latestPickupHour -= 12;
          }
          if (this.latestPickupHour === 0) {
            this.latestPickupHour = 12;
          }
        }
        this.latestPickupHour = this.latestPickupHour.toString().padStart(2, '0');
      }

      this.originalServiceLevel = this.model.serviceLevel;
      await this.refineDropdowns();

      this.setCustomerLocationAndLoadDropdowns(customerSlic);

      this.breadcrumbs.push({ url: '', name: `${this.localize(this.langSection.Term, this.langText.Edit)} ${this.localizeValue(this.langSection.RequestType, this.model.requestType)}` });
    } else {
      this.model = new TrailerRequest("", ""); //TODO: Show error?
    }
  }

  canEditLocation(slic: string) {
    return this.authService.hasLocation(slic) && (this.authService.hasLocationRole(slic, Role.User) || this.authService.hasLocationRole(slic, Role.Admin));
  }

  fillFallbackDestinationDropdowns() {
    // If the user is editing a pending request and the location has no
    // destination information, pull that information from the request
    // to display.
    const hasDestinationFacility = ['Live Load', 'Retrieve Load', 'Switch'].includes(this.model.requestType);
    if (hasDestinationFacility) {
      const destOption = this.destinationOption(this.model.destinationOrganizationAbbreviatedName);
      this.destinations = [
        destOption
      ];
    }

    const hasDestinationSortCode = ['Retrieve Load', 'Switch'].includes(this.model.requestType);
    if (hasDestinationSortCode) {
      const sortOption = this.sortTypeOption(this.model.destinationSortCode);
      this.sortTypes = [
        sortOption
      ];
    }

    if (this.hasServiceLevel) {
      const serviceOption = this.serviceLevelOption(this.model.serviceLevel);
      this.serviceLevels = [
        serviceOption
      ];
    }

    const hasTrailerType = ['Request Empty', 'Retrieve Empty', 'Retrieve Load', 'Switch'].includes(this.model.requestType);
    if (hasTrailerType) {
      this.fillTrailerTypeOption(this.model.trailerType);
    }

    this.localizeDropdowns();
  }

  //Called when a value changes in the form
  onFieldChange(fieldChange: RequestFormFieldChange) {
    this.model[fieldChange.field] = fieldChange.value;
    this.isDirty = true; //Mark the form as dirty/modified

    //Special case: when the destination changes, we need to re-request the dropdown options linked to the destination
    if (fieldChange.field === "destinationOrganizationAbbreviatedName") {
      this.loadDestinationInfo();
    }

    this.validate(fieldChange.field);
  }

  onDestinationChange(destination: string) {
    this.isDirty = true;
    this.selectedDestination = destination;
    this.validateLoadedTrailerInfo();
  }

  onSortChange(sortCode: string) {
    this.isDirty = true;
    this.destinationSortCode = sortCode;
    this.validateLoadedTrailerInfo();
  }

  showDefaultResponsibleSiteInformationModal() {
    this.ngbModalOptions.modalDialogClass = `default-responsible-site-info-${this.getLanguageCode()}`
    const modalRef = this.modalService.open(DefaultResponsibleSiteInformationModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.defaultResponsibleSitePhone = this.defaultResponsibleSitePhone;
    modalRef.componentInstance.defaultResponsibleSiteEmail = this.defaultResponsibleSiteEmail;
    modalRef.componentInstance.defaultResponsibleSiteCompanyName = this.defaultResponsibleSiteCompanyName;

    return modalRef;
  }

  isDisabled(field: string) {
    if (!this.model.requestId) return false;

    switch (field) {
      case "earliestPickupDateTime":
        return !['Pending'].some(status => status === this.model.requestStatus) || this.model.trailerRequestData.attentionNeeded;
      case "latestPickupDateTime":
        return !['Pending'].some(status => status === this.model.requestStatus) || this.model.trailerRequestData.attentionNeeded;
    }

    return true;
  }

  /* Earliest Date Time */
  async onEarliestPickupDate(date: Date | null) {
    this.earliestPickupDateIsDirty = true;
    this.earliestPickupDate = date;
    this.setEarliestPickupDateTime();

    if (['Request Empty', 'Retrieve Empty'].includes(this.model.requestType)) {
      this.checkNonDestinationTrailerTypes();
    }
    else {
      await this.refineDropdowns();
    }
  }

  onEarliestPickupHour(value: string) {
    this.earliestPickupHour = value;
    this.setEarliestPickupDateTime();
  }

  onEarliestPickupMinute(value: string) {
    this.earliestPickupMinute = value;
    this.setEarliestPickupDateTime();
  }

  onEarliestPickupAmPm(value: string) {
    this.earliestPickupAmPmSelectIsDirty = true;
    this.earliestPickupAmPm = value;
    this.setEarliestPickupDateTime();
  }

  /* Latest Date Time */
  onLatestPickupDate(date: Date | null) {
    this.latestPickupDateIsDirty = true;
    this.latestPickupDate = date;
    this.setLatestPickupDateTime();
  }

  onLatestPickupHour(value: string) {
    this.latestPickupHour = value;
    this.setLatestPickupDateTime();
  }

  onLatestPickupMinute(value: string) {
    this.latestPickupMinute = value;
    this.setLatestPickupDateTime();
  }

  onLatestPickupAmPm(value: string) {
    this.latestPickupAmPmSelectIsDirty = true;
    this.latestPickupAmPm = value;
    this.setLatestPickupDateTime();
  }

  destinationOption(destination: string) {
    let dest = destination;
    let val = destination;

    if (!dest) {
      dest = 'Unspecified';
      val = '';
    }

    let defaultOption: DropdownOption = { name: dest, value: val };
    return defaultOption;
  }

  serviceLevelOption(level: string) {
    let code = this.localizeValue(this.langSection.ServiceLevel, level);
    let desc = !code ? '' : this.serviceLevelOptions.find(o => o.value == level)?.name;

    if (!desc) {
      desc = 'Unspecified'; // localize?
    }

    let defaultOption: DropdownOption = { name: desc, value: code };
    return defaultOption;
  }

  sortTypeOption(sort: string) {
    let code = this.localizeValue(this.langSection.Sort, sort);
    let desc = !code ? '' : this.destinationSortOptions.find(o => o.value == sort)?.name;

    if (!desc) {
      desc = 'Unspecified'; // localize?
    }

    let defaultOption: DropdownOption = { name: desc, value: code };
    return defaultOption;
  }

  fillTrailerTypeOption(trailerType: string) {
    if (this.model.originOrganizationAbbreviatedName) {
      this.apiService.getTrailerTypes(this.model.originOrganizationCountryCode + '::' + this.model.originOrganizationSlicNumber + '::' + this.model.originOrganizationAbbreviatedName)
        .pipe(takeUntil(this.disconnect$))
        .subscribe(trailers => {
          if (trailers) {
            let thisTrailer = trailers.find(trailer => trailer['TrailerTypeCode'] === trailerType);
            if (thisTrailer) {
              let defaultOption: DropdownOption = { name: thisTrailer.groupName, value: thisTrailer.trailerTypeCode };
              this.trailerTypes = [
                defaultOption
              ];
            }
            else {
              this.apiService.getTrailerInfo(trailerType)
                .pipe(takeUntil(this.disconnect$))
                .subscribe(trailer => {
                  if (trailer) {
                    let defaultOption: DropdownOption = { name: trailer.group, value: trailerType };
                    this.trailerTypes = [
                      defaultOption
                    ];
                  }
                  else {
                    let defaultOption: DropdownOption = { name: trailerType, value: trailerType };
                    this.trailerTypes = [
                      defaultOption
                    ];
                  }
                });
            }
          }
        });
    }
    else if (trailerType) {
      this.apiService.getTrailerInfo(trailerType)
        .pipe(takeUntil(this.disconnect$))
        .subscribe(trailer => {
          if (trailer) {
            let defaultOption: DropdownOption = { name: trailer.group, value: trailerType };
            this.trailerTypes = [
              defaultOption
            ];
          }
          else {
            let defaultOption: DropdownOption = { name: trailerType, value: trailerType };
            this.trailerTypes = [
              defaultOption
            ];
          }
        });
    }
    else {
      console.log('No trailer type specified to fill trailer options');
    }
  }

  //Performs validation on the entire form or the specific field
  validate(fieldName?: string) {
    let errors = {} as any;

    const isEarliestDateDirty: boolean = this.earliestPickupDateIsDirty ||
      this.earliestPickupHourElement.nativeElement.classList.contains('ng-dirty') ||
      this.earliestPickupMinuteElement.nativeElement.classList.contains('ng-dirty') ||
      this.earliestPickupAmPmSelectIsDirty;

    const isEarliestDateEmpty: boolean = !this.earliestPickupDate || !this.earliestPickupMinute || !this.earliestPickupHour;

    const isLatestDateDirty: boolean = this.latestPickupDateIsDirty ||
      this.latestPickupHourElement.nativeElement.classList.contains('ng-dirty') ||
      this.latestPickupMinuteElement.nativeElement.classList.contains('ng-dirty') ||
      this.latestPickupAmPmSelectIsDirty;

    const isLatestDateEmpty: boolean = !this.latestPickupDate || !this.latestPickupMinute || !this.latestPickupHour;

    //Earliest pickup
    if ((!fieldName || fieldName === "earliestPickupDateTime") && (this.isNewRequest || this.model.requestStatus === 'Pending') && isEarliestDateDirty) {
      this.validateEarliestDateTime(errors);
    }

    //Latest pickup
    if ((!fieldName || fieldName === "latestPickupDateTime") && (this.isNewRequest || this.model.requestStatus === 'Pending') && isLatestDateDirty) {
      this.validateLatestDateTime(errors);
    }
    else if (!isLatestDateDirty && isEarliestDateDirty && this.earliestPickupDate > this.latestPickupDate) {
      errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeInTheFuture);
    }

    //Required Checks
    if (isEarliestDateEmpty && (!fieldName || isEarliestDateDirty)) {
      errors.earliestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.ThisFieldIsRequired);
    }

    if (isLatestDateEmpty && (this.submitting || this.submissionErrors['latestPickupDateTime'] || isLatestDateDirty)) {
      errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.ThisFieldIsRequired);
    }

    this.validateRequiredFields(errors, fieldName);

    this.validatePeakSeason();

    //Check to see if we have errors
    return this.reportErrors(errors, fieldName);
  }

  validateLoadedTrailerInfo() {
    if (this.submissionErrors.destinationOrganizationAbbreviatedName) {
      this.validate("destinationOrganizationAbbreviatedName");
    }

    if (this.submissionErrors.destinationSortCode) {
      this.validate("destinationSortCode");
    }

    if (this.submissionErrors.trailerType) {
      this.validate("trailerType");
    }

    if (this.submissionErrors.serviceLevel) {
      this.validate("serviceLevel");
    }
  }

  //Submits the form model to the API
  submit() {
    this.submitting = true;
    this.hasValidationErrors = false;
    this.receivedBadResponse = false;

    try {
      if (!this.validate()) {
        this.submitting = false;
        this.hasValidationErrors = true;
        return;
      }
    } catch {
      this.submitting = false;
      this.hasValidationErrors = true;
      return;
    }

    const data = this.model.trailerRequestData;
    data.slicName = this.customerLocation;
    if (this.isNewRequest) {
      if (this.locationProfile && this.locationProfile.timezoneOffset) {
        // Must set or we won't make adjustments for time zones
        //console.log('Creating new request, location ' + this.locationProfile.slicName + ', time zone: ' + this.locationProfile.timezoneName + ', time zone offset: ' + this.locationProfile.timezoneOffset);
        this.model.trailerRequestData.timezoneName = this.locationProfile.timezoneName;
        this.model.trailerRequestData.timezoneOffset = this.locationProfile.timezoneOffset;
      }
      else {
        //console.log('*** Creating trailer request, no location profile, request slic: ' + this.customerLocation);
        this.model.trailerRequestData.timezoneName = 'Unk';
        this.model.trailerRequestData.timezoneOffset = 0;
      }
      data.originalSlicName = this.originalSlicName ? this.originalSlicName : '';

      this.apiService.createRequest(data).pipe(takeUntil(this.disconnect$)).subscribe(response => this.handleRequestPostResponse(response), errors => this.handleRequestErrorResponse(errors));
    } else {
      // Assume the time zone offsets are preset
      this.apiService.editRequest(data).pipe(takeUntil(this.disconnect$)).subscribe(response => this.handleRequestPostResponse(response, data), errors => this.handleRequestErrorResponse(errors));
    }
  }

  onCancelClick() {
    this.router.navigateByUrl("/", { replaceUrl: true });
  }

  private handleRequestPostResponse(response: any, request: TrailerRequestResponse = null) {
    if (response?.ok || (response instanceof HttpErrorResponse && response.status === 504)) {
      if (this.isNewRequest) {
        this.store.dispatch(trailerRequestStatusChange()); // make sure pending status count is up to date
        setTimeout(() => this.store.dispatch(refreshTrailerRequests()), 5000);
      } else {
        this.store.dispatch(updateTrailerRequest(this.requestId, request));
      }
      this.isDirty = false;
      this.router.navigateByUrl("/", { replaceUrl: true });
    }
  }

  private handleRequestErrorResponse(response: HttpErrorResponse) {
    this.submitting = false;
    if (response?.error && response.error['errors']) {
      for (const { field, error } of response.error.errors) {
        this.submissionErrors[field] = error;
      }

      this.hasValidationErrors = true;
    }
    else {
      this.receivedBadResponse = true;
    }
  }

  //Converts the slug name for a request type (from the URL) to a friendly name
  private getTypeFromSlug(slug: string) {
    switch (slug) {
      case 'live-load':
        return 'Live Load';
      case 'request-empty':
        return 'Request Empty';
      case 'retrieve-empty':
        return 'Retrieve Empty';
      case 'retrieve-load':
        return 'Retrieve Load';
      case 'switch':
        return 'Switch';
      case 'realign':
        return 'REALIGN';
      default:
        return null;
    }
  }

  /* Functions for compiling date time values and setting on model */
  private setEarliestPickupDateTime() {
    this.isDirty = true; //Even if they have only picked one piece of the request datetime, make the form dirty to mark changes
    if (this.earliestPickupDate === null || this.earliestPickupHour === null || this.earliestPickupMinute === null) {
      this.model.earliestPickupDateTime = null;
      return;
    }

    let hours: number;
    if (typeof (this.earliestPickupHour) === "string")
      hours = parseInt(this.earliestPickupHour);
    else
      hours = this.earliestPickupHour as number;

    if (this.is12Hour) {
      if (this.earliestPickupAmPm === "PM" && hours < 12) {
        hours += 12;
      }
      if (this.earliestPickupAmPm === "AM" && hours === 12) {
        hours = 0;
      }
    }

    const date = new Date(this.earliestPickupDate);
    date.setHours(hours);
    if (typeof (this.earliestPickupMinute) === "string")
      date.setMinutes(parseInt(this.earliestPickupMinute));
    else
      date.setMinutes(this.earliestPickupMinute as number);

    if (this.isValidDate(date))
      this.model.earliestPickupDateTime = date.toISOString();

    this.validate('earliestPickupDateTime');
    this.validate('latestPickupDateTime');

    this.validatePeakSeason();
  }

  private isValidDate(date: Date) {
    return date instanceof Date && !isNaN(date.getTime());
  }

  private setLatestPickupDateTime() {
    this.isDirty = true; //Even if they have only picked one piece of the request datetime, make the form dirty to mark changes
    if (this.latestPickupDate === null || this.latestPickupHour === null || this.latestPickupMinute === null) {
      this.model.latestPickupDateTime = null;
      return;
    }

    let hours;
    if (typeof (this.latestPickupHour) === "string")
      hours = parseInt(this.latestPickupHour);
    else
      hours = this.latestPickupHour as number;

    if (this.is12Hour) {
      if (this.latestPickupAmPm === "PM" && hours < 12) {
        hours += 12;
      }
      if (this.latestPickupAmPm === "AM" && hours === 12) {
        hours = 0;
      }
    }

    const date = new Date(this.latestPickupDate);
    date.setHours(hours);
    if (typeof (this.latestPickupMinute) === "string")
      date.setMinutes(parseInt(this.latestPickupMinute));
    else
      date.setMinutes(this.latestPickupMinute as number);

    if (this.isValidDate(date))
      this.model.latestPickupDateTime = date.toISOString();

    this.validate('latestPickupDateTime');
    this.validate('earliestPickupDateTime');

    this.validatePeakSeason();
  }

  private validatePeakSeason() {
    let showPeakSeasonWarning = false;

    if ((!this.model.requestCategory || this.model.requestCategory === 'Ad Hoc') &&
      this.earliestPickupDate &&
      this.earliestPickupDate >= this.peakSeasonStartDate &&
      this.earliestPickupDate <= this.peakSeasonEndDate) {

      let earliestDate = new Date(this.earliestPickupDate.getFullYear(), this.earliestPickupDate.getMonth(), this.earliestPickupDate.getDate(), 0, 0, 0);

      // if we have the location's offset (we should) use location's current time
      let currentDateTime = (this.locationProfile && this.locationProfile.timezoneOffset) ?
        this.dateService.getDateTimeForLocation(this.locationProfile.timezoneOffset) : new Date();

      // Do not show warning if after 5 am if earliest date 
      let currentDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), 0, 0, 0);
      let currentDateTimeLimit = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), 5, 0, 0);

      showPeakSeasonWarning = this.dateService.datesAreEqual(earliestDate, currentDate) ? currentDateTime <= currentDateTimeLimit ? true : false : true;

    } else {
      showPeakSeasonWarning = false;
    }

    if (showPeakSeasonWarning) {
      this.peakSeasonWarning =
        this.localize(this.langSection.RequestFormPage, this.langText.PeakSeasonDateRangeWarning) +
        this.dateService.getLocalizedMonthDateFormat(this.earliestPickupDate) + '.';
    } else {
      this.peakSeasonWarning = null;
    }
  }

  private setDefaultUSEarliestAndLatestDates(): void {
    if (this.isNewRequest && !this.earliestPickupDate && !this.latestPickupDate) {
      const currentDate = this.dateService.getDateTimeForLocation(this.locationProfile.timezoneOffset);
      currentDate.setHours(0, 0, 0, 0);

      if (!this.locationProfile?.enableSameDayRequests) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.earliestPickupDate = currentDate;
      this.latestPickupDate = currentDate;
    }
  }

  private isValidTime(hours: string | number, minutes: string | number): boolean {
    const isValidHour = (this.is12Hour && +hours > 0 && +hours < 13) ||
      (!this.is12Hour && +hours >= 0 && +hours < 24);
    const isValidMinute = +minutes >= 0 && +minutes < 60;

    return isValidHour && isValidMinute;
  }

  private validateEarliestDateTime(errors: any) {
    const earliest = new Date(this.model.earliestPickupDateTime);
    const maxAllowedEarliest = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);
    const latest = new Date(this.model.latestPickupDateTime);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (earliest.getTime() + this.locationOffsetDifference < new Date().getTime()) {
      // Check time zone
      if (this.locationProfile && this.locationProfile.timezoneOffset) {
        if (earliest.getTime() < this.dateService.getDateTimeForLocation(this.locationProfile.timezoneOffset).getTime()) {
          errors.earliestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeInTheFuture);
        }
      }
      else {
        errors.earliestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeInTheFuture);
      }
    }
    if (earliest.getTime() > maxAllowedEarliest) {
      errors.earliestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeWithin30DaysFromToday);
    }
    if (this.model.latestPickupDateTime && earliest.getTime() >= latest.getTime()) {
      errors.earliestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeBeforeLatestPickup);
    }
    if (this.locationProfile && !this.locationProfile.enableSameDayRequests && new Date(earliest.getFullYear(), earliest.getMonth(), earliest.getDate()) <= currentDate) {
      errors.earliestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeInTheFuture);
    }
    if (!this.isValidTime(this.earliestPickupHour, this.earliestPickupMinute)) {
      errors.earliestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.InvalidTime);
    }
  }

  private validateLatestDateTime(errors: any) {
    const earliest = new Date(this.model.earliestPickupDateTime);
    const latest = new Date(this.model.latestPickupDateTime);
    const maxAllowedLatest = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (latest.getTime() + this.locationOffsetDifference < new Date().getTime()) {
      // Check time zone
      if (this.locationProfile && this.locationProfile.timezoneOffset) {
        if (latest.getTime() < this.dateService.getDateTimeForLocation(this.locationProfile.timezoneOffset).getTime()) {
          errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeInTheFuture);
        }
      }
      else {
        errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeInTheFuture);
      }
    }
    if (this.model.earliestPickupDateTime && latest.getTime() <= earliest.getTime()) {
      errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeAfterEarliestPickup);
    }
    if (latest.getTime() > maxAllowedLatest) {
      errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeWithin30DaysFromToday);
    }
    if (this.locationProfile && !this.locationProfile.enableSameDayRequests && new Date(latest.getFullYear(), latest.getMonth(), latest.getDate()) <= currentDate) {
      errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.MustBeInTheFuture);
    }
    if (!this.isValidTime(this.latestPickupHour, this.latestPickupMinute)) {
      errors.latestPickupDateTime = this.localize(this.langSection.RequestFormPage, this.langText.InvalidTime);
    }
  }

  private validateRequiredFields(errors: any, fieldName: string = '') {
    //Not all form types will contain these fields. Constrain validation for them by request type.
    const hasTrailerType = ['Request Empty', 'Retrieve Empty', 'Retrieve Load', 'Switch'].includes(this.model.requestType);
    const hasDestinationFacility = ['Live Load', 'Retrieve Load', 'Switch'].includes(this.model.requestType);
    const hasDestinationSortCode = ['Live Load', 'Retrieve Load', 'Switch'].includes(this.model.requestType);
    const hasServiceLevel = ['Live Load', 'Retrieve Load', 'Switch'].includes(this.model.requestType);
    const hasPiecePalletPercent = ['Retrieve Load', 'Switch'].includes(this.model.requestType);
    const hasPiecePallet = ['Live Load'].includes(this.model.requestType);

    if (hasTrailerType && (this.isNewRequest || ['Pending', 'Approved', 'Scheduled'].some(x => x === this.model.requestStatus)) && (!fieldName || fieldName === "trailerType") && !this.model.trailerType) {
      errors.trailerType = this.localize(this.langSection.RequestFormPage, this.langText.ThisFieldIsRequired);
    }

    if (hasDestinationFacility && (this.isNewRequest || this.model.requestStatus === "Pending") && (!fieldName || fieldName === "destinationOrganizationAbbreviatedName") && !this.model.destinationOrganizationAbbreviatedName) {
      errors.destinationOrganizationAbbreviatedName = this.localize(this.langSection.RequestFormPage, this.langText.ThisFieldIsRequired);
    }

    if (hasDestinationSortCode && (this.isNewRequest || this.model.requestStatus === "Pending") && (!fieldName || fieldName === "destinationSortCode") && !this.model.destinationSortCode) {
      errors.destinationSortCode = this.localize(this.langSection.RequestFormPage, this.langText.ThisFieldIsRequired);
    }

    if (hasServiceLevel && (this.isNewRequest || this.model.requestStatus === "Pending") && (!fieldName || fieldName === "serviceLevel") && !this.model.serviceLevel) {
      errors.serviceLevel = this.localize(this.langSection.RequestFormPage, this.langText.ThisFieldIsRequired);
    }

    if (hasPiecePalletPercent && (!fieldName || ['pieceCount', 'palletCount', 'percentFull'].some(t => t === fieldName)) && !this.model.pieceCount && !this.model.palletCount && !this.model.percentFull) {
      errors.pieceCount = " ";
      errors.palletCount = " ";
      errors.percentFull = " ";
    } else if (hasPiecePallet && (!fieldName || ['pieceCount', 'palletCount'].some(t => t === fieldName)) && !this.model.pieceCount && !this.model.palletCount) {
      errors.pieceCount = " ";
      errors.palletCount = " ";
    }

    if (hasPiecePalletPercent || hasPiecePallet) {

      let hasOneValidEntry = false;

      if ((this.model.pieceCount && parseInt(this.model.pieceCount.toString()) !== 0) ||
        (this.model.palletCount && parseInt(this.model.palletCount.toString()) !== 0) ||
        (this.model.percentFull && parseInt(this.model.percentFull.toString()) !== 0)) {
        hasOneValidEntry = true;
      }

      if (!hasOneValidEntry && this.model.pieceCount && parseInt(this.model.pieceCount.toString()) === 0) {
        errors.pieceCount = this.localize(this.langSection.RequestFormPage, this.langText.MustBeGreaterThanZero);
      }

      if (!hasOneValidEntry && this.model.palletCount && parseInt(this.model.palletCount.toString()) === 0) {
        errors.palletCount = this.localize(this.langSection.RequestFormPage, this.langText.MustBeGreaterThanZero);
      }

      if (hasPiecePalletPercent) {
        if (this.model.percentFull && parseInt(this.model.percentFull.toString()) > 100) {
          errors.percentFullInvalid = this.localize(this.langSection.RequestFormPage, this.langText.MustBeValidPercent);
        } else if (!hasOneValidEntry && this.model.percentFull && parseInt(this.model.percentFull.toString()) === 0) {
          errors.percentFull = this.localize(this.langSection.RequestFormPage, this.langText.MustBeGreaterThanZero);
        }
      }
    }

    if ((!fieldName || fieldName === "requestReason") && !this.model.requestReason && this.isNewRequest) {
      errors.requestReason = this.localize(this.langSection.RequestFormPage, this.langText.ThisFieldIsRequired);
    }
  }

  private reportErrors(errors: any, fieldName: string = ''): boolean {
    if (Object.keys(errors).length > 0) {
      //Check if we are validating a single field
      if (fieldName) {
        //Piece and pallet count (and percentFull) are linked so we need special validation here
        if (['pieceCount', 'palletCount', 'percentFull'].some(x => x === fieldName)) {
          if (!errors['pieceCount'] && errors[fieldName] == null) {
            delete this.submissionErrors.pieceCount;
          } else if (errors['pieceCount']) {
            this.submissionErrors.pieceCount = errors['pieceCount'];
          } else if (errors[fieldName] == " ") {
            this.submissionErrors.pieceCount = " ";
          }

          if (!errors['palletCount'] && errors[fieldName] == null) {
            delete this.submissionErrors.palletCount;
          } else if (errors['palletCount']) {
            this.submissionErrors.palletCount = errors['palletCount'];
          } else if (errors[fieldName] == " ") {
            this.submissionErrors.palletCount = " ";
          }

          if (!errors['percentFull'] && errors[fieldName] == null) {
            delete this.submissionErrors.percentFull;
          } else if (errors['percentFull']) {
            this.submissionErrors.percentFull = errors['percentFull'];
          } else if (errors[fieldName] == " ") {
            this.submissionErrors.percentFull = " ";
          }

          if (!errors['percentFullInvalid']) {
            delete this.submissionErrors.percentFullInvalid;
          } else {
            this.submissionErrors.percentFullInvalid = errors['percentFullInvalid'];
          }

        } else { //Otherwise, just set the individual field's error
          if (errors[fieldName] == null) {
            delete this.submissionErrors[fieldName]
          } else {
            this.submissionErrors[fieldName] = errors[fieldName];
          }
        }
      } else { //Otherwise, treat the errors variable as ALL of the form errors that need to be fixed
        this.submissionErrors = errors;
      }

      //Return false since validation failed
      return false;
    } else {
      //Check if we're validating on an individual changed field
      if (fieldName) {
        //We need to do some special magic with piece and pallet count, and percentFull since they are linked.
        if (['pieceCount', 'palletCount', 'percentFull'].some(x => x === fieldName)) {
          if (this.submissionErrors.pieceCount) {
            delete this.submissionErrors.pieceCount;
          }

          if (this.submissionErrors.palletCount) {
            delete this.submissionErrors.palletCount;
          }

          if (this.submissionErrors.percentFull) {
            delete this.submissionErrors.percentFull;
          }

          if (fieldName === "percentFull") {
            delete this.submissionErrors.percentFullInvalid;
          }

        } else { //Otherwise, just delete this specific field's error
          delete this.submissionErrors[fieldName];
        }
      } else { //We're not doing an individual field, so set all of submissionErrors to blank
        this.submissionErrors = {};
      }

      if (this.hasValidationErrors && Object.keys(this.submissionErrors).length === 0) {
        this.hasValidationErrors = false;
      }

      //Return true since our validation rules passed
      return true;
    }
  }

}


export interface RequestFormFieldChange {
  field: string;
  value: string;
}

export * from './add-request/add-request.component';
export * from './live-load/live-load-request-form.component';
export * from './realign/realign-request-form.component';
export * from './request-empty/request-empty-request-form.component';
export * from './retrieve-empty/retrieve-empty-request-form.component';
export * from './retrieve-load/retrieve-load-request-form.component';
export * from './switch-request-form/switch-request-form.component';


