import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { VirtualScrollerComponent } from "@iharbeck/ngx-virtual-scroller";
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { StringService } from 'src/app/services/string.service';
import { debounceFunction } from "../../../decorators/debounceFunction";
import { AppState } from "../../app.state";
import { AuthService } from "../../auth/auth.service";
import { LocalizableComponent } from "../../components/localizable/localizable.component";
import * as locationListActions from "../../store/actions/location-list.actions";
import { DropdownOption } from '../../types/dropdownOption';
import { LangSection, LangText } from "../../types/language";
import { LocationInfo } from "../../types/locationInfo";
import { LocationSortType } from "../../types/locationSortType";
import { Role } from "../../types/role";


@Component({
  selector: 'location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})

/**
 * Maintains a list of locations visible to a customer.
 * Calls to the API for location data and for autocompleted names and keeps track of the selection location
 */
export class LocationListComponent extends LocalizableComponent implements AfterViewInit, OnDestroy, OnInit {
  locations: LocationInfo[] = [];
  filteredLocations: LocationInfo[] = [];
  selectedLocation: LocationInfo | null = null;
  selectedLocations: LocationInfo[] = [];
  locationQuery: string = '';
  showAllLocations: boolean = false; //set after loading from API, true if locationQuery was blank
  unloadedLocationCount: number = 0;
  locationsDisplayed: string = null;
  iconClass: string = 'search icon';
  containerTop: number = 0;
  containerHeight: number = 0;
  listHeight: number = 0;
  isFixed: boolean = false;
  showScrollbar: boolean = false;
  showScrollbarCount: number = 0;
  locationListScrollTop: number = 0;
  recalcInterval: any; //number = -1;
  disconnect$: Subject<boolean> = new Subject<boolean>(); //Change back once fix is made to call ngOnDestroy
  sortLocationValue: LocationSortType = LocationSortType.CODE;
  sortLocationAscending: boolean = true;
  sortOptions: DropdownOption[] = [
    { name: this.localize(this.langSection.HomePage, this.langText.LocationCode), value: LocationSortType.CODE },
    { name: this.localize(this.langSection.HomePage, this.langText.CustomLocationName), value: LocationSortType.CUSTOM_NAME },
    { name: this.localize(this.langSection.HomePage, this.langText.StateProvinceTerritory), value: LocationSortType.TERRITORY },
    { name: this.localize(this.langSection.HomePage, this.langText.TotalRequests), value: LocationSortType.TOTAL },
    { name: this.localize(this.langSection.HomePage, this.langText.PendingRequests), value: LocationSortType.PENDING },
    { name: this.localize(this.langSection.HomePage, this.langText.InProgressRequests), value: LocationSortType.IN_PROGRESS },
    { name: this.localize(this.langSection.HomePage, this.langText.CompletedRequests), value: LocationSortType.COMPLETED }
  ];

  get showLoadMoreButton() {
    return this.locations.length > 0 && this.unloadedLocationCount > 0;
  }

  @Output() locationsSelected: EventEmitter<LocationInfo[]> = new EventEmitter<LocationInfo[]>();
  @ViewChild('scroll') scroll: VirtualScrollerComponent;
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private stringService: StringService)
  {
    super();
  }

  ngOnInit() {
    combineLatest([
        this.store.select(s => s.locationList).pipe(takeUntil(this.disconnect$), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))),
        this.store.select(s => s.requestFilters).pipe(takeUntil(this.disconnect$), distinctUntilChanged((prev, curr) => JSON.stringify(prev.locations) === JSON.stringify(curr.locations)))
      ])
      .pipe(takeUntil(this.disconnect$))
      .subscribe(([locationList, filters]) => {
        this.locations = [...locationList.locations];
        this.locationQuery = locationList.query;
        this.sortLocationValue = locationList.sortType;
        this.sortLocationAscending = locationList.ascending;

        this.sortLocations();

        if (filters.locations.length === 0) {
          this.setSelection(this.locations.find(l => l.slicName === ''));
        } else {
          this.setMultipleSelection(this.locations.filter(l => filters.locations.includes(l.slicName)));
        }

        this.filterLocations();
    })

  }

  ngAfterViewInit() {
    this.captureHandles();
    
    this.recalcInterval = setInterval(() => {
      if(this.scroll && this.scroll['element']) {
        this.scroll['element'].nativeElement.scrollTop = this.locationListScrollTop;
      }
     }, 500); // as number;
  }

  ngOnDestroy() {
    clearInterval(this.recalcInterval);
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  self: HTMLDivElement;
  itemContainer: HTMLDivElement;
  container: HTMLDivElement;
  footer: HTMLDivElement;
  marginTop: number = 0;

  //Capture all of the elements necessary for doing bounds calculations. Do it once for performance.
  captureHandles() {
    this.container = document.querySelector('.location-list-container');
    this.self = document.querySelector('.location-list');
    this.itemContainer = document.querySelector('.location-list .location-items');
    this.footer = document.querySelector('.footer');
  }


  @debounceFunction(100)
  filterLocations() {
    const term = this.stringService.normalizeString(this.locationQuery.toLowerCase());

    this.showAllLocations = this.locationQuery === "";
    this.iconClass = this.locationQuery === "" ? 'search icon' : 'clear icon';
    this.filteredLocations = this.locations.filter(loc =>
      this.stringService.normalizeString(loc.slicName?.toLowerCase()).indexOf(term) > -1 ||
      this.stringService.normalizeString(loc.profile.customName?.toLowerCase()).indexOf(term) > -1 ||
      this.stringService.normalizeString(loc.profile.address?.toLowerCase()).indexOf(term) > -1
    );
    this.setLocationsDisplayed(this.showAllLocations ? this.filteredLocations.length - 1 : this.filteredLocations.length);
  }

  clearSearch() {
    this.locationQuery = "";
    this.setLocationListQuery(this.locationQuery);
  }

  setLocationListQuery(val: string) {
    this.locationListScrollTop = 0;
    this.store.dispatch(locationListActions.setLocationListQuery(val));
  }

  setLocationsDisplayed(count: number) {
    if (count !== -1) {
      this.locationsDisplayed = this.localize(LangSection.Term, LangText.Displaying) + ' ' + count + ' ';
      this.locationsDisplayed += count === 1 ? this.localize(LangSection.Term, LangText.Location) : this.localize(LangSection.Term, LangText.Locations);
    } else {
      this.locationsDisplayed = null;
    }
  }

  setSelection(location: LocationInfo) {
    // User selected "All Locations". Set selection list to just be "All Locations"
    if (location.slicName === '') {
      this.selectedLocations = [location];
    } else {
      if (this.isLocationSelected(location.slicName)) {
        this.selectedLocations = this.selectedLocations.filter(loc => loc.slicName !== location.slicName);

        // User untoggled all locations, revert to "All Locations" selection
        if (this.selectedLocations.length === 0)
          this.selectedLocations = [this.locations.find(l => l.slicName === '')];
      } else {
        // Remove "All Locations" from selected list once User selects anything else
        this.selectedLocations = this.selectedLocations.filter(loc => loc.slicName !== '');
        this.selectedLocations.push(location);

        if (this.selectedLocations.length === this.locations.length - 1)
          this.selectedLocations = [this.locations.find(l => l.slicName === '')];
      }
    }

    this.locationsSelected.emit(this.selectedLocations);
  }

  setMultipleSelection(locations: LocationInfo[]) {
    if (JSON.stringify(this.selectedLocations.sort()) === JSON.stringify(locations.sort()))
      return;
    
    this.selectedLocations = locations;
    this.locationsSelected.emit(this.selectedLocations);
 }

  isLocationSelected(locationSlic: string): boolean {
    return this.selectedLocations.some(sl => sl.slicName === locationSlic);
  }

  sortLocations() {
    this.locations.sort((locA, locB) => {
      let compare: number = 0 - this.compareAllLocations(locA, locB, 0);

      // always put all locations card at the top
      if (compare)
        return compare;

      // sort by selected criteria
      switch (this.sortLocationValue) {
        case LocationSortType.CUSTOM_NAME:
          compare = this.compareLocationCustomName(locA, locB);
          break;
        case LocationSortType.TERRITORY:
          compare = this.compareLocationTerritory(locA, locB);
          break;
        case LocationSortType.TOTAL:
          compare = this.compareMetric(locA.metrics.totalCount, locB.metrics.totalCount);
          break;
        case LocationSortType.PENDING:
          compare = this.compareMetric(locA.metrics.pendingCount, locB.metrics.pendingCount);
          break;
        case LocationSortType.IN_PROGRESS:
          compare = this.compareMetric(locA.metrics.inProgressCount, locB.metrics.inProgressCount);
          break;
        case LocationSortType.COMPLETED:
          compare = this.compareMetric(locA.metrics.completedCount, locB.metrics.completedCount);
          break;
      }

      if (compare === 0) {
        compare = this.compareLocationCode(locA, locB);
      }

      if (!this.sortLocationAscending)
        compare = 0 - compare;

      return compare;
    });
  }

  compareAllLocations(locA: LocationInfo, locB: LocationInfo, defaultCompare: number): number {
    let compare = defaultCompare;

    if (locA.slicNumber === '')
      compare = 1;
    else if (locB.slicNumber === '')
      compare = -1;

    return compare;
  }

  compareLocationCode(locA: LocationInfo, locB: LocationInfo) {
    let slicA = locA.slicName.toLowerCase();
    let slicB = locB.slicName.toLowerCase();
    return (slicA < slicB) ? -1 : (slicA > slicB) ? 1 : 0;
  }

  compareLocationCustomName(locA: LocationInfo, locB: LocationInfo) {
    let customNameA = (this.showCustomNameHyperlink(locA)) ? '' : locA.profile.customName.toLowerCase();
    let customNameB = (this.showCustomNameHyperlink(locB)) ? '' : locB.profile.customName.toLowerCase();
    return (customNameA < customNameB) ? -1 : (customNameA > customNameB) ? 1 : 0;
  }

  compareLocationTerritory(locA: LocationInfo, locB: LocationInfo) {
    let stateA = this.extractTerritory(locA.profile.address).toLowerCase();
    let stateB = this.extractTerritory(locB.profile.address).toLowerCase();

    return (stateA < stateB) ? -1 : (stateA > stateB) ? 1 : 0;
  }

  compareMetric(metricA: number, metricB: number) {
    metricA = metricA ?? 0;
    metricB = metricB ?? 0;

    return (metricA < metricB) ? -1 : (metricA > metricB) ? 1 : 0;
  }

  showCustomNameHyperlink(location: LocationInfo) {
    return location.slicName &&
      this.authService.hasLocationRole(location.slicName, Role.Admin) &&
      !location.profile.forceReadOnly &&
      (location.profile.customName.trim().length == 0 || (!location.profile.customNameLastUpdatedBy && location.slicName == location.profile.customName));
  }

  locationListScroll(event: Event) {
    const scrollTop = (event.target as HTMLElement).scrollTop;
    this.locationListScrollTop = scrollTop;
  }

  extractTerritory(address: String): String {
    let territory = address.match(/^.+,\s([^,\s]+)\s.+$/);
    if (territory && territory.length > 1)
      return territory[1];
    else
      return '';
  }

  onSortChange(val: LocationSortType) {
    this.store.dispatch(locationListActions.updateLocationSortFilter(val));
  }

  onSortClick() {
    this.store.dispatch(locationListActions.toggleLocationSortDirection());
  }
}
