import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { TrailerRequestPayload } from '../../types/trailerRequestPayload';
import { LocalizableComponent } from '../localizable/localizable.component';

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})

/**
 * Conains two datepickers that represen a range.
 * Input is an ISO value per date, output change event returns a DateRange (from, to) with ISO representations of each datepicker value
 */
export class DateRangePickerComponent extends LocalizableComponent {
  fromDate: Date | null = null;
  toDate: Date | null = null;
  lowerLimit: Date = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 30));
  upperLimit: Date = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 90));

  @Input()
  set from(payload: TrailerRequestPayload) {
    if (payload.fromDate) {
      this.fromDate = new Date(payload.fromDate);
    }
  }

  @Input()
  set to(payload: TrailerRequestPayload) {
    if (payload.toDate) {
      this.toDate = new Date(payload.toDate);
    }
  }

  @Input() showUnreadCommentMeatballs: boolean = false

  //Output a DateRange object with "from" and "to" properties
  @Output() onChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  constructor() {
    super();
  }

  //A new DateRange is emitted when a single datepicker value has changed
  emitChange() {
    this.fromDate?.setHours(0);
    this.fromDate?.setMinutes(0);
    this.fromDate?.setSeconds(0);

    this.toDate?.setHours(23);
    this.toDate?.setMinutes(59);
    this.toDate?.setSeconds(59);

    this.onChange.emit({
      from: this.fromDate ? moment(this.fromDate).format('YYYY-MM-DDTHH:mm:ss') : null,
      to: this.toDate ? moment(this.toDate).format('YYYY-MM-DDTHH:mm:ss') : null
    });
  }

  //Occurs when the From date is changed in the datepicker
  onFromDateChanged(date: Date | null) {
    const isAfterTo = date != null ? this.toDate && this.toDate.getTime() < date.getTime(): false;

    if (this._dateEquals(date, this.toDate)) {
      //If the two dates are on the same day, From should be the start of the day and To should be the end.
      this.fromDate = date;
      this.toDate = this._getDateEnd(date);
    } else {
      //If the dates are different, make sure From is not greater than To. If it is, set it one day less than To. Otherwise, set it to the provided value
      if (isAfterTo) {
        this.toDate = this._getDateEnd(date);
      }

      this.fromDate = date;
    }

    this.emitChange();
  }

  //Occurs when the To date is changed in the datepickers
  onToDateChanged(date: Date | null) {
    const isBeforeFrom = date != null ? this.fromDate && this.fromDate.getTime() > date.getTime() : false;

    if (this._dateEquals(date, this.fromDate)) {
      //If the two dates are on the same day, From should be the start of the day and To should be the end.
      this.fromDate = date;
      this.toDate = this._getDateEnd(date);
    } else {
      //If the dates are different, make sure To is not less than From. If it is, set it one day greater than From. Otherwise, set it to the provided value
      if (isBeforeFrom) {
        this.fromDate = date;
      }

      this.toDate = this._getDateEnd(date);
    }

    this.emitChange();
  }

  private _dateEquals(date1: Date, date2: Date) {
    return date1 != null && date2!=null ? date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() : false;
  }

  private _getDateEnd(date: Date) {
    return date!=null ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59) : null;
  }

}

//An interface to represent a date range with ISO values
export interface DateRange {
  from: string;
  to: string;
}
