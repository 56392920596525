import { NotificationUserLocationSettings } from "./NotificationUserLocationSettings";

export class NotificationUserLocationSettingsUpdates {
    settings: NotificationUserLocationSettings;
    updateType: NotificationSettingsUpdateType;
}

export enum NotificationSettingsUpdateType {
    ALL = 0,
    STATUS_CHANGE = 1,
    COMMENT = 2,
    REQUEST_EDITED = 3
  }

