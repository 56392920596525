// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  outline: 2px solid #C91A13;
  outline-offset: -1px;
}
::ng-deep .error on-off-toggle {
  position: relative;
}
::ng-deep .error on-off-toggle:after {
  font-family: upsicons, fantasy !important;
  content: "\\e638" !important;
  color: #C91A13;
  position: absolute;
  top: calc(50% - 0.7em);
  right: -25px;
}

.location-header {
  border-top: none !important;
  border-left: 1px solid #757575 !important;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

th {
  border: 1px solid #757575;
  border-left: none;
}

td {
  border-right: 1px solid #757575;
  border-bottom: 1px solid #757575;
  vertical-align: middle;
  padding: 0 28px;
}

.comment-notifications,
.request-edited-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
}

status-change-notifications {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/notification-settings/location-settings-grid/location-settings/location-settings.component.scss"],"names":[],"mappings":"AAGA;EACI,0BAAA;EACA,oBAAA;AAFJ;AAII;EACI,kBAAA;AAFR;AAKI;EACI,yCAAA;EACA,2BAAA;EACA,cAbK;EAcL,kBAAA;EACA,sBAAA;EACA,YAAA;AAHR;;AAOA;EACI,2BAAA;EACA,yCAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AAJJ;;AAOA;EACI,yBAAA;EACA,iBAAA;AAJJ;;AAOA;EACI,+BAAA;EACA,gCAAA;EACA,sBAAA;EACA,eAAA;AAJJ;;AAOA;;EAEI,aAAA;EACA,uBAAA;EACA,mBAAA;AAJJ;;AAOA;EACI,aAAA;AAJJ","sourcesContent":["$border-color: #757575;\n$errorColor: #C91A13;\n\n.error {\n    outline: 2px solid $errorColor;\n    outline-offset: -1px;\n\n    ::ng-deep & on-off-toggle {\n        position: relative;\n    }\n\n    ::ng-deep & on-off-toggle:after {\n        font-family: upsicons, fantasy !important;\n        content: '\\e638' !important;\n        color: $errorColor;\n        position: absolute;\n        top: calc(50% - 0.7em);\n        right: -25px;\n    }\n}\n\n.location-header {\n    border-top: none !important;\n    border-left: 1px solid $border-color !important;\n    padding: 12px;\n    padding-top: 8px;\n    padding-bottom: 8px;\n}\n\nth {\n    border: 1px solid $border-color;\n    border-left: none;\n}\n\ntd {\n    border-right: 1px solid $border-color;\n    border-bottom: 1px solid $border-color;\n    vertical-align: middle;\n    padding: 0 28px;\n}\n\n.comment-notifications,\n.request-edited-notifications {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\nstatus-change-notifications {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
