import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { debounceFunction } from '../../decorators/debounceFunction';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { DateRange } from '../components/date-range-picker/date-range-picker.component';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { RequestFormComponent } from '../request-form/request-form.component';
import { FeatureFlagService } from '../services/feature-flag.service';
import { LoadingIndicatorService } from '../services/loading-indicator.service';
import { LocationListService } from '../services/location-list.service';
import * as filterActions from '../store/actions/filter.actions';
import { requestChangesDetected } from '../store/actions/trailer-requests.actions';
import { defaultFilters } from '../store/reducers/filter.reducer';
import { DocumentStatus } from '../types/documentStatus';
import { DropdownOption } from '../types/dropdownOption';
import { LocationInfo } from '../types/locationInfo';
import { LocationMetrics } from '../types/locationMetrics';
import { Role } from '../types/role';
import { StatusFilterType } from '../types/statusFilterType';
import { TrailerRequestMetrics } from '../types/trailerRequestMetrics';
import { TrailerRequestPayload } from '../types/trailerRequestPayload';
import { TrailerRequestResponse } from '../types/trailerRequestResponse';
import { TrailerSortType } from '../types/trailerSortType';
import { AppState } from './../app.state';
import { CancelRequestModal } from './cancel-request-modal/cancel-request-modal.component';
import { ExportCommentsModalComponent } from './export-comments-modal/export-comments-modal.component';
import { FullPageModalComponent } from './full-page-modal/full-page-modal.component';
import { LocationListComponent } from './location-list/location-list.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends LocalizableComponent implements OnInit, OnDestroy, AfterViewInit {
  roleType = Role;
  auth: AuthService;
  location: LocationInfo | null = null;
  locations: LocationInfo[] = [];
  requests: TrailerRequestResponse[] = [];
  filters: TrailerRequestPayload;
  sorting: TrailerRequestPayload;
  attentionNeeded: number = 0;
  pending: number = 0;
  approved: number = 0;
  scheduled: number = 0;
  inTransit: number = 0;
  onProperty: number = 0;
  completed: number = 0;
  canceledOrRejected: number = 0;
  sortOptions: DropdownOption[] = [];
  showFilters: boolean = false;
  showActiveFilter: boolean = false;
  sortInitVal: string;
  preComments: number = 0;
  postComments: number = 0;
  requestQuery: string = '';
  iconClass: string = 'search icon';
  isLoading: boolean = true;
  appRoot: Element;
  requestsContainer: Element;
  locationMetrics: LocationMetrics;
  requestMetrics: TrailerRequestMetrics;
  showFsAlert: boolean = false;
  showRequestMetrics: boolean = false;
  currentFullPageModalRefs: NgbModalRef[] = [];
  modalNavSubscription = Subscription.EMPTY;
  disableExport: boolean = false;
  canDeactivate: boolean = true;
  cancelModal: NgbModalRef;
  locationsHeight: string = null;
  showLocations: boolean = false;
  showRequests: boolean = false;

  @ViewChild(LocationListComponent, { static: false }) locationList: LocationListComponent;
  @ViewChild('gridBottom', { static: false, read: ElementRef }) gridBottom!: ElementRef;
  @ViewChild('locationSection', { static: false, read: ElementRef }) locationSection!: ElementRef;

  disconnect$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private requestService: ApiService,
    private authService: AuthService,
    private loadingIndicatorService: LoadingIndicatorService,
    private modalService: NgbModal,
    private locationListService: LocationListService,
    private featureFlagService: FeatureFlagService,
    private apiService: ApiService,
  ) {
    super();
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .pipe(takeUntil(this.disconnect$))
      .subscribe(async nav => {
        if ((nav as NavigationStart).url.includes('login') || (nav as NavigationStart).url.includes('gateway')) {
          this.closeModals();
          return true;
        }
        else {
          return false;
        }
      });

    this.route.queryParams
      .pipe(takeUntil(this.disconnect$))
      .subscribe(async queryParams => {
        const requestId = queryParams['requestid'] || queryParams['requestId'];
        const path = queryParams['path'];

        if (path) {
          if (path !== 'edit-request') {
            const canDeactivateResponse = await this.canDeactivateModal();
            if (canDeactivateResponse?.canDeactivate === false) {
              this.router.navigate([''], {
                queryParams: canDeactivateResponse.params
              });
              return;
            }
          }

          if (path === 'request' && !this.currentFullPageModalRefs.some(ref => ref.componentInstance?.type === 'details')) {
            this.currentFullPageModalRefs.push(this.openFullPageModal('details', requestId));
          }
          else if (path === 'edit-request' && !this.currentFullPageModalRefs.some(ref => ref.componentInstance?.type === 'edit')) {
            this.currentFullPageModalRefs.push(this.openFullPageModal('edit', requestId));
          }
          else if (path === 'notification-settings' && !this.currentFullPageModalRefs.some(ref => ref.componentInstance?.type === 'notification-settings')) {
            this.currentFullPageModalRefs.push(this.openFullPageModal('notification-settings'));
          }
          else {
            this.closeModals();

            if (requestId) {
              this.findRequest(requestId)
            }
          }
        }
        else {
          this.closeModals();

          if (requestId) {
            this.findRequest(requestId)
          }
        }

        this.currentFullPageModalRefs = this.currentFullPageModalRefs.filter(ref => ref.componentInstance);
      });

    this.auth = authService;
    this.store.select(s => s.requestFilters)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(filters => {
        this.filters = filters;
        this.showFsAlert = this.shouldShowFSAlert();

        if (
          filters.requestType.length == 0 &&
          filters.trailerType.length == 0 &&
          (filters.destination == "" || filters.destination == null) &&
          filters.destinationSort.length == 0 &&
          filters.serviceLevel.length == 0 &&
          filters.trailerVolume.length == 0 &&
          filters.trailerDirection.length == 0 &&
          filters.earliestDueDate == null &&
          filters.latestDueDate == null &&
          filters.unreadCommentsOnly == false &&
          filters.planNotMet.length == 0 &&
          filters.createdAs.length == 0
        ) {
          this.showActiveFilter = false;
        } else {
          this.showActiveFilter = true;
        }

        if (!this.requestQuery) {
          this.requestQuery = this.filters.query;
        }
        this.setSearchIcon();

        if (filters.sortType == TrailerSortType['SLIC'])
          this.sortInitVal = "UPS LOCATION CODE";
        else if (filters.sortType == TrailerSortType['CUSTOMER_ID'])
          this.sortInitVal = 'LOCATION NAME';
        else
          this.sortInitVal = TrailerSortType[filters.sortType].toString().replace("_", " ");

      });

    this.store.select(s => s.trailerRequests)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(data => {
        if (!data?.requests) {
          return;
        }

        this.requestMetrics = data.metrics;
        this.showRequestMetrics = this.showActiveFilter || !!this.requestQuery;
        this.requests = data.requests.map(request => ({
          ...request,
          isViewOnly: typeof (request.isViewOnly) !== 'undefined' ?
            request.isViewOnly : this.authService.hasLocationRole(request.slicName, Role.ReadonlyUser)
        }));
        this.updateCards();
        setTimeout(() => { this.calculationLocationBounds(); });
      });
  }

  private closeModals() {
    this.currentFullPageModalRefs?.forEach(ref => {
      ref.close();
    });
    this.modalService.dismissAll();
    this.modalNavSubscription.unsubscribe();
  }

  private setSearchIcon() {
    this.iconClass = this.requestQuery === '' ? 'search icon' : 'clear icon';
  }

  ngOnInit() {
    this.loadingIndicatorService.locationListLoading$.pipe(takeUntil(this.disconnect$)).subscribe((val: boolean) => {
      this.isLoading = val;
      if (!val) {
        setTimeout(() => { this.calculationLocationBounds(); });
      }
    });

    this.store.select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$), skip(1))
      .subscribe(state => {
        if (state && this.hasNoVisibleLocations(state.locations)) {
          this.router.navigate(['no-locations']);
        }
      });

  }

  ngAfterViewInit() {
    window.name = 'HomePage';
    this.appRoot = document.querySelector('body');

    this.updateComments(this.filters.fromDate, this.filters.toDate);
  }

  calculationLocationBounds() {
    if (this.locationSection && this.gridBottom) {
      let locationBounds: DOMRect = this.locationSection.nativeElement.getBoundingClientRect();
      let gridBounds: DOMRect = this.gridBottom.nativeElement.getBoundingClientRect();

      this.locationSection.nativeElement.style.height = (gridBounds.top - locationBounds.top) + 'px';
      let height = (gridBounds.top - locationBounds.top) + 'px';
      if (height !== this.locationsHeight) {
        this.locationsHeight = height;
        setTimeout(() => { this.calculationLocationBounds(); }, 10);
      }
    }
    else {
      setTimeout(() => { this.calculationLocationBounds(); }, 10);
    }
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculationLocationBounds();
  }

  @HostListener('body:scroll', ['$event'])
  @debounceFunction()
  onScroll(evt: WheelEvent) {
    setTimeout(() => { this.calculationLocationBounds(); });

    if (this.requests.length < defaultFilters.take) return; // Default is 25 now

    if (!this.requestsContainer) {
      this.requestsContainer = document.querySelector('.trailer-requests');
      if (!this.requestsContainer) return;
    }

    const containerBounds = this.requestsContainer.getBoundingClientRect();
    const windowBounds = this.appRoot.getBoundingClientRect();

    if (containerBounds.height > 500 && (windowBounds.top + windowBounds.height) >= (containerBounds.bottom - (windowBounds.height * 0.25))) {
      this.store.dispatch(filterActions.fetchMoreRequests());
    }
  }

  getRouteData() {
    return this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(takeUntil(this.disconnect$))
  }

  shouldShowFSAlert() {
    let fsDownloadDate = new Date();
    fsDownloadDate.setDate(fsDownloadDate.getDate() + (6 - fsDownloadDate.getDay()) % 7);
    fsDownloadDate.setHours(0, 0, 0, 0);

    const { fromDate, toDate } = this.filters;
    const from = new Date(fromDate);
    const to = new Date(toDate);

    if (fsDownloadDate.getDate() === from.getDate() || fsDownloadDate.getDate() === to.getDate()) {
      fsDownloadDate.setDate(fsDownloadDate.getDate() + 7);
    }

    return (from > fsDownloadDate || to > fsDownloadDate) ? true : false;
  }

  setHour(field: string) {
    let d = new Date();
    if (field === 'fromDate') {
      d.setHours(0, 0, 0, 0);
    } else if (field === 'toDate') {
      d.setHours(23, 59, 59);
    }
    return d.toISOString();
  }

  updateCards() {
    const { requests } = this;

    const count = (status: string) => requests.filter(x => x.requestStatus == status).length;
    this.attentionNeeded = requests.filter(x => x.requestStatus == "Rejected" && x.attentionNeeded).length;
    this.pending = count("Pending");
    this.approved = count("Approved");
    this.scheduled = count("Scheduled");
    this.inTransit = count("In Transit");
    this.onProperty = count("On Property");
    this.completed = count("Completed");
    this.canceledOrRejected = count("Canceled") + requests.filter(x => x.requestStatus == "Rejected" && !x.attentionNeeded).length;
  }

  filterTiles(status: string) {
    this.store.dispatch((() => {
      switch (status) {
        case 'attentionNeeded':
          return filterActions.updateStatusFilter(StatusFilterType.ATTENTIONNEEDED);
        case 'pending':
          return filterActions.updateStatusFilter(StatusFilterType.PENDING);
        case 'approved':
          return filterActions.updateStatusFilter(StatusFilterType.APPROVED);
        case 'scheduled':
          return filterActions.updateStatusFilter(StatusFilterType.SCHEDULED);
        case 'inTransit':
          return filterActions.updateStatusFilter(StatusFilterType.IN_TRANSIT);
        case 'onProperty':
          return filterActions.updateStatusFilter(StatusFilterType.ON_PROPERTY);
        case 'completed':
          return filterActions.updateStatusFilter(StatusFilterType.COMPLETED);
        case 'canceledOrRejected':
          return filterActions.updateStatusFilter(StatusFilterType.CANCELEDORREJECTED);
        case 'total':
        default:
          return filterActions.updateStatusFilter(StatusFilterType.ALL);
      }
    })());
  }

  onUpdatedDateRange(dateRange: DateRange) {
    this.store.dispatch(filterActions.updateDateRange(dateRange.from, dateRange.to));
    // Search for unread comments outside the selected dates
    this.updateComments(dateRange.from, dateRange.to);
  }

  updateComments(from: string, to: string) {
    if (from && to) {
      let offset = new Date().getTimezoneOffset() * -1;
      this.requestService.getRequestComments({ startDate: from, endDate: to, timezoneOffset: offset })
        .pipe(takeUntil(this.disconnect$))
        .subscribe(data => {
          this.preComments = data.preCount;
          this.postComments = data.postCount;
        });
    }
  }

  onSortChange(val: String) {
    if (val == "UPS LOCATION CODE")
      this.store.dispatch(filterActions.updateSortFilter(TrailerSortType.SLIC));
    else if (val == 'LOCATION NAME')
      this.store.dispatch(filterActions.updateSortFilter(TrailerSortType.CUSTOMER_ID));
    else
      this.store.dispatch(filterActions.updateSortFilter(TrailerSortType[val.toUpperCase().replace(" ", "_")]));
  }

  onSortClick() {
    this.store.dispatch(filterActions.toggleSortDirection());
  }

  onUpdateLocations(locations: LocationInfo[]) {
    if (!locations)
      return;

    const selectedLocationsChanged = JSON.stringify(locations.map(l => l.slicName).sort()) !== JSON.stringify(this.locations.map(l => l.slicName).sort());
    this.locations = locations;

    if (selectedLocationsChanged) {
      if (locations.length === 1) {
        this.location = locations[0];
      } else {
        this.location = null;
      }

      this.sortOptions = this.location?.slicName == "" || locations.length === 0 ?
        [
          { name: this.localize(this.langSection.HomePage, this.langText.Status), value: 'STATUS' },
          { name: this.localize(this.langSection.HomePage, this.langText.UnreadComments), value: 'UNREAD COMMENTS' },
          { name: this.localize(this.langSection.HomePage, this.langText.Type), value: 'TYPE' },
          { name: this.localize(this.langSection.HomePage, this.langText.EarliestTime), value: 'EARLIEST TIME' },
          { name: this.localize(this.langSection.HomePage, this.langText.LatestTime), value: 'LATEST TIME' },
          { name: this.localize(this.langSection.HomePage, this.langText.UPSLocationCode), value: 'UPS LOCATION CODE' },
          { name: this.localize(this.langSection.HomePage, this.langText.LocationName), value: 'LOCATION NAME' }
        ] : [
          { name: this.localize(this.langSection.HomePage, this.langText.Status), value: 'STATUS' },
          { name: this.localize(this.langSection.HomePage, this.langText.UnreadComments), value: 'UNREAD COMMENTS' },
          { name: this.localize(this.langSection.HomePage, this.langText.Type), value: 'TYPE' },
          { name: this.localize(this.langSection.HomePage, this.langText.EarliestTime), value: 'EARLIEST TIME' },
          { name: this.localize(this.langSection.HomePage, this.langText.LatestTime), value: 'LATEST TIME' }
        ];

      const locationNames = locations.map(loc => loc.slicName);
      this.store.dispatch(filterActions.updateLocations(locationNames));
    }

    this.setLocationMetrics(selectedLocationsChanged);
  }

  isLocationViewOnly(slic: string) {
    return this.authService.hasLocationRole(slic, Role.ReadonlyUser);
  }

  @debounceFunction(100)
  filterRequests() {
    this.setSearchIcon();
    this.store.dispatch(filterActions.updateSearchQuery(this.requestQuery));
  }

  clearSearch() {
    this.requestQuery = '';
    this.filterRequests();
  }

  receiveFilter($event) {
    const filter = $event;
    this.store.dispatch(filterActions.updateFilters({
      requestType: this.object2Array(filter.requestType),
      trailerType: this.object2Array(filter.trailerType),
      destination: (!filter.destination) ? '' : (!filter.destination.slicName) ? filter.destination : filter.destination.slicName,
      destinationSort: this.object2Array(filter.destinationSort),
      serviceLevel: this.object2Array(filter.serviceLevel),
      trailerDirection: this.object2Array(filter.trailerDirection),
      trailerVolume: this.object2Array(filter.trailerVolume),
      earliestDueDate: filter.earliestDueDate,
      latestDueDate: filter.latestDueDate,
      unreadCommentsOnly: filter.unreadCommentsOnly,
      planNotMet: this.object2Array(filter.planNotMet),
      createdAs: this.object2Array(filter.createdAs)
    }));

    const { requestType, trailerType, destination, destinationSort, serviceLevel, trailerDirection, trailerVolume, earliestDueDate, latestDueDate, unreadCommentsOnly, planNotMet } = this.filters;
    if (requestType.length == 0 && trailerType.length == 0 && (destination == "" || destination == null) && destinationSort.length == 0 && serviceLevel.length == 0 && trailerDirection.length == 0 && trailerVolume.length == 0 && earliestDueDate == null && latestDueDate == null && unreadCommentsOnly == false && planNotMet.length == 0)
      this.showActiveFilter = false;
    else {
      this.showActiveFilter = true;
      this.onFilterClick();
    }
  }

  addRequest() {
    let validLocation = '';

    const location = this.location?.slicName;
    if (location && !this.isLocationViewOnly(location) && this.location.profile.documentStatus != DocumentStatus.SoftDelete) {
      validLocation = location;
    }
    this.router.navigateByUrl(`/add-request`, { state: { location: validLocation } });

  }

  async exportRequests() {
    this.disableExport = true;

    const modalRef = this.modalService.open(ExportCommentsModalComponent, { centered: true, modalDialogClass: 'modal-export-comments', backdrop: 'static' });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.filters = this.filters;
    await modalRef.result;

    this.disableExport = false;
  }

  object2Array(obj) {
    let r = [];
    for (let o in obj) {
      if (obj[o] == true)
        r.push(o);
    }
    return r;
  }

  onFilterClick() {
    this.showFilters = !this.showFilters;
    setTimeout(() => { this.calculationLocationBounds(); }, 10);
  }

  setLocationMetrics(selectedLocationsChanged: boolean) {
    let oldMetrics = { ...this.locationMetrics };
    this.locationMetrics = {
      approvedCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.approvedCommentsCount, 0),
      approvedCount: this.locations.reduce((sum, current) => sum + current.metrics.approvedCount, 0),
      attentionNeededCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.attentionNeededCommentsCount, 0),
      attentionNeededCount: this.locations.reduce((sum, current) => sum + current.metrics.attentionNeededCount, 0),
      canceledOrRejectedCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.canceledOrRejectedCommentsCount, 0),
      canceledOrRejectedCount: this.locations.reduce((sum, current) => sum + current.metrics.canceledOrRejectedCount, 0),
      completedCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.completedCommentsCount, 0),
      completedCount: this.locations.reduce((sum, current) => sum + current.metrics.completedCount, 0),
      countryCode: '',
      inProgressCount: this.locations.reduce((sum, current) => sum + current.metrics.inProgressCount, 0),
      inTransitCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.inTransitCommentsCount, 0),
      inTransitCount: this.locations.reduce((sum, current) => sum + current.metrics.inTransitCount, 0),
      onPropertyCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.onPropertyCommentsCount, 0),
      onPropertyCount: this.locations.reduce((sum, current) => sum + current.metrics.onPropertyCount, 0),
      pendingCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.pendingCommentsCount, 0),
      pendingCount: this.locations.reduce((sum, current) => sum + current.metrics.pendingCount, 0),
      scheduledCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.scheduledCommentsCount, 0),
      scheduledCount: this.locations.reduce((sum, current) => sum + current.metrics.scheduledCount, 0),
      slicNumber: '',
      totalCount: this.locations.reduce((sum, current) => sum + current.metrics.totalCount, 0),
      unreadCommentsCount: this.locations.reduce((sum, current) => sum + current.metrics.unreadCommentsCount, 0)
    };

    if (!selectedLocationsChanged && this.locationListService.lastUpdateFromTimer.value && this.shouldRefreshRequests(oldMetrics)) {
      this.store.dispatch(requestChangesDetected());
    }
  }

  private shouldRefreshRequests(oldLocationMetrics: LocationMetrics): boolean {
    return oldLocationMetrics.totalCount !== this.locationMetrics.totalCount ||
      oldLocationMetrics.attentionNeededCount !== this.locationMetrics.attentionNeededCount ||
      oldLocationMetrics.pendingCount !== this.locationMetrics.pendingCount ||
      oldLocationMetrics.approvedCount !== this.locationMetrics.approvedCount ||
      oldLocationMetrics.scheduledCount !== this.locationMetrics.scheduledCount ||
      oldLocationMetrics.inTransitCount !== this.locationMetrics.inTransitCount ||
      oldLocationMetrics.onPropertyCount !== this.locationMetrics.onPropertyCount ||
      oldLocationMetrics.completedCount !== this.locationMetrics.completedCount ||
      oldLocationMetrics.canceledOrRejectedCount !== this.locationMetrics.canceledOrRejectedCount;
  }

  openFullPageModal(type: 'edit' | 'details' | 'notification-settings', requestId: string = ''): NgbModalRef {
    const modalRef = this.modalService.open(FullPageModalComponent, { size: 'full-page', keyboard: false, backdrop: 'static' });
    modalRef.componentInstance.requestId = requestId;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.modal = modalRef;

    return modalRef;
  }

  async canDeactivateModal(): Promise<{ canDeactivate: boolean, params?: Params }> {
    const modalType = ['edit', 'details'];
    if (this.currentFullPageModalRefs.some(c => modalType.includes(c.componentInstance?.type))) {
      const modalInstance = this.currentFullPageModalRefs.find(c => modalType.includes(c.componentInstance?.type)).componentInstance;
      const formComponent = modalInstance?.internalComponent as RequestFormComponent;
      if (formComponent && formComponent.isDirty) {
        this.cancelModal = this.modalService.open(CancelRequestModal, { centered: true, size: 'cancel-on-deactivator', backdrop: 'static' });
        formComponent.isNewRequest = false;
        const canDeactivate = await this.cancelModal.result;

        return {
          canDeactivate, params: {
            path: modalInstance.type === 'edit' ? 'edit-request' : 'request',
            requestId: formComponent.requestId
          }
        }
      }
      else {
        return { canDeactivate: true };
      }
    } else {
      return { canDeactivate: true };
    }
  }

  toggleLocations() {
    this.showLocations = !this.showLocations;
  }

  toggleRequests() {
    this.showRequests = !this.showRequests;
  }

  private hasNoVisibleLocations(locations: LocationInfo[]) {
    // Returns true: 
    // - User does not have access to any locations
    // - User only has access to locations that are not visible on TRS, ie. are toggled off on TMS
    //
    // Returns false: 
    // - User has access to one or more locations that are visible on TRS
    return (!locations.length || (locations.length === 1 && !locations[0].slicName));
  }

  private findRequest(requestId: string) {
    this.requestQuery = requestId;
    this.filterRequests();
    this.apiService.getTrailerRequest(requestId).subscribe(request => {
      if (request) {
        this.showRequest(request);
      }
    });
  }

  private showRequest(request: TrailerRequestResponse) {
    if (request) {
      let fromDate = new Date(request.sortDateTime);
      let toDate = new Date(request.sortDateTime);

      fromDate = new Date(fromDate.setDate(fromDate.getDate() - 1)); // account for time zone differences
      toDate = new Date(toDate.setDate(toDate.getDate() + 1));

      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 0);
      let dateRange: DateRange = {
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      };

      this.onUpdatedDateRange(dateRange);
    }
  }

}
