import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ToastService } from 'src/app/services/toast.service';
import { OnPropertyData, TrailerAsset } from 'src/app/types/onPropertyData';
import { LocalizableComponent } from '../localizable/localizable.component';

@Component({
  selector: 'on-property-map',
  templateUrl: './on-property-map.component.html',
  styleUrls: ['./on-property-map.component.scss']
})
export class OnPropertyMapComponent extends LocalizableComponent implements OnChanges, OnDestroy {
  @Input() assetData: OnPropertyData;
  @Input() isLoading: boolean;
  @Input() selectedTrailer: TrailerAsset;
  @Output() onTrailerPointSelect: EventEmitter<TrailerAsset> = new EventEmitter<TrailerAsset>();
  @ViewChild('bingMap', { static: true }) bingMapElement: ElementRef;

  map: Microsoft.Maps.Map;
  selectedTrailerIcon: Microsoft.Maps.Pushpin;
  showNoDataMessage: boolean;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  private mapApiKey: string;

  constructor(private env: EnvironmentService, private apiService: ApiService, private toastService: ToastService) {
    super();
    this.env.environment$.pipe(takeUntil(this.disconnect$))
      .subscribe(env => {
        this.mapApiKey = env.bingMapsApiKey;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assetData) {
      if (this.assetData?.assets?.trailers?.length > 0 || this.assetData?.buildings?.length > 0 || this.assetData?.yards?.length > 0) {
        this.showNoDataMessage = false;
        this.initMap();
      } else {
        this.showNoDataMessage = true;
      }
    }

    if (this.assetData) {
      if (changes?.selectedTrailer) {
        this.drawSelectedTrailerIcon();
      }
      if (changes?.trailers) {
        this.drawTrailerPins();
      }
      if (changes?.location) {
        this.drawYardPerimeters()
        this.drawBuildingPerimeters();
      }
    }
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  initMap() {
    this.drawYardPerimeters();
    this.drawBuildingPerimeters();
    this.drawTrailerPins();
  }

  private drawYardPerimeters() {
    for (let yard of this.assetData.yards) {
      let yardCoords: Microsoft.Maps.Location[] = [];
      for (let l of yard.yardOutline) {
        yardCoords.push(new Microsoft.Maps.Location(l.latitude, l.longitude));
      }

      if (!this.map) {
        this.map = new Microsoft.Maps.Map(this.bingMapElement.nativeElement, {
          credentials: this.mapApiKey,
          disableBirdseye: true,
          disableStreetside: true,
          bounds: Microsoft.Maps.LocationRect.fromLocations(yardCoords)
        });
      }

      let polygon = new Microsoft.Maps.Polygon(yardCoords, {
        fillColor: 'transparent',
        strokeColor: '#F35E06',
        strokeThickness: 5
      });

      this.map.entities.push(polygon);
    }
  }

  private drawBuildingPerimeters() {
    for (let building of this.assetData.buildings) {
      let buildingCoords: Microsoft.Maps.Location[] = [];
      for (let l of building.buildingOutline) {
        buildingCoords.push(new Microsoft.Maps.Location(l.latitude, l.longitude));
      }

      //Create a polygon
      let polygon = new Microsoft.Maps.Polygon(buildingCoords, {
        fillColor: '#A79E9D',
        strokeColor: '#242424',
        strokeThickness: 1
      });

      //Add the polygon to map
      this.map.entities.push(polygon);
    }
  }

  private drawTrailerPins() {
    Microsoft.Maps.registerModule('CanvasOverlayModule', 'assets/bing-modules/CanvasOverlayModule.js');

    Microsoft.Maps.loadModule('CanvasOverlayModule', function () {
      let locations: Microsoft.Maps.Location[] = [];
      for (let l of this.assetData.assets.trailers) {
        locations.push(new Microsoft.Maps.Location(l.location.latitude, l.location.longitude));
      }

      //Create a pushpin icon on an off screen canvas.
      let offScreenCanvas = document.createElement('canvas');
      offScreenCanvas.width = 10;
      offScreenCanvas.height = 10;

      //Draw a circle on the off screen canvas.
      let offCtx = offScreenCanvas.getContext('2d');
      offCtx.fillStyle = '#C91A13';
      offCtx.beginPath();
      offCtx.arc(5, 5, 4.5, 0, 2 * Math.PI);
      offCtx.closePath();
      offCtx.fill();

      //Implement the new custom overlay class.
      let overlay = new CanvasOverlay(function (canvas) {
        //Calculate pixel coordinates of locations.
        let points = this.map.tryLocationToPixel(locations, Microsoft.Maps.PixelReference.control) as Microsoft.Maps.Point[];

        //Get the context of the main canvas.
        let ctx = canvas.getContext("2d");

        //Buffer map dimensions to account for radius of points.
        let mapWidth = this.map.getWidth() + 5;
        let mapHeight = this.map.getHeight() + 5;

        //Draw the off screen canvas for each location.
        for (let i = 0, len = points?.length; i < len; i++) {
          //Don't draw the point if it is not in view. This greatly improves performance when zoomed in.
          if (points[i].x >= -5 && points[i].y >= -5 && points[i].x <= mapWidth && points[i].y <= mapHeight) {
            ctx.drawImage(offScreenCanvas, points[i].x - 5, points[i].y - 5, 10, 10);
          }
        }
      }.bind(this));

      //Add a click event to the map and check to see if a circle is clicked.
      Microsoft.Maps.Events.addHandler(this.map, 'click', function (e) {
        //Get the pixel coordinates of the locations.
        let points = this.map.tryLocationToPixel(locations, Microsoft.Maps.PixelReference.control) as Microsoft.Maps.Point[];

        //Reverse the array as the top most circle will intersect the mouse first.
        points?.reverse();

        //Calculate the square of the radius. As r^2 = x^2 + y^2.
        let r2 = 5 * 5;
        let dx, dy;
        let selectedIdx = -1;

        //Loop through the pixel coordinates until we find one that is within 5 pixel distance of the mouse location.
        for (let i = 0, len = points?.length; i < len; i++) {
          dx = points[i].x - e.point.x;
          dy = points[i].y - e.point.y;

          //Check to see if the mouse location is within radial distance of the point.
          if (dx * dx + dy * dy <= r2) {
            //Calculate the index of the point in the un-reversed array
            selectedIdx = len - i - 1;
            break;
          }
        }

        if (selectedIdx >= 0) {
          //Use the selected index value to retrieve the original location object.
          let selectedLocation = locations[selectedIdx];
          let clickedTrailer = this.assetData.assets.trailers.find(x => x.location.latitude == selectedLocation.latitude && x.location.longitude == selectedLocation.longitude);

          if (clickedTrailer?.trailerNumber != this.selectedTrailer?.trailerNumber) {
            this.onTrailerPointSelect.emit(this.assetData.assets.trailers.find(x => x.trailerNumber === clickedTrailer.trailerNumber));
          } else {
            this.onTrailerPointSelect.emit(null);
          }
        }
      }.bind(this));

      //Add the custom overlay to the map.
      this.map.layers.insert(overlay);
    }.bind(this));
  }

  private drawSelectedTrailerIcon() {
    if (this.selectedTrailerIcon) {
      this.map.entities.remove(this.selectedTrailerIcon);
      this.selectedTrailerIcon = null;
    }
    let trailerData = this.assetData?.assets?.trailers.find(x => x?.trailerNumber === this.selectedTrailer?.trailerNumber);
    if (trailerData) {
      let trailerLocation = new Microsoft.Maps.Location(trailerData.location.latitude, trailerData.location.longitude);
      this.selectedTrailerIcon = new Microsoft.Maps.Pushpin(trailerLocation, {
        icon: "assets/trailer-drop-point.svg",
        anchor: new Microsoft.Maps.Point(15, 15)
      });

      this.map.entities.push(this.selectedTrailerIcon);
    }
  }
}

export function getMockTrailerData(): OnPropertyData {
  return JSON.parse(JSON.stringify(
    {
      "assets": {
        "trailers": [
          {
            "trailerNumber": "711842",
            "location": {
              "latitude": "38.1279",
              "longitude": "-85.7189"
            }
          },
          {
            "trailerNumber": "723481",
            "location": {
              "latitude": "38.1270",
              "longitude": "-85.7192"
            }
          },
          {
            "trailerNumber": "757221",
            "location": {
              "latitude": "38.1260",
              "longitude": "-85.7195"
            }
          },
          {
            "trailerNumber": "757402",
            "location": {
              "latitude": "38.1250",
              "longitude": "-85.7198"
            }
          },
          {
            "trailerNumber": "758100",
            "location": {
              "latitude": "38.1240",
              "longitude": "-85.7201"
            }
          },
          {
            "trailerNumber": "760447",
            "location": {
              "latitude": "38.1229",
              "longitude": "-85.7207"
            }
          },
          {
            "trailerNumber": "762334",
            "location": {
              "latitude": "38.1231",
              "longitude": "-85.7216"
            }
          },
          {
            "trailerNumber": "766685",
            "location": {
              "latitude": "38.1244",
              "longitude": "-85.7218"
            }
          },
          {
            "trailerNumber": "768111",
            "location": {
              "latitude": "38.1254",
              "longitude": "-85.7215"
            }
          },
          {
            "trailerNumber": "771883",
            "location": {
              "latitude": "38.1264",
              "longitude": "-85.7212"
            }
          },
          {
            "trailerNumber": "773938",
            "location": {
              "latitude": "38.1274",
              "longitude": "-85.7209"
            }
          },
          {
            "trailerNumber": "774494",
            "location": {
              "latitude": "38.1302",
              "longitude": "-85.7202"
            }
          },
          {
            "trailerNumber": "774498",
            "location": {
              "latitude": "38.1316",
              "longitude": "-85.7199"
            }
          },
          {
            "trailerNumber": "775495",
            "location": {
              "latitude": "38.1310",
              "longitude": "-85.7179"
            }
          },
          {
            "trailerNumber": "776465",
            "location": {
              "latitude": "38.1295",
              "longitude": "-85.7184"
            }
          }
        ]
      },
      "buildings": [
        {
          "buildingOutline": [
            {
              "latitude": "38.1307294343999",
              "longitude": "-85.7179713249206"
            },
            {
              "latitude": "38.1311218628913",
              "longitude": "-85.717855989933"
            },
            {
              "latitude": "38.1312980330601",
              "longitude": "-85.7177942991257"
            },
            {
              "latitude": "38.1313317901697",
              "longitude": "-85.717951208353"
            },
            {
              "latitude": "38.1313465589002",
              "longitude": "-85.7179498672485"
            },
            {
              "latitude": "38.1314256770485",
              "longitude": "-85.7183468341827"
            },
            {
              "latitude": "38.131415127967",
              "longitude": "-85.7183521986008"
            },
            {
              "latitude": "38.1314235672323",
              "longitude": "-85.718397796154"
            },
            {
              "latitude": "38.1314372810364",
              "longitude": "-85.7183964550495"
            },
            {
              "latitude": "38.1315153441796",
              "longitude": "-85.7187907397747"
            },
            {
              "latitude": "38.1316029013895",
              "longitude": "-85.7192346453667"
            },
            {
              "latitude": "38.1315965719562",
              "longitude": "-85.7192467153072"
            },
            {
              "latitude": "38.1316493172173",
              "longitude": "-85.7195055484772"
            },
            {
              "latitude": "38.131699952632",
              "longitude": "-85.7197603583336"
            },
            {
              "latitude": "38.1314953009481",
              "longitude": "-85.719832777977"
            },
            {
              "latitude": "38.1314889715054",
              "longitude": "-85.7198032736778"
            },
            {
              "latitude": "38.1315311677795",
              "longitude": "-85.7197898626328"
            },
            {
              "latitude": "38.1314341163126",
              "longitude": "-85.7193191349506"
            },
            {
              "latitude": "38.1312832643198",
              "longitude": "-85.71857213974"
            },
            {
              "latitude": "38.1310786114675",
              "longitude": "-85.7186405360699"
            },
            {
              "latitude": "38.1311672241131",
              "longitude": "-85.7191099226475"
            },
            {
              "latitude": "38.1311872674347",
              "longitude": "-85.719105899334"
            },
            {
              "latitude": "38.1312073107508",
              "longitude": "-85.7191850244999"
            },
            {
              "latitude": "38.1311893772577",
              "longitude": "-85.719195753336"
            },
            {
              "latitude": "38.1312895937803",
              "longitude": "-85.7196906208992"
            },
            {
              "latitude": "38.1312421228131",
              "longitude": "-85.7197080552578"
            },
            {
              "latitude": "38.1311440160497",
              "longitude": "-85.719209164381"
            },
            {
              "latitude": "38.1311239727162",
              "longitude": "-85.7192158699036"
            },
            {
              "latitude": "38.131108149028",
              "longitude": "-85.7191340625286"
            },
            {
              "latitude": "38.1311239727162",
              "longitude": "-85.7191313803196"
            },
            {
              "latitude": "38.1310269207078",
              "longitude": "-85.7186472415924"
            },
            {
              "latitude": "38.1308233220536",
              "longitude": "-85.7187142968178"
            },
            {
              "latitude": "38.1309182645019",
              "longitude": "-85.7191890478134"
            },
            {
              "latitude": "38.1309330333161",
              "longitude": "-85.7191890478134"
            },
            {
              "latitude": "38.1309509668722",
              "longitude": "-85.7192668318748"
            },
            {
              "latitude": "38.1309351431464",
              "longitude": "-85.7192775607109"
            },
            {
              "latitude": "38.1310364149317",
              "longitude": "-85.7197791337967"
            },
            {
              "latitude": "38.1309910536286",
              "longitude": "-85.7197965681553"
            },
            {
              "latitude": "38.1308845072011",
              "longitude": "-85.719288289547"
            },
            {
              "latitude": "38.1308729031253",
              "longitude": "-85.719299018383"
            },
            {
              "latitude": "38.130851804801",
              "longitude": "-85.719209164381"
            },
            {
              "latitude": "38.1308676285448",
              "longitude": "-85.719209164381"
            },
            {
              "latitude": "38.1307684663605",
              "longitude": "-85.7187277078629"
            },
            {
              "latitude": "38.1305690866673",
              "longitude": "-85.7187974452972"
            },
            {
              "latitude": "38.1306640294463",
              "longitude": "-85.7192721962929"
            },
            {
              "latitude": "38.130677743393",
              "longitude": "-85.7192654907704"
            },
            {
              "latitude": "38.1306956770118",
              "longitude": "-85.7193513214588"
            },
            {
              "latitude": "38.1306809081496",
              "longitude": "-85.7193580269814"
            },
            {
              "latitude": "38.1307790155354",
              "longitude": "-85.719843506813"
            },
            {
              "latitude": "38.1307674114429",
              "longitude": "-85.7198515534401"
            },
            {
              "latitude": "38.1307969491294",
              "longitude": "-85.7200044393539"
            },
            {
              "latitude": "38.1307758507831",
              "longitude": "-85.720012485981"
            },
            {
              "latitude": "38.1307410384985",
              "longitude": "-85.7198676466942"
            },
            {
              "latitude": "38.1307325991543",
              "longitude": "-85.7198569178581"
            },
            {
              "latitude": "38.1306334367865",
              "longitude": "-85.7193727791309"
            },
            {
              "latitude": "38.1306197228314",
              "longitude": "-85.7193794846535"
            },
            {
              "latitude": "38.1305986244339",
              "longitude": "-85.7192923128605"
            },
            {
              "latitude": "38.1306144482326",
              "longitude": "-85.719290971756"
            },
            {
              "latitude": "38.1305142307831",
              "longitude": "-85.7188068330288"
            },
            {
              "latitude": "38.1300922610652",
              "longitude": "-85.7189556956291"
            },
            {
              "latitude": "38.1301988086492",
              "longitude": "-85.7194773852825"
            },
            {
              "latitude": "38.1303064110019",
              "longitude": "-85.7200419902802"
            },
            {
              "latitude": "38.1300289653972",
              "longitude": "-85.7201358675957"
            },
            {
              "latitude": "38.1298127047837",
              "longitude": "-85.7190428674221"
            },
            {
              "latitude": "38.1293664674442",
              "longitude": "-85.7191877067089"
            },
            {
              "latitude": "38.1288495460756",
              "longitude": "-85.7193607091904"
            },
            {
              "latitude": "38.1284497207104",
              "longitude": "-85.71947067976"
            },
            {
              "latitude": "38.1280256291434",
              "longitude": "-85.7196262478828"
            },
            {
              "latitude": "38.1280825968106",
              "longitude": "-85.71991994977"
            },
            {
              "latitude": "38.1282144662399",
              "longitude": "-85.720562338829"
            },
            {
              "latitude": "38.1279074738401",
              "longitude": "-85.7206723093986"
            },
            {
              "latitude": "38.1275171379966",
              "longitude": "-85.7208010554314"
            },
            {
              "latitude": "38.1270888211288",
              "longitude": "-85.7209378480911"
            },
            {
              "latitude": "38.1267712742426",
              "longitude": "-85.7210451364517"
            },
            {
              "latitude": "38.1262532795136",
              "longitude": "-85.7212114334106"
            },
            {
              "latitude": "38.1258186252961",
              "longitude": "-85.7213522493839"
            },
            {
              "latitude": "38.1255496025785",
              "longitude": "-85.7214327156544"
            },
            {
              "latitude": "38.1250590291884",
              "longitude": "-85.7216030359268"
            },
            {
              "latitude": "38.1246001027102",
              "longitude": "-85.7217451930046"
            },
            {
              "latitude": "38.1243004801885",
              "longitude": "-85.7218484580517"
            },
            {
              "latitude": "38.12420869417",
              "longitude": "-85.721360296011"
            },
            {
              "latitude": "38.1239291153566",
              "longitude": "-85.7214514911175"
            },
            {
              "latitude": "38.1234754568907",
              "longitude": "-85.7215909659862"
            },
            {
              "latitude": "38.123147344011",
              "longitude": "-85.7217063009739"
            },
            {
              "latitude": "38.1230956476351",
              "longitude": "-85.721452832222"
            },
            {
              "latitude": "38.1234575214981",
              "longitude": "-85.7213401794434"
            },
            {
              "latitude": "38.1241612185979",
              "longitude": "-85.7211162149906"
            },
            {
              "latitude": "38.1240187916964",
              "longitude": "-85.7204416394234"
            },
            {
              "latitude": "38.124015626651",
              "longitude": "-85.7204188406468"
            },
            {
              "latitude": "38.1234786219595",
              "longitude": "-85.7205972075462"
            },
            {
              "latitude": "38.1229584938072",
              "longitude": "-85.7207715511322"
            },
            {
              "latitude": "38.122909962391",
              "longitude": "-85.7205221056938"
            },
            {
              "latitude": "38.1234701817757",
              "longitude": "-85.7203423976898"
            },
            {
              "latitude": "38.1241696587019",
              "longitude": "-85.720113068819"
            },
            {
              "latitude": "38.1245768925588",
              "longitude": "-85.7199816405773"
            },
            {
              "latitude": "38.1251560891315",
              "longitude": "-85.7197898626328"
            },
            {
              "latitude": "38.1258682097672",
              "longitude": "-85.719568580389"
            },
            {
              "latitude": "38.126536014363",
              "longitude": "-85.7193419337273"
            },
            {
              "latitude": "38.1269791040884",
              "longitude": "-85.7191997766495"
            },
            {
              "latitude": "38.1276722176222",
              "longitude": "-85.7189744710922"
            },
            {
              "latitude": "38.1282070815582",
              "longitude": "-85.7187947630882"
            },
            {
              "latitude": "38.1289571504173",
              "longitude": "-85.7185453176498"
            },
            {
              "latitude": "38.1294951697464",
              "longitude": "-85.7183763384819"
            },
            {
              "latitude": "38.1299667246033",
              "longitude": "-85.718223452568"
            },
            {
              "latitude": "38.1306513704162",
              "longitude": "-85.7179994881153"
            },
            {
              "latitude": "38.1307294343999",
              "longitude": "-85.7179713249206"
            }
          ]
        },
        {
          "buildingOutline": [
            {
              "latitude": "38.1317073369604",
              "longitude": "-85.7178653776646"
            },
            {
              "latitude": "38.1317674664638",
              "longitude": "-85.7181738317013"
            },
            {
              "latitude": "38.1318318155265",
              "longitude": "-85.7181470096111"
            },
            {
              "latitude": "38.1317716860762",
              "longitude": "-85.7178412377834"
            },
            {
              "latitude": "38.1317073369604",
              "longitude": "-85.7178653776646"
            }
          ]
        }
      ],
      "yards": [
        {
          "yardOutline": [
            {
              "latitude": "38.1331725839111",
              "longitude": "-85.7183361053467"
            },
            {
              "latitude": "38.1329531679539",
              "longitude": "-85.7186365127564"
            },
            {
              "latitude": "38.1327843859994",
              "longitude": "-85.7191514968872"
            },
            {
              "latitude": "38.1324130643257",
              "longitude": "-85.7204174995422"
            },
            {
              "latitude": "38.1314003592504",
              "longitude": "-85.723614692688"
            },
            {
              "latitude": "38.116009732725",
              "longitude": "-85.7236683368683"
            },
            {
              "latitude": "38.1158767861183",
              "longitude": "-85.7209673523903"
            },
            {
              "latitude": "38.1190305098389",
              "longitude": "-85.7199722528458"
            },
            {
              "latitude": "38.119812325264",
              "longitude": "-85.7197415828705"
            },
            {
              "latitude": "38.1210235423482",
              "longitude": "-85.7193875312805"
            },
            {
              "latitude": "38.1222516199932",
              "longitude": "-85.7190871238708"
            },
            {
              "latitude": "38.128362159718",
              "longitude": "-85.7174777984619"
            },
            {
              "latitude": "38.1307083360342",
              "longitude": "-85.7168340682984"
            },
            {
              "latitude": "38.1315185088999",
              "longitude": "-85.7168555259704"
            },
            {
              "latitude": "38.1321936460851",
              "longitude": "-85.7171773910522"
            },
            {
              "latitude": "38.1327168731083",
              "longitude": "-85.7176065444946"
            },
            {
              "latitude": "38.1331725839111",
              "longitude": "-85.7183361053467"
            }
          ]
        }
      ]
    }
  ));
}

export function getMockAdikyData(): OnPropertyData {
  return JSON.parse(JSON.stringify(
    {
      "assets": {
        "trailers": []
      },
      "buildings": [
        {
          "buildingOutline": [
            {
              "latitude": "38.1814955244738",
              "longitude": "-85.6851947307587"
            },
            {
              "latitude": "38.1810527658414",
              "longitude": "-85.6848031282425"
            },
            {
              "latitude": "38.1795600169107",
              "longitude": "-85.6875067949295"
            },
            {
              "latitude": "38.1799985677918",
              "longitude": "-85.6879091262817"
            },
            {
              "latitude": "38.1814955244738",
              "longitude": "-85.6851947307587"
            }
          ]
        }
      ],
      "yards": [
        {
          "yardOutline": [
            {
              "latitude": "38.1802094086219",
              "longitude": "-85.6854575872421"
            },
            {
              "latitude": "38.1809136125726",
              "longitude": "-85.6841164827347"
            },
            {
              "latitude": "38.1816894940747",
              "longitude": "-85.6847709417343"
            },
            {
              "latitude": "38.1817232278657",
              "longitude": "-85.684894323349"
            },
            {
              "latitude": "38.1815629922197",
              "longitude": "-85.6852000951767"
            },
            {
              "latitude": "38.1807112073437",
              "longitude": "-85.6867879629135"
            },
            {
              "latitude": "38.1799310985972",
              "longitude": "-85.6881666183472"
            },
            {
              "latitude": "38.1790919452699",
              "longitude": "-85.6874477863312"
            },
            {
              "latitude": "38.1802094086219",
              "longitude": "-85.6854575872421"
            }
          ]
        }
      ]
    }
  ));
}

export function getMockLchkyData(): OnPropertyData {
  return JSON.parse(JSON.stringify({
    "assets": {
      "trailers": [
        {
          "trailerNumber": "MTRZ624328",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "725068",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ534219",
          "location": {
            "latitude": "29.8794",
            "longitude": "-95.4077"
          }
        },
        {
          "trailerNumber": "775821",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MTRZ321887",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364260",
          "location": {
            "latitude": "39.1182",
            "longitude": "-77.1928"
          }
        },
        {
          "trailerNumber": "NONZ327135",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "343464",
          "location": {
            "latitude": "40.1627",
            "longitude": "-75.1372"
          }
        },
        {
          "trailerNumber": "373403",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624332",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "376790",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ5322585",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624319",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "939233",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "602569",
          "location": {
            "latitude": "40.282",
            "longitude": "-76.8846"
          }
        },
        {
          "trailerNumber": "EJGZ104116",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "369280",
          "location": {
            "latitude": "43.5968",
            "longitude": "-116.5466"
          }
        },
        {
          "trailerNumber": "910676",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "381748",
          "location": {
            "latitude": "44.999",
            "longitude": "-93.2142"
          }
        },
        {
          "trailerNumber": "939237",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ467305",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ003643",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724824",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "303719",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "371875",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "BOWZ003534",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "376727",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "179809",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "184102",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ227274",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "186880",
          "location": {
            "latitude": "26.1461",
            "longitude": "-80.2012"
          }
        },
        {
          "trailerNumber": "703427",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748390",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "703516",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "BOWZ006301",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ006212",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748215",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "308751",
          "location": {
            "latitude": "41.763",
            "longitude": "-87.7942"
          }
        },
        {
          "trailerNumber": "339163",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "304294",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ003508",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "382776",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "843427",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "MLNZ901737",
          "location": {
            "latitude": "35.8368",
            "longitude": "-78.6019"
          }
        },
        {
          "trailerNumber": "BOWZ006147",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ269764",
          "location": {
            "latitude": "45.5714",
            "longitude": "-122.7179"
          }
        },
        {
          "trailerNumber": "724384",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624930",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "844389",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "841683",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "MTRZ624942",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "NONZ004104",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MTRZ624948",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "374199",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624949",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748247",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MUPZ903531",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ621893",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "374173",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724765",
          "location": {
            "latitude": "35.4566",
            "longitude": "-97.5835"
          }
        },
        {
          "trailerNumber": "PRTZ159052",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364638",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "186330",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ103558",
          "location": {
            "latitude": "35.0527",
            "longitude": "-89.9678"
          }
        },
        {
          "trailerNumber": "724678",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "748276",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MTRZ924940",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PRTZ143185",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "609725",
          "location": {
            "latitude": "32.9136",
            "longitude": "-97.0675"
          }
        },
        {
          "trailerNumber": "888531",
          "location": {
            "latitude": "32.8864",
            "longitude": "-96.882"
          }
        },
        {
          "trailerNumber": "308362",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MTRZ322707",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ532429",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218939",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ087983",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "337607",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "216970",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "218947",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "888419",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "841328",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "757934",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "305846",
          "location": {
            "latitude": "38.1671",
            "longitude": "-85.7337"
          }
        },
        {
          "trailerNumber": "844290",
          "location": {
            "latitude": "35.2742",
            "longitude": "-80.8124"
          }
        },
        {
          "trailerNumber": "BOWZ154518",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ464157",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ224141",
          "location": {
            "latitude": "39.0955",
            "longitude": "-76.8978"
          }
        },
        {
          "trailerNumber": "724639",
          "location": {
            "latitude": "40.2236",
            "longitude": "-79.612"
          }
        },
        {
          "trailerNumber": "BOWZ006306",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MUPZ903487",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ100661",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "346911",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "BOWZ006308",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQU237852",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741191",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "375929",
          "location": {
            "latitude": "37.3192",
            "longitude": "-79.9808"
          }
        },
        {
          "trailerNumber": "218892",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218965",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741178",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MAR560140",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "305792",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724757",
          "location": {
            "latitude": "38.1022",
            "longitude": "-85.6819"
          }
        },
        {
          "trailerNumber": "PTLZ190592",
          "location": {
            "latitude": "39.731",
            "longitude": "-86.3419"
          }
        },
        {
          "trailerNumber": "347231",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218966",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "376562",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "183912",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "218960",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "363008",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "372511",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722207",
          "location": {
            "latitude": "38.0729",
            "longitude": "-84.5339"
          }
        },
        {
          "trailerNumber": "738647",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "367196",
          "location": {
            "latitude": "44.8311",
            "longitude": "-93.1141"
          }
        },
        {
          "trailerNumber": "EJGZ100971",
          "location": {
            "latitude": "41.9233",
            "longitude": "-88.0246"
          }
        },
        {
          "trailerNumber": "218963",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "808629",
          "location": {
            "latitude": "41.82",
            "longitude": "-87.7063"
          }
        },
        {
          "trailerNumber": "218967",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "761277",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219427",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "875068",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "218968",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ481689",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "843260",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ323230",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ104524",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "715500",
          "location": {
            "latitude": "41.1719",
            "longitude": "-73.1518"
          }
        },
        {
          "trailerNumber": "363773",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "218955",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MAR560122",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "879106",
          "location": {
            "latitude": "34.0439",
            "longitude": "-117.5845"
          }
        },
        {
          "trailerNumber": "218952",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "369627",
          "location": {
            "latitude": "40.6751",
            "longitude": "-89.5831"
          }
        },
        {
          "trailerNumber": "968278",
          "location": {
            "latitude": "30.0253",
            "longitude": "-90.0275"
          }
        },
        {
          "trailerNumber": "MTRZ562463",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "376502",
          "location": {
            "latitude": "33.9335",
            "longitude": "-81.1055"
          }
        },
        {
          "trailerNumber": "215895",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "875594",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218957",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ112163",
          "location": {
            "latitude": "35.0527",
            "longitude": "-89.9678"
          }
        },
        {
          "trailerNumber": "219015",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "606324",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQU238412",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "602304",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ264193",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "369482",
          "location": {
            "latitude": "40.6733",
            "longitude": "-75.3663"
          }
        },
        {
          "trailerNumber": "218959",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQU238278",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "215339",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218956",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ100777",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "365835",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "827468",
          "location": {
            "latitude": "34.0515",
            "longitude": "-117.5931"
          }
        },
        {
          "trailerNumber": "377673",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "369324",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MLNZ900225",
          "location": {
            "latitude": "39.9826",
            "longitude": "-83.1343"
          }
        },
        {
          "trailerNumber": "218916",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ268228",
          "location": {
            "latitude": "39.0993",
            "longitude": "-84.5259"
          }
        },
        {
          "trailerNumber": "748281",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "775552",
          "location": {
            "latitude": "38.7771",
            "longitude": "-90.4652"
          }
        },
        {
          "trailerNumber": "377109",
          "location": {
            "latitude": "41.7519",
            "longitude": "-87.8696"
          }
        },
        {
          "trailerNumber": "218922",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218672",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218923",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218918",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364862",
          "location": {
            "latitude": "42.8826",
            "longitude": "-78.8082"
          }
        },
        {
          "trailerNumber": "339730",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MEQZ268304",
          "location": {
            "latitude": "45.7597",
            "longitude": "-108.5633"
          }
        },
        {
          "trailerNumber": "MTRZ324218",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "306316",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218917",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "366283",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "887728",
          "location": {
            "latitude": "32.8864",
            "longitude": "-96.882"
          }
        },
        {
          "trailerNumber": "219087",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724473",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "366335",
          "location": {
            "latitude": "44.999",
            "longitude": "-93.2142"
          }
        },
        {
          "trailerNumber": "369375",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ534943",
          "location": {
            "latitude": "40.1627",
            "longitude": "-75.1372"
          }
        },
        {
          "trailerNumber": "873525",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "371209",
          "location": {
            "latitude": "41.3813",
            "longitude": "-81.8281"
          }
        },
        {
          "trailerNumber": "346163",
          "location": {
            "latitude": "45.5698",
            "longitude": "-122.5641"
          }
        },
        {
          "trailerNumber": "218834",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "305622",
          "location": {
            "latitude": "37.3454",
            "longitude": "-77.3402"
          }
        },
        {
          "trailerNumber": "EJGZ101731",
          "location": {
            "latitude": "40.172",
            "longitude": "-77.2374"
          }
        },
        {
          "trailerNumber": "MTRZ324225",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ568519",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "378700",
          "location": {
            "latitude": "39.8686",
            "longitude": "-75.0215"
          }
        },
        {
          "trailerNumber": "219388",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219387",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "374407",
          "location": {
            "latitude": "41.2042",
            "longitude": "-95.9516"
          }
        },
        {
          "trailerNumber": "CTLZ531589",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "368620",
          "location": {
            "latitude": "33.9335",
            "longitude": "-81.1055"
          }
        },
        {
          "trailerNumber": "218914",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218912",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ901716",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "746901",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ993073",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "383291",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ900844",
          "location": {
            "latitude": "36.0605",
            "longitude": "-115.1579"
          }
        },
        {
          "trailerNumber": "218941",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "724641",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ101515",
          "location": {
            "latitude": "40.7957",
            "longitude": "-74.0261"
          }
        },
        {
          "trailerNumber": "218931",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "PIFZ097633",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748163",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "875422",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "722774",
          "location": {
            "latitude": "31.8162",
            "longitude": "-106.3957"
          }
        },
        {
          "trailerNumber": "MTRZ324269",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "737511",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218946",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "605014",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "218935",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MTRZ324187",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "216212",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ324185",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219394",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "377930",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "MTRZ324184",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218919",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "218937",
          "location": {
            "latitude": "40.7783",
            "longitude": "-74.0598"
          }
        },
        {
          "trailerNumber": "303859",
          "location": {
            "latitude": "37.8496",
            "longitude": "-121.283"
          }
        },
        {
          "trailerNumber": "724996",
          "location": {
            "latitude": "37.3192",
            "longitude": "-79.9808"
          }
        },
        {
          "trailerNumber": "CTLZ531352",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "218924",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "373629",
          "location": {
            "latitude": "38.7771",
            "longitude": "-90.4652"
          }
        },
        {
          "trailerNumber": "724308",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724947",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "ECDU296295",
          "location": {
            "latitude": "36.0256",
            "longitude": "-79.7826"
          }
        },
        {
          "trailerNumber": "MEQZ268950",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ261173",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218927",
          "location": {
            "latitude": "40.7783",
            "longitude": "-74.0598"
          }
        },
        {
          "trailerNumber": "MLNZ907298",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218951",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNU537060",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "218949",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNU880658",
          "location": {
            "latitude": "40.2236",
            "longitude": "-79.612"
          }
        },
        {
          "trailerNumber": "MTRZ324243",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ883405",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338597",
          "location": {
            "latitude": "48.1877",
            "longitude": "-103.6265"
          }
        },
        {
          "trailerNumber": "MTRZ321374",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "757444",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "775506",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "724646",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "842482",
          "location": {
            "latitude": "33.7885",
            "longitude": "-84.4366"
          }
        },
        {
          "trailerNumber": "MTRZ321378",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "370768",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "948762",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "308752",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "377873",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "340543",
          "location": {
            "latitude": "29.675",
            "longitude": "-95.3248"
          }
        },
        {
          "trailerNumber": "MTRZ562475",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219001",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MTRZ321375",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ562472",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ104754",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "342826",
          "location": {
            "latitude": "42.9203",
            "longitude": "-87.9205"
          }
        },
        {
          "trailerNumber": "184423",
          "location": {
            "latitude": "41.5901",
            "longitude": "-83.6675"
          }
        },
        {
          "trailerNumber": "MTRZ569273",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ269940",
          "location": {
            "latitude": "36.007",
            "longitude": "-77.7883"
          }
        },
        {
          "trailerNumber": "336988",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ821340",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "849083",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "791506",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "366492",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "610031",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ562489",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ562492",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ324273",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ532183",
          "location": {
            "latitude": "40.2236",
            "longitude": "-79.612"
          }
        },
        {
          "trailerNumber": "PTLZ580745",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "94706",
          "location": {
            "latitude": "29.4858",
            "longitude": "-98.3628"
          }
        },
        {
          "trailerNumber": "MTRZ324193",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "602559",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "345400",
          "location": {
            "latitude": "38.6257",
            "longitude": "-90.2172"
          }
        },
        {
          "trailerNumber": "MEQZ267968",
          "location": {
            "latitude": "40.172",
            "longitude": "-77.2374"
          }
        },
        {
          "trailerNumber": "180246",
          "location": {
            "latitude": "36.1772",
            "longitude": "-115.1589"
          }
        },
        {
          "trailerNumber": "339800",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "939223",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "889341",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "MTRZ322632",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ324272",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "344622",
          "location": {
            "latitude": "40.1627",
            "longitude": "-75.1372"
          }
        },
        {
          "trailerNumber": "724960",
          "location": {
            "latitude": "26.6199",
            "longitude": "-81.8452"
          }
        },
        {
          "trailerNumber": "MTRZ324190",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724940",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "844284",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ305186",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "369298",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ100307",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "CTLZ480345",
          "location": {
            "latitude": "33.9335",
            "longitude": "-81.1055"
          }
        },
        {
          "trailerNumber": "MTRZ324195",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "721729",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748366",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "725132",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "841359",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "MTRZ324276",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364548",
          "location": {
            "latitude": "32.9136",
            "longitude": "-97.0675"
          }
        },
        {
          "trailerNumber": "MTRZ324196",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ531301",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "376715",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MTRZ624272",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ264023",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ482478",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ120336",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218856",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "185381",
          "location": {
            "latitude": "34.0623",
            "longitude": "-118.2241"
          }
        },
        {
          "trailerNumber": "378423",
          "location": {
            "latitude": "36.0605",
            "longitude": "-115.1579"
          }
        },
        {
          "trailerNumber": "941670",
          "location": {
            "latitude": "37.3192",
            "longitude": "-79.9808"
          }
        },
        {
          "trailerNumber": "722481",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ81038",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218841",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "307251",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "179324",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624275",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "369877",
          "location": {
            "latitude": "34.0439",
            "longitude": "-117.5845"
          }
        },
        {
          "trailerNumber": "MTRZ624276",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "704041",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ532585",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ621916",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219607",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ622145",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218697",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ532642",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ154272",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ620286",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "876509",
          "location": {
            "latitude": "29.8794",
            "longitude": "-95.4077"
          }
        },
        {
          "trailerNumber": "EJGZ101777",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ621861",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "309101",
          "location": {
            "latitude": "41.5901",
            "longitude": "-83.6675"
          }
        },
        {
          "trailerNumber": "EJGZ103582",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "371551",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218693",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ324201",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ533195",
          "location": {
            "latitude": "39.6116",
            "longitude": "-77.7236"
          }
        },
        {
          "trailerNumber": "EJGZ100621",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ270075",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "382843",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "MTRZ624298",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "609802",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ622567",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ270352",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "382267",
          "location": {
            "latitude": "37.3192",
            "longitude": "-79.9808"
          }
        },
        {
          "trailerNumber": "BOWZ100202",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218698",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "376355",
          "location": {
            "latitude": "35.1247",
            "longitude": "-106.6189"
          }
        },
        {
          "trailerNumber": "MLNZ902344",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ094715",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "309247",
          "location": {
            "latitude": "32.2662",
            "longitude": "-90.2048"
          }
        },
        {
          "trailerNumber": "MTRZ324200",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ467729",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "972056",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741217",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "367305",
          "location": {
            "latitude": "36.0605",
            "longitude": "-115.1579"
          }
        },
        {
          "trailerNumber": "373142",
          "location": {
            "latitude": "35.8368",
            "longitude": "-78.6019"
          }
        },
        {
          "trailerNumber": "721839",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ270391",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "721865",
          "location": {
            "latitude": "40.7783",
            "longitude": "-74.0598"
          }
        },
        {
          "trailerNumber": "948900",
          "location": {
            "latitude": "34.0439",
            "longitude": "-117.5845"
          }
        },
        {
          "trailerNumber": "PTLZ591346",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "336475",
          "location": {
            "latitude": "36.0256",
            "longitude": "-79.7826"
          }
        },
        {
          "trailerNumber": "343172",
          "location": {
            "latitude": "28.6921",
            "longitude": "-81.3528"
          }
        },
        {
          "trailerNumber": "605060",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "345875",
          "location": {
            "latitude": "38.5727",
            "longitude": "-121.5463"
          }
        },
        {
          "trailerNumber": "365472",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722339",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364565",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ534423",
          "location": {
            "latitude": "41.2042",
            "longitude": "-95.9516"
          }
        },
        {
          "trailerNumber": "825079",
          "location": {
            "latitude": "45.8027",
            "longitude": "-108.5527"
          }
        },
        {
          "trailerNumber": "601253",
          "location": {
            "latitude": "32.7844",
            "longitude": "-96.6563"
          }
        },
        {
          "trailerNumber": "366488",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "849465",
          "location": {
            "latitude": "39.6889",
            "longitude": "-75.7012"
          }
        },
        {
          "trailerNumber": "704109",
          "location": {
            "latitude": "41.2665",
            "longitude": "-73.778"
          }
        },
        {
          "trailerNumber": "703018",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "309573",
          "location": {
            "latitude": "41.2042",
            "longitude": "-95.9516"
          }
        },
        {
          "trailerNumber": "EJGZ100703",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722383",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "305783",
          "location": {
            "latitude": "44.999",
            "longitude": "-93.2142"
          }
        },
        {
          "trailerNumber": "366086",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "MLNU881038",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ901100",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "215061",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ211088",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "738680",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "874422",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724889",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "367852",
          "location": {
            "latitude": "33.9335",
            "longitude": "-81.1055"
          }
        },
        {
          "trailerNumber": "742311",
          "location": {
            "latitude": "36.2313",
            "longitude": "-86.8132"
          }
        },
        {
          "trailerNumber": "CTLZ531637",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "381183",
          "location": {
            "latitude": "43.0569",
            "longitude": "-77.6494"
          }
        },
        {
          "trailerNumber": "347005",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MTRZ560835",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "703316",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741250",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "721877",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "371240",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "306343",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ483041",
          "location": {
            "latitude": "40.6421",
            "longitude": "-73.9016"
          }
        },
        {
          "trailerNumber": "741158",
          "location": {
            "latitude": "47.6657",
            "longitude": "-117.3097"
          }
        },
        {
          "trailerNumber": "BOWZ606301",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "347251",
          "location": {
            "latitude": "32.2662",
            "longitude": "-90.2048"
          }
        },
        {
          "trailerNumber": "369542",
          "location": {
            "latitude": "40.7623",
            "longitude": "-73.9997"
          }
        },
        {
          "trailerNumber": "877711",
          "location": {
            "latitude": "33.9344",
            "longitude": "-81.0982"
          }
        },
        {
          "trailerNumber": "MECZ467051",
          "location": {
            "latitude": "39.864",
            "longitude": "-82.9417"
          }
        },
        {
          "trailerNumber": "842117",
          "location": {
            "latitude": "40.1627",
            "longitude": "-75.1372"
          }
        },
        {
          "trailerNumber": "MLNZ910038",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ885180",
          "location": {
            "latitude": "34.0102",
            "longitude": "-118.2065"
          }
        },
        {
          "trailerNumber": "844559",
          "location": {
            "latitude": "44.999",
            "longitude": "-93.2142"
          }
        },
        {
          "trailerNumber": "338518",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741110",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218821",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ097102",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ190962",
          "location": {
            "latitude": "29.8794",
            "longitude": "-95.4077"
          }
        },
        {
          "trailerNumber": "748456",
          "location": {
            "latitude": "41.5901",
            "longitude": "-83.6675"
          }
        },
        {
          "trailerNumber": "PIFZ031961",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "304532",
          "location": {
            "latitude": "37.7328",
            "longitude": "-122.2046"
          }
        },
        {
          "trailerNumber": "186356",
          "location": {
            "latitude": "34.7252",
            "longitude": "-92.2104"
          }
        },
        {
          "trailerNumber": "PTLZ243809",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "304257",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "363535",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ481471",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ135228",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "725044",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "849047",
          "location": {
            "latitude": "42.9203",
            "longitude": "-87.9205"
          }
        },
        {
          "trailerNumber": "MTRZ5622566",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "946153",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ376174",
          "location": {
            "latitude": "45.7018",
            "longitude": "-111.062"
          }
        },
        {
          "trailerNumber": "PTLZ221818",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "337956",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "724010",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "180349",
          "location": {
            "latitude": "35.8368",
            "longitude": "-78.6019"
          }
        },
        {
          "trailerNumber": "MTRZ280248",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364056",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "EJGZ103294",
          "location": {
            "latitude": "32.5824",
            "longitude": "-82.3141"
          }
        },
        {
          "trailerNumber": "MEQZ269271",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "873230",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "218675",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "W20681",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MUPZ904301",
          "location": {
            "latitude": "36.2313",
            "longitude": "-86.8132"
          }
        },
        {
          "trailerNumber": "PTLZ182093",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "363558",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "368775",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "724660",
          "location": {
            "latitude": "37.3192",
            "longitude": "-79.9808"
          }
        },
        {
          "trailerNumber": "PTLZ243847",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "841151",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741211",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724933",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "378713",
          "location": {
            "latitude": "33.896",
            "longitude": "-84.2417"
          }
        },
        {
          "trailerNumber": "372356",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "308871",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "398226",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "303792",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "703390",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "370836",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "827135",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "738929",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "PTLZ243845",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "345799",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "305475",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ243884",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "303448",
          "location": {
            "latitude": "38.7348",
            "longitude": "-77.1954"
          }
        },
        {
          "trailerNumber": "870876",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ993075",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ101246",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ000126",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "U98588",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "839234",
          "location": {
            "latitude": "28.437",
            "longitude": "-81.3594"
          }
        },
        {
          "trailerNumber": "943615",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "216684",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "FLEZ300190",
          "location": {
            "latitude": "36.2313",
            "longitude": "-86.8132"
          }
        },
        {
          "trailerNumber": "368909",
          "location": {
            "latitude": "29.8794",
            "longitude": "-95.4077"
          }
        },
        {
          "trailerNumber": "MEQZ268738",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ533791",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "939213",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364106",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748303",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MEQZ269443",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "NONZ824459",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "371739",
          "location": {
            "latitude": "32.9136",
            "longitude": "-97.0675"
          }
        },
        {
          "trailerNumber": "181681",
          "location": {
            "latitude": "35.8368",
            "longitude": "-78.6019"
          }
        },
        {
          "trailerNumber": "363379",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ267626",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "724863",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ114217",
          "location": {
            "latitude": "34.7252",
            "longitude": "-92.2104"
          }
        },
        {
          "trailerNumber": "PIFZ056474",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "181771",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "939226",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MRTZ324254",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ103779",
          "location": {
            "latitude": "37.6363",
            "longitude": "-77.4118"
          }
        },
        {
          "trailerNumber": "742369",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "307497",
          "location": {
            "latitude": "36.1516",
            "longitude": "-86.7076"
          }
        },
        {
          "trailerNumber": "368571",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "346720",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ324248",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "874183",
          "location": {
            "latitude": "41.6924",
            "longitude": "-88.0496"
          }
        },
        {
          "trailerNumber": "365889",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748283",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ270256",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741184",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724970",
          "location": {
            "latitude": "39.9826",
            "longitude": "-83.1343"
          }
        },
        {
          "trailerNumber": "MUPZ910341",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "304127",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "383323",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "370614",
          "location": {
            "latitude": "27.8864",
            "longitude": "-82.7104"
          }
        },
        {
          "trailerNumber": "CTLZ450736",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "348517",
          "location": {
            "latitude": "36.0782",
            "longitude": "-95.8453"
          }
        },
        {
          "trailerNumber": "985761",
          "location": {
            "latitude": "40.7147",
            "longitude": "-111.9451"
          }
        },
        {
          "trailerNumber": "CTLZ532463",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "887852",
          "location": {
            "latitude": "36.2313",
            "longitude": "-86.8132"
          }
        },
        {
          "trailerNumber": "938919",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ097462",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ088232",
          "location": {
            "latitude": "36.0616",
            "longitude": "-115.1605"
          }
        },
        {
          "trailerNumber": "876982",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ280236",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724664",
          "location": {
            "latitude": "38.1257",
            "longitude": "-85.7278"
          }
        },
        {
          "trailerNumber": "375276",
          "location": {
            "latitude": "40.7783",
            "longitude": "-74.0598"
          }
        },
        {
          "trailerNumber": "827423",
          "location": {
            "latitude": "29.4858",
            "longitude": "-98.3628"
          }
        },
        {
          "trailerNumber": "218973",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ884389",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "217155",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "337166",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "840510",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ104099",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ603643",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724982",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "PCAZ976541",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722079",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "992727",
          "location": {
            "latitude": "38.5727",
            "longitude": "-121.5463"
          }
        },
        {
          "trailerNumber": "USAZ819461",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "218987",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364656",
          "location": {
            "latitude": "38.8928",
            "longitude": "-77.4615"
          }
        },
        {
          "trailerNumber": "CTLZ531651",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ100785",
          "location": {
            "latitude": "34.0515",
            "longitude": "-117.5931"
          }
        },
        {
          "trailerNumber": "827931",
          "location": {
            "latitude": "42.2498",
            "longitude": "-71.7143"
          }
        },
        {
          "trailerNumber": "365042",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "341901",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ100651",
          "location": {
            "latitude": "28.437",
            "longitude": "-81.3594"
          }
        },
        {
          "trailerNumber": "840630",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "NONZ112828",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218031",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ094676",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ269723",
          "location": {
            "latitude": "39.1182",
            "longitude": "-77.1928"
          }
        },
        {
          "trailerNumber": "703582",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "USAZ822579",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "348260",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "703433",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "553999",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218983",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "742283",
          "location": {
            "latitude": "32.8864",
            "longitude": "-96.882"
          }
        },
        {
          "trailerNumber": "345330",
          "location": {
            "latitude": "38.8928",
            "longitude": "-77.4615"
          }
        },
        {
          "trailerNumber": "ECDU295003",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "730511",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "216949",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218971",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624327",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "370451",
          "location": {
            "latitude": "35.8368",
            "longitude": "-78.6019"
          }
        },
        {
          "trailerNumber": "889167",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "337646",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "219704",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ094820",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "216160",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624279",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "757430",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MAGZ901903",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ462453",
          "location": {
            "latitude": "39.9027",
            "longitude": "-86.2552"
          }
        },
        {
          "trailerNumber": "218993",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "844299",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "UMXU252206",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ900907",
          "location": {
            "latitude": "33.9335",
            "longitude": "-81.1055"
          }
        },
        {
          "trailerNumber": "724937",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "748317",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MEQZ269929",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "398981",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ532878",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "308832",
          "location": {
            "latitude": "40.7512",
            "longitude": "-74.0708"
          }
        },
        {
          "trailerNumber": "342431",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "397762",
          "location": {
            "latitude": "40.1959",
            "longitude": "-76.7491"
          }
        },
        {
          "trailerNumber": "EJGZ100748",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "EJGZ515727",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338791",
          "location": {
            "latitude": "43.4597",
            "longitude": "-110.799"
          }
        },
        {
          "trailerNumber": "184342",
          "location": {
            "latitude": "35.4566",
            "longitude": "-97.5835"
          }
        },
        {
          "trailerNumber": "MECZ462013",
          "location": {
            "latitude": "40.1962",
            "longitude": "-76.7457"
          }
        },
        {
          "trailerNumber": "MLNZ902244",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "377783",
          "location": {
            "latitude": "39.1182",
            "longitude": "-77.1928"
          }
        },
        {
          "trailerNumber": "742024",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "378369",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ267947",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ997456",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ211667",
          "location": {
            "latitude": "34.8",
            "longitude": "-82.3458"
          }
        },
        {
          "trailerNumber": "PTLZ988525",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "606479",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338973",
          "location": {
            "latitude": "35.1593",
            "longitude": "-103.7008"
          }
        },
        {
          "trailerNumber": "877206",
          "location": {
            "latitude": "41.6202",
            "longitude": "-93.5887"
          }
        },
        {
          "trailerNumber": "308729",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "738909",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "370377",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "374819",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ901875",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "737805",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "368156",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "605352",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "336783",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "PTLZ181725",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219006",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "308799",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "605097",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ900731",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ048074",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338220",
          "location": {
            "latitude": "42.2498",
            "longitude": "-71.7143"
          }
        },
        {
          "trailerNumber": "EJGZ101817",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "721584",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "879451",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "MLNZ907663",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "365299",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722382",
          "location": {
            "latitude": "39.9027",
            "longitude": "-86.2552"
          }
        },
        {
          "trailerNumber": "309491",
          "location": {
            "latitude": "44.0744",
            "longitude": "-121.3039"
          }
        },
        {
          "trailerNumber": "370032",
          "location": {
            "latitude": "39.8613",
            "longitude": "-75.2512"
          }
        },
        {
          "trailerNumber": "219008",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219013",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ902006",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ031768",
          "location": {
            "latitude": "39.8613",
            "longitude": "-75.2512"
          }
        },
        {
          "trailerNumber": "MUPZ903824",
          "location": {
            "latitude": "42.2604",
            "longitude": "-71.7925"
          }
        },
        {
          "trailerNumber": "399103",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722636",
          "location": {
            "latitude": "38.0529",
            "longitude": "-85.7066"
          }
        },
        {
          "trailerNumber": "399376",
          "location": {
            "latitude": "41.763",
            "longitude": "-87.7942"
          }
        },
        {
          "trailerNumber": "NONZ321267",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "217881",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338517",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "370717",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "PTLZ590286",
          "location": {
            "latitude": "33.6013",
            "longitude": "-84.3829"
          }
        },
        {
          "trailerNumber": "MLNZ910039",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ195093",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "U95008",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "378332",
          "location": {
            "latitude": "38.8007",
            "longitude": "-77.1286"
          }
        },
        {
          "trailerNumber": "377741",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "370709",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "377854",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "MLNU886403",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218985",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "757220",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218989",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "738789",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "742052",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "BOWZ100097",
          "location": {
            "latitude": "37.3192",
            "longitude": "-79.9808"
          }
        },
        {
          "trailerNumber": "841432",
          "location": {
            "latitude": "29.4858",
            "longitude": "-98.3628"
          }
        },
        {
          "trailerNumber": "757268",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ664181",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ467039",
          "location": {
            "latitude": "40.6733",
            "longitude": "-75.3663"
          }
        },
        {
          "trailerNumber": "219000",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "346427",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ978513",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "378370",
          "location": {
            "latitude": "32.9795",
            "longitude": "-97.2505"
          }
        },
        {
          "trailerNumber": "PIFZ034624",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "R53826",
          "location": {
            "latitude": "28.437",
            "longitude": "-81.3594"
          }
        },
        {
          "trailerNumber": "601511",
          "location": {
            "latitude": "37.9948",
            "longitude": "-122.3411"
          }
        },
        {
          "trailerNumber": "366258",
          "location": {
            "latitude": "42.2498",
            "longitude": "-71.7143"
          }
        },
        {
          "trailerNumber": "748167",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "BOWZ279119",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ821435",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "532652",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "USAZ882305",
          "location": {
            "latitude": "43.1061",
            "longitude": "-76.0768"
          }
        },
        {
          "trailerNumber": "366651",
          "location": {
            "latitude": "32.7922",
            "longitude": "-96.6646"
          }
        },
        {
          "trailerNumber": "MEQZ269529",
          "location": {
            "latitude": "41.7336",
            "longitude": "-71.4462"
          }
        },
        {
          "trailerNumber": "738673",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "303901",
          "location": {
            "latitude": "47.2179",
            "longitude": "-122.2399"
          }
        },
        {
          "trailerNumber": "378493",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "365444",
          "location": {
            "latitude": "33.896",
            "longitude": "-84.2417"
          }
        },
        {
          "trailerNumber": "EJGZ101703",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ900504",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722741",
          "location": {
            "latitude": "35.8368",
            "longitude": "-78.6019"
          }
        },
        {
          "trailerNumber": "380363",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "181292",
          "location": {
            "latitude": "42.9203",
            "longitude": "-87.9205"
          }
        },
        {
          "trailerNumber": "BOWZ100079",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "MECZ465510",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ621842",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "367067",
          "location": {
            "latitude": "46.8914",
            "longitude": "-96.848"
          }
        },
        {
          "trailerNumber": "365625",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "339640",
          "location": {
            "latitude": "34.0643",
            "longitude": "-117.9848"
          }
        },
        {
          "trailerNumber": "219033",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724390",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "737853",
          "location": {
            "latitude": "38.0529",
            "longitude": "-85.7066"
          }
        },
        {
          "trailerNumber": "721641",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "346349",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "878658",
          "location": {
            "latitude": "39.2793",
            "longitude": "-84.4391"
          }
        },
        {
          "trailerNumber": "337411",
          "location": {
            "latitude": "34.2156",
            "longitude": "-88.723"
          }
        },
        {
          "trailerNumber": "219035",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "306906",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "398938",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "219022",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "376533",
          "location": {
            "latitude": "45.7597",
            "longitude": "-108.5633"
          }
        },
        {
          "trailerNumber": "808507",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "MLNU884109",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "380830",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ008503",
          "location": {
            "latitude": "36.2313",
            "longitude": "-86.8132"
          }
        },
        {
          "trailerNumber": "219023",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "942786",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "219036",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219027",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "843091",
          "location": {
            "latitude": "37.7328",
            "longitude": "-122.2046"
          }
        },
        {
          "trailerNumber": "874797",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "307666",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "5621966",
          "location": {
            "latitude": "42.3808",
            "longitude": "-83.3393"
          }
        },
        {
          "trailerNumber": "347418",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219011",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338038",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ483033",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219030",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "843726",
          "location": {
            "latitude": "28.437",
            "longitude": "-81.3594"
          }
        },
        {
          "trailerNumber": "370009",
          "location": {
            "latitude": "39.6996",
            "longitude": "-86.3455"
          }
        },
        {
          "trailerNumber": "345447",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "382354",
          "location": {
            "latitude": "30.0253",
            "longitude": "-90.0275"
          }
        },
        {
          "trailerNumber": "BOWZ227713",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "336574",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "377104",
          "location": {
            "latitude": "47.6657",
            "longitude": "-117.3097"
          }
        },
        {
          "trailerNumber": "377140",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "876314",
          "location": {
            "latitude": "32.7922",
            "longitude": "-96.6646"
          }
        },
        {
          "trailerNumber": "309554",
          "location": {
            "latitude": "40.1641",
            "longitude": "-75.0867"
          }
        },
        {
          "trailerNumber": "173554",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "785515",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "738690",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "738883",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ900393",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "346169",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "722223",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219052",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "757197",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "942784",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "PTLZ581167",
          "location": {
            "latitude": "36.0256",
            "longitude": "-79.7826"
          }
        },
        {
          "trailerNumber": "307998",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "219053",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MECZ660125",
          "location": {
            "latitude": "42.8826",
            "longitude": "-78.8082"
          }
        },
        {
          "trailerNumber": "MEQZ269067",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "PCAZ103730",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "PTLZ202801",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "839569",
          "location": {
            "latitude": "34.0102",
            "longitude": "-118.2065"
          }
        },
        {
          "trailerNumber": "871572",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "887005",
          "location": {
            "latitude": "45.7597",
            "longitude": "-108.5633"
          }
        },
        {
          "trailerNumber": "219044",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219043",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "367125",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ111405",
          "location": {
            "latitude": "41.5901",
            "longitude": "-83.6675"
          }
        },
        {
          "trailerNumber": "219041",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "219050",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ819993",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "304734",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "875330",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "757351",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ100556",
          "location": {
            "latitude": "39.5348",
            "longitude": "-119.7039"
          }
        },
        {
          "trailerNumber": "741138",
          "location": {
            "latitude": "32.9136",
            "longitude": "-97.0675"
          }
        },
        {
          "trailerNumber": "374640",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "334803",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MECZ885036",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "338525",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "304655",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "703841",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ538284",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "180376",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "EJGZ101698",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219068",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "748380",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748278",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "219077",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "887087",
          "location": {
            "latitude": "45.5714",
            "longitude": "-122.7179"
          }
        },
        {
          "trailerNumber": "877326",
          "location": {
            "latitude": "32.7922",
            "longitude": "-96.6646"
          }
        },
        {
          "trailerNumber": "219074",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "365335",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "346906",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "304280",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MEQZ267990",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219071",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "827408",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "345732",
          "location": {
            "latitude": "45.1136",
            "longitude": "-93.4156"
          }
        },
        {
          "trailerNumber": "304522",
          "location": {
            "latitude": "37.4063",
            "longitude": "-121.9905"
          }
        },
        {
          "trailerNumber": "345756",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "BOWZ226807",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ000214",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "841479",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "757454",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "742246",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338790",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ211281",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "876785",
          "location": {
            "latitude": "41.9233",
            "longitude": "-88.0246"
          }
        },
        {
          "trailerNumber": "703595",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "PTLZ211571",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ269240",
          "location": {
            "latitude": "41.6202",
            "longitude": "-93.5887"
          }
        },
        {
          "trailerNumber": "MTRZ536280",
          "location": {
            "latitude": "33.8863",
            "longitude": "-118.049"
          }
        },
        {
          "trailerNumber": "849013",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "181817",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "382416",
          "location": {
            "latitude": "45.6313",
            "longitude": "-89.3962"
          }
        },
        {
          "trailerNumber": "998996",
          "location": {
            "latitude": "35.4566",
            "longitude": "-97.5835"
          }
        },
        {
          "trailerNumber": "744425",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ268782",
          "location": {
            "latitude": "35.8368",
            "longitude": "-78.6019"
          }
        },
        {
          "trailerNumber": "372128",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "307549",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "377969",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748359",
          "location": {
            "latitude": "33.8756",
            "longitude": "-118.3093"
          }
        },
        {
          "trailerNumber": "737861",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "946123",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "MUPZ903492",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "839049",
          "location": {
            "latitude": "41.2422",
            "longitude": "-87.8039"
          }
        },
        {
          "trailerNumber": "376326",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "383052",
          "location": {
            "latitude": "37.8496",
            "longitude": "-121.283"
          }
        },
        {
          "trailerNumber": "CATZ088011",
          "location": {
            "latitude": "35.0527",
            "longitude": "-89.9678"
          }
        },
        {
          "trailerNumber": "377768",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "218868",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "943614",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "383091",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ260278",
          "location": {
            "latitude": "38.8928",
            "longitude": "-77.4615"
          }
        },
        {
          "trailerNumber": "179578",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "378756",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "878167",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "609831",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "PCAZ087766",
          "location": {
            "latitude": "29.6194",
            "longitude": "-95.5559"
          }
        },
        {
          "trailerNumber": "724245",
          "location": {
            "latitude": "38.7771",
            "longitude": "-90.4652"
          }
        },
        {
          "trailerNumber": "346799",
          "location": {
            "latitude": "43.1061",
            "longitude": "-76.0768"
          }
        },
        {
          "trailerNumber": "346633",
          "location": {
            "latitude": "37.9398",
            "longitude": "-85.6883"
          }
        },
        {
          "trailerNumber": "345348",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "375138",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "840274",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724517",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "CTLZ531957",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "339737",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "948775",
          "location": {
            "latitude": "35.4566",
            "longitude": "-97.5835"
          }
        },
        {
          "trailerNumber": "382021",
          "location": {
            "latitude": "34.0102",
            "longitude": "-118.2065"
          }
        },
        {
          "trailerNumber": "778900",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "877378",
          "location": {
            "latitude": "43.1061",
            "longitude": "-76.0768"
          }
        },
        {
          "trailerNumber": "757228",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "366779",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "728750",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "377298",
          "location": {
            "latitude": "34.0102",
            "longitude": "-118.2065"
          }
        },
        {
          "trailerNumber": "602059",
          "location": {
            "latitude": "42.5941",
            "longitude": "-71.3054"
          }
        },
        {
          "trailerNumber": "MEQZ267665",
          "location": {
            "latitude": "36.0256",
            "longitude": "-79.7826"
          }
        },
        {
          "trailerNumber": "723902",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MUPZ903314",
          "location": {
            "latitude": "39.6724",
            "longitude": "-84.2409"
          }
        },
        {
          "trailerNumber": "370522",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "348379",
          "location": {
            "latitude": "33.896",
            "longitude": "-84.2417"
          }
        },
        {
          "trailerNumber": "348823",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "877656",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "307614",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "703811",
          "location": {
            "latitude": "33.8863",
            "longitude": "-118.049"
          }
        },
        {
          "trailerNumber": "USAZ811555",
          "location": {
            "latitude": "41.8988",
            "longitude": "-72.6311"
          }
        },
        {
          "trailerNumber": "742010",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "725010",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "606342",
          "location": {
            "latitude": "33.7885",
            "longitude": "-84.4366"
          }
        },
        {
          "trailerNumber": "219499",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "304966",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ531717",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "EJGZ100791",
          "location": {
            "latitude": "38.5727",
            "longitude": "-121.5463"
          }
        },
        {
          "trailerNumber": "367821",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "721542",
          "location": {
            "latitude": "42.2498",
            "longitude": "-71.7143"
          }
        },
        {
          "trailerNumber": "PTLZ203174",
          "location": {
            "latitude": "40.7231",
            "longitude": "-73.6058"
          }
        },
        {
          "trailerNumber": "344687",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "948528",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ533832",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "984880",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "371470",
          "location": {
            "latitude": "44.999",
            "longitude": "-93.2142"
          }
        },
        {
          "trailerNumber": "344983",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ534976",
          "location": {
            "latitude": "33.8138",
            "longitude": "-117.8937"
          }
        },
        {
          "trailerNumber": "723174",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ376305",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "742196",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "216265",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "917408",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MTRZ322563",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "371572",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364341",
          "location": {
            "latitude": "42.76",
            "longitude": "-71.4911"
          }
        },
        {
          "trailerNumber": "397442",
          "location": {
            "latitude": "39.7879",
            "longitude": "-104.9199"
          }
        },
        {
          "trailerNumber": "374525",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "345715",
          "location": {
            "latitude": "35.1923",
            "longitude": "-101.749"
          }
        },
        {
          "trailerNumber": "348751",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "808435",
          "location": {
            "latitude": "41.9233",
            "longitude": "-88.0246"
          }
        },
        {
          "trailerNumber": "371753",
          "location": {
            "latitude": "42.2498",
            "longitude": "-71.7143"
          }
        },
        {
          "trailerNumber": "MECZ464626",
          "location": {
            "latitude": "40.7284",
            "longitude": "-73.9245"
          }
        },
        {
          "trailerNumber": "723816",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "309409",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "721528",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "730568",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748898",
          "location": {
            "latitude": "35.0527",
            "longitude": "-89.9678"
          }
        },
        {
          "trailerNumber": "USAZ883904",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "132154",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "338623",
          "location": {
            "latitude": "46.2315",
            "longitude": "-84.5924"
          }
        },
        {
          "trailerNumber": "808417",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "345326",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "757180",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "53602",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "730564",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ811736",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "725092",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "376513",
          "location": {
            "latitude": "41.8091",
            "longitude": "-87.6362"
          }
        },
        {
          "trailerNumber": "PRTZ137004",
          "location": {
            "latitude": "32.7844",
            "longitude": "-96.6563"
          }
        },
        {
          "trailerNumber": "737951",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ569378",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MLNZ902238",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "CTLZ532811",
          "location": {
            "latitude": "41.5901",
            "longitude": "-83.6675"
          }
        },
        {
          "trailerNumber": "757139",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "603624",
          "location": {
            "latitude": "36.0564",
            "longitude": "-79.855"
          }
        },
        {
          "trailerNumber": "374028",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ111369",
          "location": {
            "latitude": "35.0527",
            "longitude": "-89.9678"
          }
        },
        {
          "trailerNumber": "W88688",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219019",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ531776",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "849330",
          "location": {
            "latitude": "32.9136",
            "longitude": "-97.0675"
          }
        },
        {
          "trailerNumber": "375546",
          "location": {
            "latitude": "46.3339",
            "longitude": "-94.1944"
          }
        },
        {
          "trailerNumber": "382875",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "600667",
          "location": {
            "latitude": "36.2313",
            "longitude": "-86.8132"
          }
        },
        {
          "trailerNumber": "721812",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "703575",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "216191",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "337402",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ267121",
          "location": {
            "latitude": "36.0256",
            "longitude": "-79.7826"
          }
        },
        {
          "trailerNumber": "839190",
          "location": {
            "latitude": "43.1061",
            "longitude": "-76.0768"
          }
        },
        {
          "trailerNumber": "MLNZ900829",
          "location": {
            "latitude": "41.8988",
            "longitude": "-72.6311"
          }
        },
        {
          "trailerNumber": "844871",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "369691",
          "location": {
            "latitude": "37.8496",
            "longitude": "-121.283"
          }
        },
        {
          "trailerNumber": "397709",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "367485",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "848876",
          "location": {
            "latitude": "40.2236",
            "longitude": "-79.612"
          }
        },
        {
          "trailerNumber": "939247",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "721959",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "373144",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "305854",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "ECDU295679",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PCAZ113900",
          "location": {
            "latitude": "39.9826",
            "longitude": "-83.1343"
          }
        },
        {
          "trailerNumber": "MTRZ530288",
          "location": {
            "latitude": "42.2027",
            "longitude": "-89.1069"
          }
        },
        {
          "trailerNumber": "606476",
          "location": {
            "latitude": "41.7519",
            "longitude": "-87.8696"
          }
        },
        {
          "trailerNumber": "721808",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "MEQZ267093",
          "location": {
            "latitude": "33.896",
            "longitude": "-84.2417"
          }
        },
        {
          "trailerNumber": "EJGZ102372",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "EJGZ100500",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "877497",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "MEQZ268040",
          "location": {
            "latitude": "36.8925",
            "longitude": "-76.1914"
          }
        },
        {
          "trailerNumber": "305578",
          "location": {
            "latitude": "32.8864",
            "longitude": "-96.882"
          }
        },
        {
          "trailerNumber": "876590",
          "location": {
            "latitude": "25.8738",
            "longitude": "-80.2637"
          }
        },
        {
          "trailerNumber": "675569",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "827105",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "871464",
          "location": {
            "latitude": "41.9373",
            "longitude": "-72.6749"
          }
        },
        {
          "trailerNumber": "303027",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "367808",
          "location": {
            "latitude": "41.82",
            "longitude": "-87.7063"
          }
        },
        {
          "trailerNumber": "703007",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "721644",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "839373",
          "location": {
            "latitude": "43.1061",
            "longitude": "-76.0768"
          }
        },
        {
          "trailerNumber": "748311",
          "location": {
            "latitude": "38.1591",
            "longitude": "-85.7231"
          }
        },
        {
          "trailerNumber": "879755",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "364118",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "185620",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "PIFZ054493",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "181649",
          "location": {
            "latitude": "32.9886",
            "longitude": "-97.3309"
          }
        },
        {
          "trailerNumber": "748300",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "948902",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "369708",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "738861",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PTLZ202836",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ035925",
          "location": {
            "latitude": "41.6202",
            "longitude": "-93.5887"
          }
        },
        {
          "trailerNumber": "EJGZ103290",
          "location": {
            "latitude": "44.999",
            "longitude": "-93.2142"
          }
        },
        {
          "trailerNumber": "724621",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "378819",
          "location": {
            "latitude": "37.7986",
            "longitude": "-122.2966"
          }
        },
        {
          "trailerNumber": "CTLZ537050",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ534435",
          "location": {
            "latitude": "43.5441",
            "longitude": "-89.9835"
          }
        },
        {
          "trailerNumber": "181218",
          "location": {
            "latitude": "40.172",
            "longitude": "-77.2374"
          }
        },
        {
          "trailerNumber": "375558",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ102299",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "374561",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "604211",
          "location": {
            "latitude": "41.754",
            "longitude": "-87.8772"
          }
        },
        {
          "trailerNumber": "MLNZ900062",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "778507",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "939229",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ819702",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "344052",
          "location": {
            "latitude": "44.8001",
            "longitude": "-91.4636"
          }
        },
        {
          "trailerNumber": "MLNZ902072",
          "location": {
            "latitude": "30.3763",
            "longitude": "-81.773"
          }
        },
        {
          "trailerNumber": "MTRZ624278",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ269841",
          "location": {
            "latitude": "42.76",
            "longitude": "-71.4911"
          }
        },
        {
          "trailerNumber": "363358",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "308333",
          "location": {
            "latitude": "38.1279",
            "longitude": "-85.7189"
          }
        },
        {
          "trailerNumber": "948742",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364278",
          "location": {
            "latitude": "45.7597",
            "longitude": "-108.5633"
          }
        },
        {
          "trailerNumber": "375172",
          "location": {
            "latitude": "41.7801",
            "longitude": "-87.6206"
          }
        },
        {
          "trailerNumber": "366755",
          "location": {
            "latitude": "41.2042",
            "longitude": "-95.9516"
          }
        },
        {
          "trailerNumber": "NONZ012828",
          "location": {
            "latitude": "32.7922",
            "longitude": "-96.6646"
          }
        },
        {
          "trailerNumber": "347309",
          "location": {
            "latitude": "38.933",
            "longitude": "-94.7573"
          }
        },
        {
          "trailerNumber": "841131",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "724653",
          "location": {
            "latitude": "38.0529",
            "longitude": "-85.7066"
          }
        },
        {
          "trailerNumber": "604139",
          "location": {
            "latitude": "41.8437",
            "longitude": "-87.7562"
          }
        },
        {
          "trailerNumber": "748326",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "345266",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "364352",
          "location": {
            "latitude": "36.0782",
            "longitude": "-95.8453"
          }
        },
        {
          "trailerNumber": "MTRZ624291",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MUPZ902989",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624312",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624296",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219457",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ268710",
          "location": {
            "latitude": "39.7964",
            "longitude": "-104.9944"
          }
        },
        {
          "trailerNumber": "MTRZ624289",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MEQZ267737",
          "location": {
            "latitude": "37.8496",
            "longitude": "-121.283"
          }
        },
        {
          "trailerNumber": "306292",
          "location": {
            "latitude": "36.0256",
            "longitude": "-79.7826"
          }
        },
        {
          "trailerNumber": "MTRZ624285",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "722471",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ533734",
          "location": {
            "latitude": "42.8572",
            "longitude": "-85.6857"
          }
        },
        {
          "trailerNumber": "757256",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624288",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "EJGZ100833",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MEQZ268292",
          "location": {
            "latitude": "41.1719",
            "longitude": "-73.1518"
          }
        },
        {
          "trailerNumber": "375774",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ624316",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "382806",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "738869",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MECZ466570",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "377019",
          "location": {
            "latitude": "36.0256",
            "longitude": "-79.7826"
          }
        },
        {
          "trailerNumber": "219171",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "827911",
          "location": {
            "latitude": "36.2313",
            "longitude": "-86.8132"
          }
        },
        {
          "trailerNumber": "942550",
          "location": {
            "latitude": "38.0618",
            "longitude": "-84.5223"
          }
        },
        {
          "trailerNumber": "MTRZ624302",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "741024",
          "location": {
            "latitude": "29.9401",
            "longitude": "-95.5467"
          }
        },
        {
          "trailerNumber": "321414",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ224057",
          "location": {
            "latitude": "40.6777",
            "longitude": "-73.7555"
          }
        },
        {
          "trailerNumber": "CTLZ482162",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "775574",
          "location": {
            "latitude": "38.0529",
            "longitude": "-85.7066"
          }
        },
        {
          "trailerNumber": "383169",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "942528",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "CTLZ480422",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "739504",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "MTRZ547033",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "748288",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "CTLZ535685",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ882266",
          "location": {
            "latitude": "41.6202",
            "longitude": "-93.5887"
          }
        },
        {
          "trailerNumber": "CTLZ483039",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "219249",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "PIFZ003927",
          "location": {
            "latitude": "32.7922",
            "longitude": "-96.6646"
          }
        },
        {
          "trailerNumber": "872559",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "184136",
          "location": {
            "latitude": "44.999",
            "longitude": "-93.2142"
          }
        },
        {
          "trailerNumber": "748308",
          "location": {
            "latitude": "41.7532",
            "longitude": "-87.8729"
          }
        },
        {
          "trailerNumber": "MAR560049",
          "location": {
            "latitude": "39.6834",
            "longitude": "-84.2292"
          }
        },
        {
          "trailerNumber": "NONZ008735",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        },
        {
          "trailerNumber": "USAZ883107",
          "location": {
            "latitude": "0.0",
            "longitude": "0.0"
          }
        }
      ]
    },
    "buildings": [
      {
        "buildingOutline": [
          {
            "latitude": "38.1307294343999",
            "longitude": "-85.7179713249206"
          },
          {
            "latitude": "38.1311218628913",
            "longitude": "-85.717855989933"
          },
          {
            "latitude": "38.1312980330601",
            "longitude": "-85.7177942991257"
          },
          {
            "latitude": "38.1313317901697",
            "longitude": "-85.717951208353"
          },
          {
            "latitude": "38.1313465589002",
            "longitude": "-85.7179498672485"
          },
          {
            "latitude": "38.1314256770485",
            "longitude": "-85.7183468341827"
          },
          {
            "latitude": "38.131415127967",
            "longitude": "-85.7183521986008"
          },
          {
            "latitude": "38.1314235672323",
            "longitude": "-85.718397796154"
          },
          {
            "latitude": "38.1314372810364",
            "longitude": "-85.7183964550495"
          },
          {
            "latitude": "38.1315153441796",
            "longitude": "-85.7187907397747"
          },
          {
            "latitude": "38.1316029013895",
            "longitude": "-85.7192346453667"
          },
          {
            "latitude": "38.1315965719562",
            "longitude": "-85.7192467153072"
          },
          {
            "latitude": "38.1316493172173",
            "longitude": "-85.7195055484772"
          },
          {
            "latitude": "38.131699952632",
            "longitude": "-85.7197603583336"
          },
          {
            "latitude": "38.1314953009481",
            "longitude": "-85.719832777977"
          },
          {
            "latitude": "38.1314889715054",
            "longitude": "-85.7198032736778"
          },
          {
            "latitude": "38.1315311677795",
            "longitude": "-85.7197898626328"
          },
          {
            "latitude": "38.1314341163126",
            "longitude": "-85.7193191349506"
          },
          {
            "latitude": "38.1312832643198",
            "longitude": "-85.71857213974"
          },
          {
            "latitude": "38.1310786114675",
            "longitude": "-85.7186405360699"
          },
          {
            "latitude": "38.1311672241131",
            "longitude": "-85.7191099226475"
          },
          {
            "latitude": "38.1311872674347",
            "longitude": "-85.719105899334"
          },
          {
            "latitude": "38.1312073107508",
            "longitude": "-85.7191850244999"
          },
          {
            "latitude": "38.1311893772577",
            "longitude": "-85.719195753336"
          },
          {
            "latitude": "38.1312895937803",
            "longitude": "-85.7196906208992"
          },
          {
            "latitude": "38.1312421228131",
            "longitude": "-85.7197080552578"
          },
          {
            "latitude": "38.1311440160497",
            "longitude": "-85.719209164381"
          },
          {
            "latitude": "38.1311239727162",
            "longitude": "-85.7192158699036"
          },
          {
            "latitude": "38.131108149028",
            "longitude": "-85.7191340625286"
          },
          {
            "latitude": "38.1311239727162",
            "longitude": "-85.7191313803196"
          },
          {
            "latitude": "38.1310269207078",
            "longitude": "-85.7186472415924"
          },
          {
            "latitude": "38.1308233220536",
            "longitude": "-85.7187142968178"
          },
          {
            "latitude": "38.1309182645019",
            "longitude": "-85.7191890478134"
          },
          {
            "latitude": "38.1309330333161",
            "longitude": "-85.7191890478134"
          },
          {
            "latitude": "38.1309509668722",
            "longitude": "-85.7192668318748"
          },
          {
            "latitude": "38.1309351431464",
            "longitude": "-85.7192775607109"
          },
          {
            "latitude": "38.1310364149317",
            "longitude": "-85.7197791337967"
          },
          {
            "latitude": "38.1309910536286",
            "longitude": "-85.7197965681553"
          },
          {
            "latitude": "38.1308845072011",
            "longitude": "-85.719288289547"
          },
          {
            "latitude": "38.1308729031253",
            "longitude": "-85.719299018383"
          },
          {
            "latitude": "38.130851804801",
            "longitude": "-85.719209164381"
          },
          {
            "latitude": "38.1308676285448",
            "longitude": "-85.719209164381"
          },
          {
            "latitude": "38.1307684663605",
            "longitude": "-85.7187277078629"
          },
          {
            "latitude": "38.1305690866673",
            "longitude": "-85.7187974452972"
          },
          {
            "latitude": "38.1306640294463",
            "longitude": "-85.7192721962929"
          },
          {
            "latitude": "38.130677743393",
            "longitude": "-85.7192654907704"
          },
          {
            "latitude": "38.1306956770118",
            "longitude": "-85.7193513214588"
          },
          {
            "latitude": "38.1306809081496",
            "longitude": "-85.7193580269814"
          },
          {
            "latitude": "38.1307790155354",
            "longitude": "-85.719843506813"
          },
          {
            "latitude": "38.1307674114429",
            "longitude": "-85.7198515534401"
          },
          {
            "latitude": "38.1307969491294",
            "longitude": "-85.7200044393539"
          },
          {
            "latitude": "38.1307758507831",
            "longitude": "-85.720012485981"
          },
          {
            "latitude": "38.1307410384985",
            "longitude": "-85.7198676466942"
          },
          {
            "latitude": "38.1307325991543",
            "longitude": "-85.7198569178581"
          },
          {
            "latitude": "38.1306334367865",
            "longitude": "-85.7193727791309"
          },
          {
            "latitude": "38.1306197228314",
            "longitude": "-85.7193794846535"
          },
          {
            "latitude": "38.1305986244339",
            "longitude": "-85.7192923128605"
          },
          {
            "latitude": "38.1306144482326",
            "longitude": "-85.719290971756"
          },
          {
            "latitude": "38.1305142307831",
            "longitude": "-85.7188068330288"
          },
          {
            "latitude": "38.1300922610652",
            "longitude": "-85.7189556956291"
          },
          {
            "latitude": "38.1301988086492",
            "longitude": "-85.7194773852825"
          },
          {
            "latitude": "38.1303064110019",
            "longitude": "-85.7200419902802"
          },
          {
            "latitude": "38.1300289653972",
            "longitude": "-85.7201358675957"
          },
          {
            "latitude": "38.1298127047837",
            "longitude": "-85.7190428674221"
          },
          {
            "latitude": "38.1293664674442",
            "longitude": "-85.7191877067089"
          },
          {
            "latitude": "38.1288495460756",
            "longitude": "-85.7193607091904"
          },
          {
            "latitude": "38.1284497207104",
            "longitude": "-85.71947067976"
          },
          {
            "latitude": "38.1280256291434",
            "longitude": "-85.7196262478828"
          },
          {
            "latitude": "38.1280825968106",
            "longitude": "-85.71991994977"
          },
          {
            "latitude": "38.1282144662399",
            "longitude": "-85.720562338829"
          },
          {
            "latitude": "38.1279074738401",
            "longitude": "-85.7206723093986"
          },
          {
            "latitude": "38.1275171379966",
            "longitude": "-85.7208010554314"
          },
          {
            "latitude": "38.1270888211288",
            "longitude": "-85.7209378480911"
          },
          {
            "latitude": "38.1267712742426",
            "longitude": "-85.7210451364517"
          },
          {
            "latitude": "38.1262532795136",
            "longitude": "-85.7212114334106"
          },
          {
            "latitude": "38.1258186252961",
            "longitude": "-85.7213522493839"
          },
          {
            "latitude": "38.1255496025785",
            "longitude": "-85.7214327156544"
          },
          {
            "latitude": "38.1250590291884",
            "longitude": "-85.7216030359268"
          },
          {
            "latitude": "38.1246001027102",
            "longitude": "-85.7217451930046"
          },
          {
            "latitude": "38.1243004801885",
            "longitude": "-85.7218484580517"
          },
          {
            "latitude": "38.12420869417",
            "longitude": "-85.721360296011"
          },
          {
            "latitude": "38.1239291153566",
            "longitude": "-85.7214514911175"
          },
          {
            "latitude": "38.1234754568907",
            "longitude": "-85.7215909659862"
          },
          {
            "latitude": "38.123147344011",
            "longitude": "-85.7217063009739"
          },
          {
            "latitude": "38.1230956476351",
            "longitude": "-85.721452832222"
          },
          {
            "latitude": "38.1234575214981",
            "longitude": "-85.7213401794434"
          },
          {
            "latitude": "38.1241612185979",
            "longitude": "-85.7211162149906"
          },
          {
            "latitude": "38.1240187916964",
            "longitude": "-85.7204416394234"
          },
          {
            "latitude": "38.124015626651",
            "longitude": "-85.7204188406468"
          },
          {
            "latitude": "38.1234786219595",
            "longitude": "-85.7205972075462"
          },
          {
            "latitude": "38.1229584938072",
            "longitude": "-85.7207715511322"
          },
          {
            "latitude": "38.122909962391",
            "longitude": "-85.7205221056938"
          },
          {
            "latitude": "38.1234701817757",
            "longitude": "-85.7203423976898"
          },
          {
            "latitude": "38.1241696587019",
            "longitude": "-85.720113068819"
          },
          {
            "latitude": "38.1245768925588",
            "longitude": "-85.7199816405773"
          },
          {
            "latitude": "38.1251560891315",
            "longitude": "-85.7197898626328"
          },
          {
            "latitude": "38.1258682097672",
            "longitude": "-85.719568580389"
          },
          {
            "latitude": "38.126536014363",
            "longitude": "-85.7193419337273"
          },
          {
            "latitude": "38.1269791040884",
            "longitude": "-85.7191997766495"
          },
          {
            "latitude": "38.1276722176222",
            "longitude": "-85.7189744710922"
          },
          {
            "latitude": "38.1282070815582",
            "longitude": "-85.7187947630882"
          },
          {
            "latitude": "38.1289571504173",
            "longitude": "-85.7185453176498"
          },
          {
            "latitude": "38.1294951697464",
            "longitude": "-85.7183763384819"
          },
          {
            "latitude": "38.1299667246033",
            "longitude": "-85.718223452568"
          },
          {
            "latitude": "38.1306513704162",
            "longitude": "-85.7179994881153"
          },
          {
            "latitude": "38.1307294343999",
            "longitude": "-85.7179713249206"
          }
        ]
      },
      {
        "buildingOutline": [
          {
            "latitude": "38.1317073369604",
            "longitude": "-85.7178653776646"
          },
          {
            "latitude": "38.1317674664638",
            "longitude": "-85.7181738317013"
          },
          {
            "latitude": "38.1318318155265",
            "longitude": "-85.7181470096111"
          },
          {
            "latitude": "38.1317716860762",
            "longitude": "-85.7178412377834"
          },
          {
            "latitude": "38.1317073369604",
            "longitude": "-85.7178653776646"
          }
        ]
      }
    ],
    "yards": [
      {
        "yardOutline": [
          {
            "latitude": "38.1331725839111",
            "longitude": "-85.7183361053467"
          },
          {
            "latitude": "38.1329531679539",
            "longitude": "-85.7186365127564"
          },
          {
            "latitude": "38.1327843859994",
            "longitude": "-85.7191514968872"
          },
          {
            "latitude": "38.1324130643257",
            "longitude": "-85.7204174995422"
          },
          {
            "latitude": "38.1314003592504",
            "longitude": "-85.723614692688"
          },
          {
            "latitude": "38.116009732725",
            "longitude": "-85.7236683368683"
          },
          {
            "latitude": "38.1158767861183",
            "longitude": "-85.7209673523903"
          },
          {
            "latitude": "38.1190305098389",
            "longitude": "-85.7199722528458"
          },
          {
            "latitude": "38.119812325264",
            "longitude": "-85.7197415828705"
          },
          {
            "latitude": "38.1210235423482",
            "longitude": "-85.7193875312805"
          },
          {
            "latitude": "38.1222516199932",
            "longitude": "-85.7190871238708"
          },
          {
            "latitude": "38.128362159718",
            "longitude": "-85.7174777984619"
          },
          {
            "latitude": "38.1307083360342",
            "longitude": "-85.7168340682984"
          },
          {
            "latitude": "38.1315185088999",
            "longitude": "-85.7168555259704"
          },
          {
            "latitude": "38.1321936460851",
            "longitude": "-85.7171773910522"
          },
          {
            "latitude": "38.1327168731083",
            "longitude": "-85.7176065444946"
          },
          {
            "latitude": "38.1331725839111",
            "longitude": "-85.7183361053467"
          }
        ]
      }
    ]
  }));
}