import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { catchError, Subject, takeUntil, tap, throwError } from 'rxjs';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ToastService } from 'src/app/services/toast.service';
import { NotificationUserLocationSettings } from 'src/app/types/NotificationUserLocationSettings';
import { NotificationSettingsUpdateType, NotificationUserLocationSettingsUpdates } from 'src/app/types/NotificationUserLocationSettingsUpdates';

@Component({
  selector: '[location-settings]',
  templateUrl: './location-settings.component.html',
  styleUrls: ['./location-settings.component.scss']
})
export class LocationSettingsComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() settings: NotificationUserLocationSettings;
  @Input() showStatusCheckboxes: boolean;
  @Input() dataOutOfDate: boolean;
  @Output() settingsChanged = new EventEmitter<NotificationUserLocationSettings>();

  statusChangeError: boolean = false;
  commentError: boolean = false;
  requestEditedError: boolean = false;
  errorToastId: string = 'notification-save-api-error';
  disconnect$: Subject<boolean> = new Subject<boolean>();

  constructor(private notificationsService: NotificationsService, private toastService: ToastService) {
    super();
  }

  ngOnInit() {
    this.notificationsService.toggleAllStatusSuccess.pipe(takeUntil(this.disconnect$)).subscribe(() => {
      this.statusChangeError = false;
    });

    this.notificationsService.toggleAllCommentsSuccess.pipe(takeUntil(this.disconnect$)).subscribe(() => {
      this.commentError = false;
    });

    this.notificationsService.toggleAllRequestEditedSuccess.pipe(takeUntil(this.disconnect$)).subscribe(() => {
      this.requestEditedError = false;
    });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  onStatusSettingsChanged($event: { newSettings: NotificationUserLocationSettings, oldSettings: string }) {
    this.settings.statusChange = $event.newSettings.statusChange;
    this.settings.statusChangeNotifications = $event.newSettings.statusChangeNotifications;

    this.saveSettings(NotificationSettingsUpdateType.STATUS_CHANGE).subscribe({
      next: () => {
        this.statusChangeError = false;
      },
      error: (error) => {
        this.statusChangeError = true;
        if (error?.error && typeof (error.error) === 'object' && error.error.Data?.ObjectJson) {
          let currentSettings: NotificationUserLocationSettings = JSON.parse(error.error.Data.ObjectJson);
          this.settings = {
            ...this.settings,
            statusChange: currentSettings.statusChange,
            statusChangeNotifications: currentSettings.statusChangeNotifications
          };
        }
        else {
          const originalSettings: NotificationUserLocationSettings = JSON.parse($event.oldSettings);
          this.settings = {
            ...this.settings,
            statusChange: originalSettings.statusChange,
            statusChangeNotifications: originalSettings.statusChangeNotifications
          };
        }
        
        this.settingsChanged.emit(this.settings);
      }
    });
  }

  changeCommentToggle(enabled: boolean) {
    this.settings.comment = enabled;

    this.saveSettings(NotificationSettingsUpdateType.COMMENT).subscribe({
      next: () => {
        this.commentError = false;
      },
      error: (error) => {
        this.commentError = true;
        if (error?.error && typeof (error.error) === 'object' && error.error.Data?.ObjectJson) {
          let currentSettings: NotificationUserLocationSettings = JSON.parse(error.error.Data.ObjectJson);
          this.settings.comment = currentSettings.comment;
        }
        else {
          this.settings.comment = !enabled;
        }

        this.settingsChanged.emit(this.settings);
      }
    });
  }

  changeRequestEditedToggle(enabled: boolean) {
    this.settings.requestEdited = enabled;

    this.saveSettings(NotificationSettingsUpdateType.REQUEST_EDITED).subscribe({
      next: () => {
        this.requestEditedError = false;
      },
      error: (error) => {
        this.requestEditedError = true;
        if (error?.error && typeof (error.error) === 'object' && error.error.Data?.ObjectJson) {
          let currentSettings: NotificationUserLocationSettings = JSON.parse(error.error.Data.ObjectJson);
          this.settings.requestEdited = currentSettings.requestEdited;
        }
        else {
          this.settings.requestEdited = !enabled;
        }

        this.settingsChanged.emit(this.settings);
      }
    });
  }

  saveSettings(updateType: NotificationSettingsUpdateType) {
    const updates: NotificationUserLocationSettingsUpdates = {
      settings: this.settings,
      updateType: updateType
    };

    this.settingsChanged.emit(this.settings);

    return this.notificationsService.saveLocationSettings(updates)
      .pipe(
        tap(updatedSetting => {
          this.settings.lastModifiedUtc = updatedSetting.lastModifiedUtc;
        }),
        catchError(error => {
          this.showError();
          return throwError(() => error);
        }));
  }

  showError() {
    this.toastService.pushToast({
      id: this.errorToastId,
      text: this.localize(this.langSection.Toast, this.langText.FailedToSaveChanges),
      duration: 10
    });
  }
}
