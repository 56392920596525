import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { take } from "rxjs";
import { ApiService } from "../api.service";
import { AppState } from "../app.state";
import { AuthService } from "../auth/auth.service";
import { LocalizableComponent } from "../components/localizable/localizable.component";
import { MapLoaderService } from "../services/map-loader.service";
import { StringService } from "../services/string.service";
import { ToastService } from "../services/toast.service";
import { LocationProfile } from "../types/locationProfile";
import { OnPropertyData, TrailerAsset } from "../types/onPropertyData";
import { Role } from "../types/role";
import { Toast, ToastType } from "../types/Toast";
import * as moment from "moment";
import { LangSection, LangText } from "../types/language";

@Component({
  selector: 'on-property',
  templateUrl: './on-property.component.html',
  styleUrls: ['./on-property.component.scss']
})

export class OnPropertyComponent extends LocalizableComponent implements OnInit {

  locationOptions: LocationProfile[] = [];
  filteredLocationOptions: LocationProfile[] = [];
  currentLocation: LocationProfile = null;
  locationSearchTerm: string = '';
  trailerListIsLoading: boolean = true;
  iconClass: string = 'search icon';
  trailerSearch: string = "";
  displayedTrailerCountMessage: string = "";

  filteredTrailers: TrailerAsset[] = [];
  allTrailers: TrailerAsset[] = [];
  mapLoaded: boolean = false;
  selectedTrailer: TrailerAsset;
  locationLoading: boolean;
  assetData: OnPropertyData;
  private errorToastId = 'on-property-get-error';

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private store: Store<AppState>,
    private stringService: StringService, private apiService: ApiService, private mapLoaderService: MapLoaderService,
    private toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.mapLoaderService.load()
      .then(_ => {
        this.mapLoaded = true;
        console.log('Bing maps loaded');
      });

    this.route.params
      .subscribe(params => {
        this.store
          .select(s => s.locationList)
          .pipe(take(1))
          .subscribe(state => {
            if (!(params.slicNumber && params.countryCode)) {
              let validLocations = state.locations.filter(l => this.hasLocationPermissions(l.slicName));
              if (validLocations?.length === 0) {
                this.router.navigateByUrl("/");
                return;
              }

              this.currentLocation = validLocations[0]?.profile;
              this.router.navigateByUrl(`trailer-reservation-system/onproperty/${this.currentLocation.countryCode}/${this.currentLocation.slicNumber}`);
              this.loadOnPropertyData();
            } else {
              let location = state.locations.find(loc => params.slicNumber == loc.slicNumber)?.profile;
              let validLocation = this.hasLocationPermissions(location?.slicName);
              if (!validLocation) {
                this.router.navigateByUrl("/");
                return;
              }

              this.currentLocation = location;
              this.loadOnPropertyData();
            }
          });
      });

    this.store.select(s => s.locationList)
      .pipe(take(1))
      .subscribe(locList => {
        this.locationOptions = locList.locations?.slice(1).map(l => {
          return l.profile;
        });

        this.filteredLocationOptions = [...this.locationOptions];
      });
  }

  private loadOnPropertyData() {
    this.locationLoading = true;
    this.mapLoaded = false;
    this.apiService.getOnPropertyDetailsForLocation(this.currentLocation)
      .subscribe({
        next: assetData => {
          this.mapLoaderService.load()
            .then(_ => {
              this.mapLoaded = true;
              this.assetData = assetData;
              this.allTrailers = assetData?.assets?.trailers?.sort((a, b) => a.trailerNumber > b.trailerNumber ? 1 : -1);
              this.filteredTrailers = this.allTrailers;
              this.trailerListIsLoading = false;
              this.getFilterString();
              this.locationLoading = false;
            });
        },
        error: _ => {
          this.toastService.pushToast({
            id: this.errorToastId,
            text: this.localize(this.langSection.Toast, this.langText.FailedToRetrieveOnPropertyData),
            duration: 10
          });
          this.locationLoading = false;
        }
      });
  }

  onLocationSelect(locProfile: LocationProfile) {
    this.trailerListIsLoading = true;
    this.router.navigateByUrl(`/onproperty/${locProfile.countryCode}/${locProfile.slicNumber}`, { replaceUrl: true });
  }

  filterLocations() {
    const trimmedNormalizedTerm = this.stringService.normalizeString(this.locationSearchTerm.trim().toLowerCase());
    this.filteredLocationOptions = this.locationOptions.filter(lp =>
      this.stringService.normalizeString(lp.slicName.toLowerCase()).includes(trimmedNormalizedTerm) ||
      this.stringService.normalizeString(lp.crdSlicName.toLowerCase()).includes(trimmedNormalizedTerm) ||
      this.stringService.normalizeString(lp.address.toLowerCase()).includes(trimmedNormalizedTerm));
  }

  clearSearch() {
    this.locationSearchTerm = '';
    this.filterLocations();
  }

  searchTrailers(val: string) {
    this.iconClass = this.trailerSearch === "" ? 'search icon' : 'clear icon';
    this.filteredTrailers = this.allTrailers.filter(ts => ts.trailerNumber.includes(val));
    this.getFilterString();
  }

  clearTrailerSearch() {
    this.trailerSearch = "";
    this.iconClass = 'search icon';
    this.filteredTrailers = this.allTrailers;
    this.getFilterString();
  }

  getFilterString() {
    if (this.filteredTrailers?.length > 0 && this.trailerSearch != '' && this.filteredTrailers?.length !== this.allTrailers?.length) {
      this.displayedTrailerCountMessage = `${this.localize(this.langSection.OnProperty, this.langText.Showing)} ${this.filteredTrailers.length} ${this.localize(this.langSection.OnProperty, this.langText.Of)} ${this.allTrailers.length} ${this.localize(this.langSection.OnProperty, this.langText.Trailers)}`;
    }
    else if (this.filteredTrailers?.length > 0 && this.filteredTrailers?.length === this.allTrailers?.length) {
      this.displayedTrailerCountMessage = `${this.localize(this.langSection.OnProperty, this.langText.Showing)} ${this.filteredTrailers.length} ${this.localize(this.langSection.OnProperty, this.langText.Trailers)}`;
    }
    else if (this.allTrailers?.length < 1) {
      this.displayedTrailerCountMessage = `${this.localize(this.langSection.OnProperty, this.langText.Showing)} 0 ${this.localize(this.langSection.OnProperty, this.langText.Trailers)}`;
    }
    else if (this.filteredTrailers?.length < 1 && this.allTrailers?.length > 0) {
      this.displayedTrailerCountMessage = `${this.localize(this.langSection.OnProperty, this.langText.Showing)} 0 ${this.localize(this.langSection.OnProperty, this.langText.Of)} ${this.allTrailers.length} ${this.localize(this.langSection.OnProperty, this.langText.Trailers)}`;
    }
    else {
      this.displayedTrailerCountMessage = `${this.localize(this.langSection.OnProperty, this.langText.Showing)} ${this.allTrailers?.length ?? 0} ${this.localize(this.langSection.OnProperty, this.langText.Trailers)}`;
    }
  }

  updateSelectedTrailer(trailer: TrailerAsset) {
    this.selectedTrailer = trailer;
  }

  exportTrailers() {
    const exportPayload = {
      countryCode: this.currentLocation.countryCode,
      slicNumber: this.currentLocation.slicNumber,
      slicAbbreviation: this.currentLocation.slicName,
      language: this.getLanguageCode()
    };


    const currentDateTime = moment(new Date());
    const exportFileName = `${this.localize(LangSection.OnProperty, LangText.TrailerListExportFileName)}_${currentDateTime.format('YYYYMMDD')}_${currentDateTime.format('HHmmss')}`

    this.apiService.exportTrailers(exportFileName, exportPayload).subscribe({
      next: () => {
        const successToast: Toast = { id: 'trailerExport', text: this.localize(LangSection.OnProperty, LangText.TrailerListExportSuccess), duration: 5000, toastType: ToastType.Success };
        this.toastService.pushToast(successToast);
      },
      error: () => {
        const errorToast: Toast = { id: 'trailerExport', text: this.localize(LangSection.OnProperty, LangText.TrailerListExportFailure), duration: 5000, toastType: ToastType.Error }
        this.toastService.pushToast(errorToast);
      }
    })
  }

  private hasLocationPermissions(slicName: string): boolean {
    return this.authService.hasLocation(slicName) && (this.authService.hasLocationRole(slicName, Role.ReadonlyUser) || this.authService.hasLocationRole(slicName, Role.User) || this.authService.hasLocationRole(slicName, Role.Admin))
  }
}