import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { NotificationsFilterService, NotificationSortPayload } from 'src/app/services/notifications-filter.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ToastService } from 'src/app/services/toast.service';
import { LocationProfile } from 'src/app/types/locationProfile';
import { NotificationSettingsErrors, NotificationUserLocationSettings } from 'src/app/types/NotificationUserLocationSettings';
import { NotificationSettingsUpdateType } from 'src/app/types/NotificationUserLocationSettingsUpdates';

@Component({
  selector: 'location-settings-grid',
  templateUrl: './location-settings-grid.component.html',
  styleUrls: ['./location-settings-grid.component.scss']
})
export class LocationSettingsGridComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() set locations(input: LocationProfile[]) {
    if (JSON.stringify(this._locations) === JSON.stringify(input))
      return;

    this._locations = input;
    if (this._locations?.length)
      this.locationSettings = this.mergeLocationsWithSettings(this._locations);
    this.updateAllHeaders();
  };
  @Input() totalLocationCount: number = 0;

  _locations: LocationProfile[] = [];
  locationSettings: NotificationUserLocationSettings[] = [];

  showStatusCheckboxes: boolean = false;
  statusChangeStatus = { allOn: false, allOff: true };
  commentStatus = { allOn: false, allOff: true };
  requestEditedStatus = { allOn: false, allOff: true };
  settingsInitialized: boolean = false;
  locationSearchTerm$ = this.notificationsFilterService.searchTerm$;
  locationColumnErrors = new NotificationSettingsErrors();
  disconnect$ = new Subject<boolean>();

  errorToastId: string = 'notification-save-api-error';

  dataOutOfDate: boolean = false;

  constructor(private notificationsService: NotificationsService, private apiService: ApiService, private authService: AuthService, private notificationsFilterService: NotificationsFilterService, private toastService: ToastService) {
    super();
  }

  ngOnInit() {
    this.notificationsFilterService.sortPayload$.pipe(takeUntil(this.disconnect$)).subscribe(s => {
      this.locationSettings = this.applySort(s, this.locationSettings);
    })

    this.notificationsService.notificationSettings$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(settings => {
        this.locationSettings = settings;
        if (this._locations?.length)
          this.locationSettings = this.mergeLocationsWithSettings(this._locations);
        this.updateAllHeaders();
        this.settingsInitialized = true;
      });

    this.notificationsService.notificationSettingsOutOfDate$.pipe(takeUntil(this.disconnect$)).subscribe(outOfDate => {
      this.dataOutOfDate = outOfDate;
    });
  }

  mergeLocationsWithSettings(locations: LocationProfile[]): NotificationUserLocationSettings[] {
    let settings = locations.map(location => {
      let setting = this.locationSettings?.find(s => s.countryCode === location.countryCode && s.slicNumber === location.slicNumber);
      if (setting) {
        setting.slicName = location.slicName;
        setting.customName = location.customName;
        setting.address = location.address;

        return setting;
      }

      return new NotificationUserLocationSettings(location);
    });

    return this.applySort(this.notificationsFilterService.sortPayload$.value, settings);
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  applySort(payload: NotificationSortPayload, locationSettings: NotificationUserLocationSettings[]) {
    let fnSortField;

    switch (payload.sortField) {
      case 'slicName':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => locationSetting.slicName ?? '';
        break;
      case 'statusChange':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => !locationSetting.statusChange;
        break;
      case 'comment':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => !locationSetting.comment;
        break;
      case 'requestEdited':
        fnSortField = (locationSetting: NotificationUserLocationSettings) => !locationSetting.requestEdited;
        break;
      default:
        fnSortField = (locationSetting: NotificationUserLocationSettings) => locationSetting.slicName ?? '';
        break;
    }

    return [...locationSettings].sort((settingA, settingB) => {
      let a = fnSortField(settingA);
      let b = fnSortField(settingB);
      let compare = 0;

      if (a < b) {
        compare = -1;
      }
      else if (a > b) {
        compare = 1;
      } else {
        compare = 0;
      }

      if (!payload.isAscending)
        compare = -compare;

      if (compare === 0) {
        if (settingA.slicName < settingB.slicName) {
          compare = -1;
        }
        else if (settingA.slicName > settingB.slicName) {
          compare = 1;
        }
        else {
          compare = 0;
        }
      }

      return compare;
    });
  }

  toggleStatusCheckboxes() {
    if (this.dataOutOfDate)
      return;

    this.showStatusCheckboxes = !this.showStatusCheckboxes;
  }

  updateStatusChangeHeader() {
    let enabledCount = this.locationSettings.filter(setting => setting.statusChange).length;
    this.statusChangeStatus.allOn = enabledCount !== 0 && enabledCount === this.locationSettings.length;
    this.statusChangeStatus.allOff = enabledCount === 0;
  }

  updateAllHeaders() {
    this.updateStatusChangeHeader();
    this.updateCommentHeader();
    this.updateRequestEditedHeader();
  }

  updateCommentHeader() {
    const enabledCount = this.locationSettings.filter(setting => setting.comment).length;
    this.commentStatus.allOn = enabledCount !== 0 && enabledCount === this.locationSettings.length;
    this.commentStatus.allOff = enabledCount === 0;
  }

  updateRequestEditedHeader() {
    const enabledCount = this.locationSettings.filter(setting => setting.requestEdited).length;
    this.requestEditedStatus.allOn = enabledCount !== 0 && enabledCount === this.locationSettings.length;
    this.requestEditedStatus.allOff = enabledCount === 0;
  }

  onSettingsChanged(settings: NotificationUserLocationSettings) {
    let index = this.locationSettings.findIndex(s => s.notificationSettingsId === settings.notificationSettingsId);
    if (index > -1) {
      this.locationSettings[index] = settings;
    }
    this.updateAllHeaders();
  }

  setAllStatusChangeToggles(enabled) {
    this.locationColumnErrors.statusChange = false;

    const originalLocationSettings = JSON.stringify(this.locationSettings);

    this.locationSettings.forEach(settings => {
      if (settings.statusChange !== enabled) {
        settings.statusChange = enabled;
        if (enabled) {
          let hasCheckedStatuses = Object.keys(settings.statusChangeNotifications).some(key => settings.statusChangeNotifications[key]);
          if (!hasCheckedStatuses) { // select all statuses
            Object.keys(settings.statusChangeNotifications).forEach(key => { settings.statusChangeNotifications[key] = true; });
          }
        }
      }
    });

    this.locationSettings = this.deepCopy(this.locationSettings);

    this.notificationsService.toggleAll(NotificationSettingsUpdateType.STATUS_CHANGE, enabled).subscribe({
      next: _ => {
        this.notificationsService.toggleAllStatusSuccess.emit();
      },
      error: _ => {
        const originalSettings = JSON.parse(originalLocationSettings);
        this.locationSettings.forEach(setting => {
          const originalSetting = originalSettings.find(s => s.notificationSettingsId === setting.notificationSettingsId);
          if (originalSetting) {
            setting.statusChange = originalSetting.statusChange;
            setting.statusChangeNotifications = originalSetting.statusChangeNotifications;
          }
        });

        this.locationSettings = this.deepCopy(this.locationSettings);
        this.locationColumnErrors.statusChange = true;
        this.showError();
        this.updateStatusChangeHeader();
      }
    });

    this.updateStatusChangeHeader();
  }

  deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  setAllCommentToggles(enabled: boolean) {
    this.locationColumnErrors.comment = false;

    const originalLocationSettings = JSON.stringify(this.locationSettings);
    this.locationSettings.forEach(settings => {
      if (settings.comment !== enabled) {
        settings.comment = enabled;
      }
    });
    this.locationSettings = this.deepCopy(this.locationSettings);

    this.notificationsService.toggleAll(NotificationSettingsUpdateType.COMMENT, enabled).subscribe({
      next: _ => {
        this.notificationsService.toggleAllCommentsSuccess.emit();
      },
      error: _ => {
        const settings: NotificationUserLocationSettings[] = JSON.parse(originalLocationSettings);
        this.locationSettings.forEach(setting => {
          const originalSetting = settings.find(s => s.notificationSettingsId === setting.notificationSettingsId);
          if (originalSetting) {
            setting.comment = originalSetting.comment;
          }
        });
        this.locationSettings = this.deepCopy(this.locationSettings);
        this.locationColumnErrors.comment = true;
        this.showError();
        this.updateCommentHeader();
      }
    });

    this.updateCommentHeader();
  }

  setAllRequestEditedToggles(enabled: boolean) {
    this.locationColumnErrors.requestEdited = false;

    const originalLocationSettings = JSON.stringify(this.locationSettings);
    this.locationSettings.forEach(settings => {
      if (settings.requestEdited !== enabled) {
        settings.requestEdited = enabled;
      }
    });
    this.locationSettings = this.deepCopy(this.locationSettings);

    this.notificationsService.toggleAll(NotificationSettingsUpdateType.REQUEST_EDITED, enabled).subscribe({
      next: _ => {
        this.notificationsService.toggleAllRequestEditedSuccess.emit();
      },
      error: _ => {
        const settings = JSON.parse(originalLocationSettings);
        this.locationSettings.forEach(setting => {
          const originalSetting = settings.find(s => s.notificationSettingsId === setting.notificationSettingsId);
          if (originalSetting) {
            setting.requestEdited = originalSetting.requestEdited;
          }
        });
        this.locationSettings = this.deepCopy(this.locationSettings);
        this.locationColumnErrors.requestEdited = true;
        this.showError();
        this.updateRequestEditedHeader();
      }
    });

    this.updateRequestEditedHeader();
  }

  onLocationSearch(searchTerm: string) {
    this.locationSearchTerm$.next(searchTerm);
    this.resetLocationColumnErrors();
  }

  clearLocationSearch() {
    this.locationSearchTerm$.next('');
    this.resetLocationColumnErrors();
  }

  resetLocationColumnErrors() {
    this.locationColumnErrors.statusChange = false;
    this.locationColumnErrors.comment = false;
    this.locationColumnErrors.requestEdited = false;
  }

  showError() {
    this.toastService.pushToast({
      id: this.errorToastId,
      text: this.localize(this.langSection.Toast, this.langText.FailedToSaveChanges),
      duration: 10
    });
  }

  settingsTrackBy(index: number, item: NotificationUserLocationSettings) {
    return item.notificationSettingsId;
  }
}
