import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { StringService } from 'src/app/services/string.service';
import { NotificationDeliveryType } from 'src/app/types/NotificationDeliveryType';

@Component({
  selector: 'delivery-type-modal',
  templateUrl: './delivery-type-modal.component.html',
  styleUrls: ['./delivery-type-modal.component.scss']
})
export class DeliveryTypeModalComponent extends LocalizableComponent implements OnDestroy, OnInit {
  @Input() modalRef: NgbModalRef;
  @Input() currentSettings: NotificationDeliveryType = null;
  @Input() upsId: string;

  emailPairs: { email: string, valid: boolean }[] = [{ email: '', valid: true }];
  saveFailed: boolean;
  emailRegex = /^([a-zA-Z0-9_\-'!#$%&*+/=?^`{|}~\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  emailAlertsEnabled: boolean = false;
  disconnect$ = new Subject<boolean>();
  maxEmailCount: number = 5;
  allEmailsHaveValue: boolean = false;
  allEmailsValid: boolean = false;
  canSubmit: boolean = false;

  constructor(private apiService: ApiService, private notificationsService: NotificationsService, private stringService: StringService, private featureFlagService: FeatureFlagService) {
    super();

    this.featureFlagService.sdkReady$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(() =>
        this.checkEmailFeatureFlags());
  }

  ngOnInit(): void {
    this.checkEmailFeatureFlags();
    this.validateEmails(this.currentSettings?.emails);
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  dismissModal() {
    this.modalRef.close();
  }

  submit() {
    let payload = {
      ...this.currentSettings,
      emails: this.emailAlertsEnabled ? this.emailPairs.map(e => e.email) : this.currentSettings.emails
    };
    if (payload.emails.length === 0)
      payload.emailNotification = false;

    this.notificationsService.saveNotificationDeliveryType(payload)
      .subscribe({
        next: () => {
          this.dismissModal();
          this.notificationsService.refreshDeliveryType();
        },
        error: () => {
          this.saveFailed = true;
        }
      })
  }

  validateEmails(emails: string[]) {
    if (emails && emails.length > 0) {
      this.emailPairs = emails.map(e => { return { email: e, valid: false } });

      emails.forEach((e, i) => {
        this.validateEmail(e, i);
      });
    }
  }

  validateEmail(emailText: string, index: number) {
    const validEmail = !emailText || this.emailRegex.test(this.stringService.normalizeString(emailText));
    this.emailPairs[index] = { email: emailText, valid: validEmail };

    this.checkAllEmailsValid();
    this.checkCanSubmit();
  }

  checkEmailFeatureFlags() {
    this.emailAlertsEnabled = this.featureFlagService.checkFeatureFlagBoolean('customer-notifications-email-alerts', false);
    this.validateEmails(this.currentSettings?.emails);
    if (!this.emailAlertsEnabled)
      this.modalRef?.close();
  }

  emailTrackBy(index, item) {
    return index;  
  }

  onAddEmailClick() {
    this.emailPairs.push({ email: '', valid: true});
    this.allEmailsValid = false;

    this.checkAllEmailsValid();
    this.checkCanSubmit();
  }

  onRemoveEmailClick(index: number) {
    this.emailPairs.splice(index, 1);

    if (this.emailPairs.length < 1) {
      this.emailPairs = [{ email: '', valid: true }]
      this.allEmailsValid = false;
    }
    this.checkAllEmailsValid();
    this.checkCanSubmit();
  }

  private checkAllEmailsValid() {
    this.allEmailsHaveValue = !this.emailPairs.some(x => !x.email);
    this.allEmailsValid = !this.emailPairs.some(x => !x.valid);
  }

  private checkCanSubmit() {
    this.canSubmit = this.allEmailsValid && (this.allEmailsHaveValue || this.emailPairs.length === 1);
  }
}
