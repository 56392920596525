import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { LocalizableComponent } from '../components/localizable/localizable.component';
import { LocationProfile, TimeData } from '../types/locationProfile';

@Injectable()

export class DateService extends LocalizableComponent {

  constructor() {
    super();
  }

  getLocalizedMonthDateFormat(date: Date) {
    switch (this.getLanguageCode()) {
      case 'fr': {
        return date.getDate() + ' ' + this.localizeValue(this.langSection.Months, date.toLocaleString('default', { month: 'long' })); // returns in format 21 novembre
      }
      default: {
        return (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0'); // returns in format 11/21
      }
    }
  }

  datesAreEqual(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

  private getBrowserTimeZoneOffset(): number {
    // Note the minus sign is due to the offset being
    // positive f the local timezone is behind UTC and
    // negative if it is ahead. Eastern time would be
    // positive, but in reality Eastern is lagging
    // UTC by 4 or 5 hours depending on daylight
    // savings. Request offsets are negative if the time
    // zone is behind UTC. We want to work in the same
    // coordinate system.
    return -1 * (new Date()).getTimezoneOffset();
  }

  // Return the browser's offset as we save it within the database (negative hours from UTC)
  getTranslatedBrowserOffset(): number {
    return this.getBrowserTimeZoneOffset() / 60;
  }

  getBrowserTimezoneAbbreviation() {
    const currentTimeZone = moment.tz.guess(true);
    const abbreviation = moment.tz(currentTimeZone).format("z");
    return abbreviation;
  }

  getOffsetAndTimezoneForLocation(utcDateString: string, location: LocationProfile): TimeData {
    if (!location?.timeData?.length || location.timeData.length > 2)
      return { offset: location?.timezoneOffset ?? 0, timeZone: location?.timezoneName ?? '' } as TimeData;

    const timeZoneData = location.timeData[0];

    if (location.timeData.length === 1)
      return timeZoneData;

    let startDate = moment.utc(timeZoneData.effectiveStartDate);
    let endDate = moment.utc(timeZoneData.effectiveEndDate);
    let offsetDate = utcDateString ? moment.utc(utcDateString) : moment.utc();

    return (offsetDate.isSameOrAfter(startDate) && offsetDate.isBefore(endDate)) ? timeZoneData : location.timeData[1];
  }

  getCurrentLocalizedTimeAsUTCString(location: LocationProfile): string {
    const timeData = this.getOffsetAndTimezoneForLocation(moment().toISOString(), location);
    return moment().utcOffset(timeData.offset).toISOString();
  }

  getLocalizedTimeAsUTCString(utcDateString: string, location: LocationProfile): string {
    const timeData = this.getOffsetAndTimezoneForLocation(utcDateString, location);
    return moment(utcDateString).utcOffset(timeData.offset).toISOString();
  }

  getLocalizedTimeAsDate(utcDateString: string, location: LocationProfile): Date {
    const timeData = this.getOffsetAndTimezoneForLocation(utcDateString, location);
    return this.getExactDate(utcDateString, timeData.offset);
  }

  getExactDate(utcDateString: string, timeoffset: number): Date {
    const utcMoment = moment.utc(utcDateString).utcOffset(timeoffset);
  
    const exactUtcDate = new Date(
      utcMoment.year(),
      utcMoment.month(),    // month is 0-based
      utcMoment.date(),
      utcMoment.hour(),
      utcMoment.minute(),
      utcMoment.second(),
      utcMoment.millisecond()
    );
  
    return exactUtcDate;
  }
}
