import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AppState } from '../app.state';
import { WhatFixService } from '../components/whatfix/whatfix.service';
import { loadInitialData } from '../store/actions/app.actions';
import { Role } from '../types/role';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService {
  initializedRoles: boolean = false;
  needsAdminRole: boolean = false;
  locationsLoadedEvent$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public auth: AuthService,
    public router: Router,
    private apiService: ApiService,
    private whatFix: WhatFixService,
    private store: Store<AppState>
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.needsAdminRole = route.data?.needsAdminRole ?? false;

    if (!this.auth.isAuthenticated()) {
      if (!this.auth.hasValidRefreshToken()) {
        this.router.navigate(['gateway'], { queryParams: route.queryParams });
        return false;
      }
      else {
        //Check refresh token
        return this.apiService.checkIfTokenRefreshRequired(false)
          .pipe(
            switchMap(() => {
              if (!this.auth.hasValidRefreshToken()) {
                this.router.navigate(['gateway'], { queryParams: route.queryParams });
                return of(false);
              }

              return this.checkRoles(state);
            })
          );
      }
    }
    else {
      return this.checkRoles(state);
    }
  }

  private checkRoles(state: RouterStateSnapshot): Observable<boolean> {
    if (!this.initializedRoles) {
      this.store.dispatch(loadInitialData());
      this.initializedRoles = true;
    }

    return new Observable<boolean>((observer) => {
      this.store
        .select(s => s.locationList)
        .pipe(filter(l => !!l.locations), first())
        .subscribe(_ => {
          if (this.needsAdminRole && !this.auth.hasRole(Role.Admin)) {
            this.router.navigate(['']);
            observer.next(false);
          }
          else if (state.url.indexOf('/no-locations') === -1 && !this.auth.hasRole(Role.User) && !this.auth.hasRole(Role.ReadonlyUser)) {
            this.router.navigate(['no-locations']);
            observer.next(false);
          }
          else if (state.url.indexOf('/add-request') !== -1 && !this.auth.hasRole(Role.User)) {
            this.router.navigate(['']);
            observer.next(false);
          }
          else {
            if (!this.whatFix.isInjected) {
              this.whatFix.initWhatFix();
            }

            if (!this.auth.loggedIn) {
              this.auth.loggedInEvent$.next(true);
            }

            this.locationsLoadedEvent$.next(true);

            observer.next(true);
          }

          observer.complete();
        });
    });
  }
}

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean => {
  return inject(AuthGuardService).canActivate(route, state);
}