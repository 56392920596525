import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AppState } from '../app.state';
import { WINDOW } from '../helpers/custom-window';
import { JWT } from '../types/jwt';
import { NcmUserRole, NcmUserType } from '../types/ncmUserType';
import { Role } from '../types/role';
import { LocationListState } from '../types/states/locationListState';

@Injectable()
export class AuthService implements OnDestroy {
  loggedInEvent$: Subject<boolean> = new Subject<boolean>();
  loggedOutEvent$: Subject<void> = new Subject<void>();
  rolesChanged$: Subject<boolean> = new Subject<boolean>();
  shouldFallback: boolean = false;
  cachedJwt: JWT = null;
  cacheTime: number = 0;
  disconnect$: Subject<boolean> = new Subject<boolean>();
  loggedIn: boolean;
  locationList: LocationListState;

  constructor(
    public jwtHelper: JwtHelperService,
    private apiService: ApiService,
    private router: Router,
    private store: Store<AppState>,
    @Inject(WINDOW) private window: Window
  ) {
    this.apiService.refreshedToken$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(_ => {
        this.loggedInEvent$.next(true);
      });

    this.loggedInEvent$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(_ => {
        this.loggedIn = true;
      });

    this.loggedOutEvent$
      .pipe(takeUntil(this.disconnect$))
      .subscribe(_ => {
        this.logout();
      });

    this.store
      .select(s => s.locationList)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(locationList => {
        this.locationList = locationList;
        this.rolesChanged$.next(true);
      });
  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  // ...
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Special case where token was set as invalid undefined string
    if (token === 'undefined') {
      console.log('Invalid JWT value of \'undefined\'. Logging out.');
      this.logout();
      return false;
    }

    if (token === null) {
      return false;
    }
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public hasRole(role: Role) {
    return this.locationList?.locations?.some(l => (l.role & role) === role) ?? false;
  }

  public hasLocationRole(slic: string, role: Role) {
    return this.locationList?.locations?.some(l => l.slicName === slic && (l.role & role) === role) ?? false;
  }

  public hasLocation(slic: string) {
    return this.locationList?.locations?.some(l => l.slicName === slic) ?? false;
  }

  public hasValidRefreshToken(): boolean {
    const refreshToken = localStorage.getItem('refresh');
    if (localStorage.getItem('refresh') !== null && !this.jwtHelper.isTokenExpired(refreshToken)) {
      return true;
    }
    return false;
  }

  public isProduction() {
    try {
      return this.getJwt().isProduction;
    }
    catch (err) {
      console.log("AuthServce Error: ", err.message);
    }
    return true;
  }

  public getFullName() {
    return this.getJwt()?.fullName;
  }

  public getUniqueName() {
    return this.getJwt()?.unique_name;
  }

  public isNcmUser() {
    return this.getJwt()?.ncmUserType === NcmUserType.Carrier || this.getJwt()?.ncmUserType === NcmUserType.Customer ||
      (this.getJwt()?.ncmUserType === NcmUserType.Upser && (this.getJwt()?.ncmUpsRole === NcmUserRole.CSR || this.getJwt()?.ncmUpsRole === NcmUserRole.Admin));
  }

  public login(username, password) {
    const obs$ = this.apiService.login({ 'username': username, 'password': password });

    obs$.pipe(take(1))
      .subscribe(response => this.handleRequestPostResponse(response), errors => this.handleRequestErrorResponse(errors));

    return obs$;
  }

  public authorize(code, state) {
    return new Promise((resolve, reject) => {
      this.apiService.authorize(code, state)
        .pipe(takeUntil(this.disconnect$))
        .subscribe(response => {
          this.handleRequestPostResponse(response);
          this.reportLoginMetrics();
          resolve(null);
        }, err => {
          this.handleRequestErrorResponse(err);
          reject(err);
        });
    })
  }

  private reportLoginMetrics() {
    let offset = new Date().getTimezoneOffset() * -1 / 60; // converts minutes to hours
    this.apiService.logMetrics({ timezoneOffset: offset })
      .pipe(take(1))
      .subscribe(
        _ => { console.log('User metrics logged.'); },
        err => { console.log(`Error logging user metrics. ${JSON.stringify(err ?? '')}`); }
      );
  }

  public getJwt(): JWT {
    if (!this.cachedJwt || (Date.now() - this.cacheTime) > 1000 * 5) {
      try {
        this.cachedJwt = this.jwtHelper.decodeToken(localStorage.getItem('token'));
      } catch (err) {
        console.log('Invalid JWT value. Logging out. Error: ', err.message);
        this.logout();
      }

      this.cacheTime = Date.now();
    }

    // Force logout if userId not contained in the JWT or if JWT has been removed.
    // Also, force logout if roles is still contained in JWT
    if (!this.cachedJwt?.trsUserId || this.cachedJwt?.roles) {
      this.logout();
    }

    return this.cachedJwt;
  }

  private handleRequestPostResponse(response) {
    localStorage.setItem('token', response.token);
    localStorage.setItem('refresh', response.refreshToken);

    const queryParams = JSON.parse(sessionStorage.getItem('queryParams'));
    if (queryParams)
      sessionStorage.removeItem('queryParams');

    this.router.navigate([''], { queryParams });
    this.loggedInEvent$.next(true);
  }

  private handleRequestErrorResponse(errors) {
    console.log(errors);
  }


  private logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    if (this.window.location.href.includes('://localhost')) {
      this.router.navigate(['gateway']);
      this.window.location.reload();
    } else {
      this.window.location.href = `https://www.ups.com/lasso/logout?returnto=${encodeURIComponent(this.window.location.href)}`;
    }
  }

}
