/// <reference path='../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts'/>
/// <reference path='../assets/types/canvas-overlay/canvas-overlay.d.ts'/>

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, UrlSerializer } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { NgbDatepickerModule, NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { AppComponent } from './app.component';
import { AuthGuard, AuthGuardService } from './auth/auth-guard.service';
import { AuthComponent } from './auth/auth.component';
import { AuthService } from './auth/auth.service';
import { GatewayComponent } from './auth/gateway.component';
import { LoginFallbackGuard, LoginFallbackGuardService } from './auth/login-fallback.guard';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { LocationAddressSearchInputComponent } from './components/location-address-search-input/location-address-search-input.component';
import { LocationSearchInput } from './components/location-search-input/location-search-input.component';
import { NotificationLocationDataStaleToast } from './components/notification-location-data-stale-toast/notification-location-data-stale-toast.component';
import { NotificationComponent } from './components/notification/notification.component';
import { OnOffToggleComponent } from './components/on-off-toggle/on-off-toggle.component';
import { OnOffTriToggleComponent } from './components/on-off-tri-toggle/on-off-tri-toggle.component';
import { OnPropertyMapComponent } from './components/on-property-map/on-property-map.component';
import { StorageAccountDownToast } from './components/storage-account-down-toast/storage-account-down-toast.component';
import { ToastComponent } from './components/toast/toast.component';
import { UpsSelectComponent } from './components/ups-select/ups-select.component';
import { UserDataStaleToast } from './components/user-data-stale-toast/user-data-stale-toast.component';
import { WhatFixService } from './components/whatfix/whatfix.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { CancelModal } from './home/cancel-modal/cancel-modal.component';
import { CommentsModal } from './home/comments-modal/comments-modal.component';
import { ContactModal } from './home/contact-modal/contact-modal.component';
import { CustomNameModalComponent } from './home/custom-name-modal/custom-name-modal.component';
import { ExportCommentsModalComponent } from './home/export-comments-modal/export-comments-modal.component';
import { FilterFormComponent } from './home/filter-form/filter-form.component';
import { FullPageModalComponent } from './home/full-page-modal/full-page-modal.component';
import { HomeComponent } from './home/home.component';
import { LocationListComponent } from './home/location-list/location-list.component';
import { LocationComponent } from './home/location-list/location/location.component';
import { RequestGridRowComponent } from './home/trailer-request/request-grid-row.component';
import { RequestInfoComponent } from './home/trailer-request/request-info/request-info.component';
import { RequestProgressComponent } from './home/trailer-request/request-progress/request-progress.component';
import { RequestRejectionComponent } from './home/trailer-request/request-rejection/request-rejection.component';
import { RequestTrailerComponent } from './home/trailer-request/request-trailer/request-trailer.component';
import { CustomHttpInterceptor } from './interceptors/custom-http.interceptor';
import { AddLocationUsersComponent } from './location-users/add-location-users/add-location-users.component';
import { CanDeactivateAddLocationUsersForm, CanDeactivateAddLocationUsersFormService } from './location-users/add-location-users/add-location-users.deactivator';
import { AddUserFormComponent } from './location-users/add-location-users/add-user-form/add-user-form.component';
import { ImportErrorModalComponent } from './location-users/add-location-users/import-error-modal/import-error-modal.component';
import { ImportInvalidEntriesModalComponent } from './location-users/add-location-users/import-invalid-entries-modal/import-invalid-entries-modal.component';
import { NewUserQueueLocationComponent } from './location-users/add-location-users/new-user-queue/new-user-queue-location/new-user-queue-location.component';
import { QueueUserComponent } from './location-users/add-location-users/new-user-queue/new-user-queue-location/queue-user/queue-user.component';
import { NewUserQueueComponent } from './location-users/add-location-users/new-user-queue/new-user-queue.component';
import { QueueUserModalComponent } from './location-users/add-location-users/new-user-queue/queue-user-modal/queue-user-modal.component';
import { LocationSearchComponent } from './location-users/location-search/location-search.component';
import { LocationSortComponent } from './location-users/location-sort/location-sort.component';
import { LocationHeaderComponent } from './location-users/location-user-grid/location-header/location-header.component';
import { LocationUserGridComponent } from './location-users/location-user-grid/location-user-grid.component';
import { UserAccessComponent } from './location-users/location-user-grid/user-access/user-access.component';
import { UserHeaderComponent } from './location-users/location-user-grid/user-header/user-header.component';
import { LocationUsersComponent } from './location-users/location-users.component';
import { RemoveUserModalComponent } from './location-users/remove-user-modal/remove-user-modal.component';
import { UserSortComponent } from './location-users/user-sort/user-sort.component';
import { UsersSearchComponent } from './location-users/users-search/users-search.component';
import { NoLocationComponent } from './no-location/no-location.component';
import { DeliveryTypeModalComponent } from './notification-settings/delivery-type-modal/delivery-type-modal.component';
import { LocationSettingsGridComponent } from './notification-settings/location-settings-grid/location-settings-grid.component';
import { LocationSettingsComponent } from './notification-settings/location-settings-grid/location-settings/location-settings.component';
import { NotificationLocationComponent } from './notification-settings/location-settings-grid/notification-location/notification-location.component';
import { NotificationTypeHeaderComponent } from './notification-settings/location-settings-grid/notification-type-header/notification-type-header.component';
import { StatusChangeNotificationsComponent } from './notification-settings/location-settings-grid/status-change-notifications/status-change-notifications.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { OnPropertyComponent } from './on-property/on-property.component';
import { TrailerListComponent } from './on-property/trailer-list/trailer-list.component';
import { TrailerComponent } from './on-property/trailer-list/trailer/trailer.component';
import { FilterDocumentStatusPipe } from './pipes/filter-document-status.pipe';
import { FilterLocationsListPipe } from './pipes/filter-location-list.pipe';
import { FilterUsersListPipe } from './pipes/filter-users-list.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LocationListItemPipe } from './pipes/location-list-item.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { DefaultResponsibleSiteInformationModalComponent } from './request-form/default-responsible-site-information-modal/default-responsible-site-information-modal.component';
import { RealignRequestFormComponent } from './request-form/realign/realign-request-form.component';
import { AddRequestComponent, LiveLoadhRequestFormComponent, RequestEmptyRequestFormComponent, RequestFormComponent, RetrieveEmptyhRequestFormComponent, RetrieveLoadRequestFormComponent, SwitchRequestFormComponent } from './request-form/request-form.component';
import { CanDeactivateRequestForm, CanDeactivateRequestFormService } from './request-form/request-form.deactivator';
import { SetupIncompleteModalComponent } from './request-form/setup-incomplete-modal/setup-incomplete-modal.component';
import { CustomUrlSerializer } from './serializer/custom-url-serializer';
import { DateService } from './services/date.service';
import { EnvironmentService } from './services/environment.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { LocationListService } from './services/location-list.service';
import { MaintenanceService } from './services/maintenance.service';
import { StorageAccountService } from './services/storage-account.service';
import { StringService } from './services/string.service';
import { UserDataService } from './services/user-data.service';
import { UserRoleService } from './services/user-role.service';
import { AppEffects } from './store/effects/app.effects';
import { LocationListEffects } from './store/effects/location-list.effects';
import { TrailerRequestEffects } from './store/effects/trailer-requests.effects';
import { filterReducer } from './store/reducers/filter.reducer';
import { locationListReducer } from './store/reducers/location-list.reducer';
import { locationUsersReducer } from './store/reducers/location-users.reducer';
import { trailerRequestsReducer } from './store/reducers/trailer-requests.reducer';
import { SystemMessageComponent } from './system-message/system-message.component';
import { UserAccessHistorySearchComponent } from './user-access-history/user-access-history-search/user-access-history-search.component';
import { UserAccessHistoryComponent } from './user-access-history/user-access-history.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DateRangePickerComponent,
    RequestGridRowComponent,
    RequestInfoComponent,
    RequestProgressComponent,
    RequestTrailerComponent,
    DatepickerComponent,
    UpsSelectComponent,
    LocationListComponent,
    FilterFormComponent,
    LocationComponent,
    RequestFormComponent,
    AddRequestComponent,
    SwitchRequestFormComponent,
    RetrieveLoadRequestFormComponent,
    LiveLoadhRequestFormComponent,
    RequestEmptyRequestFormComponent,
    RetrieveEmptyhRequestFormComponent,
    RequestDetailsComponent,
    BreadcrumbsComponent,
    HeaderComponent,
    FooterComponent,
    CancelModal,
    AuthComponent,
    NoLocationComponent,
    GatewayComponent,
    ContactModal,
    LocationSearchInput,
    LocalizedDatePipe,
    NotificationComponent,
    CommentsModal,
    RequestRejectionComponent,
    SystemMessageComponent,
    ReplacePipe,
    FilterUsersListPipe,
    FilterLocationsListPipe,
    LocationAddressSearchInputComponent,
    LocationUsersComponent,
    LocationUserGridComponent,
    LocationHeaderComponent,
    UserHeaderComponent,
    UserAccessComponent,
    UsersSearchComponent,
    LocationSearchComponent,
    RequestDetailsComponent,
    CustomNameModalComponent,
    RemoveUserModalComponent,
    FullPageModalComponent,
    FilterDocumentStatusPipe,
    AddLocationUsersComponent,
    AddUserFormComponent,
    NewUserQueueComponent,
    NewUserQueueLocationComponent,
    QueueUserComponent,
    QueueUserModalComponent,
    UserAccessHistoryComponent,
    UserAccessHistorySearchComponent,
    LocationSortComponent,
    UserSortComponent,
    UserDataStaleToast,
    StorageAccountDownToast,
    ImportErrorModalComponent,
    ImportInvalidEntriesModalComponent,
    ExportCommentsModalComponent,
    NotificationSettingsComponent,
    DeliveryTypeModalComponent,
    OnOffToggleComponent,
    NotificationLocationComponent,
    StatusChangeNotificationsComponent,
    NotificationTypeHeaderComponent,
    LocationSettingsGridComponent,
    LocationSettingsComponent,
    OnOffTriToggleComponent,
    ToastComponent,
    OnPropertyComponent,
    LocationListItemPipe,
    TrailerListComponent,
    TrailerComponent,
    OnPropertyMapComponent,
    OnPropertyComponent,
    SetupIncompleteModalComponent,
    DefaultResponsibleSiteInformationModalComponent,
    NotificationLocationDataStaleToast,
    RealignRequestFormComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      headerName: 'X-XSRF-TOKEN',
      cookieName: 'XSRF-TOKEN'
    }),
    VirtualScrollerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbTypeaheadModule,
    InfiniteScrollDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    RouterModule.forRoot([
      { path: 'gateway', component: GatewayComponent, pathMatch: 'full' },
      { path: 'login', component: AuthComponent, pathMatch: 'full', canActivate: [LoginFallbackGuard] },
      {
        path: 'trailer-reservation-system',
        children: [
          { path: '', component: HomeComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'paramsOrQueryParamsChange' },
          { path: 'no-locations', component: NoLocationComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          { path: 'add-request', component: RequestFormComponent, pathMatch: 'full', canActivate: [AuthGuard], canDeactivate: [CanDeactivateRequestForm] },
          { path: 'add-request/:location', component: RequestFormComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateRequestForm] },
          { path: 'add-request/:location/:requestType', component: RequestFormComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateRequestForm] },
          { path: 'location-users/add-users', pathMatch: 'full', component: AddLocationUsersComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateAddLocationUsersForm], data: { needsAdminRole: true } },
          { path: 'location-users', component: LocationUsersComponent, canActivate: [AuthGuard], data: { needsAdminRole: true }, runGuardsAndResolvers: 'paramsOrQueryParamsChange' },
          { path: 'onproperty/:countryCode/:slicNumber', component: OnPropertyComponent, canActivate: [AuthGuard] },
          { path: 'onproperty', component: OnPropertyComponent, canActivate: [AuthGuard] }
        ]
      },
      // Duplicate all routes within 'trailer-reservation-system' path to alleviate widespread changes for router navigation.
      // UPS Branding has enforced the URL to show as trs.ups.com/trailer-reservation-system now, but still need to support trs.ups.com/gateway
      { path: '', redirectTo: 'trailer-reservation-system', pathMatch: 'full' },
      { path: 'no-locations', redirectTo: 'trailer-reservation-system/no-locations', pathMatch: 'full' },
      { path: 'add-request', redirectTo: 'trailer-reservation-system/add-request', pathMatch: 'full' },
      { path: 'add-request/:location', redirectTo: 'trailer-reservation-system/add-request/:location', pathMatch: 'full' },
      { path: 'add-request/:location/:requestType', redirectTo: 'trailer-reservation-system/add-request/:location/:requestType', pathMatch: 'full' },
      { path: 'location-users/add-users', redirectTo: 'trailer-reservation-system/location-users/add-users', pathMatch: 'full' },
      { path: 'location-users', redirectTo: 'trailer-reservation-system/location-users', pathMatch: 'full' },
      { path: 'onproperty/:countryCode/:slicNumber', redirectTo: '/trailer-reservation-system/onproperty/:countryCode/:slicNumber', pathMatch: 'full' },
      { path: 'onproperty', redirectTo: '/trailer-reservation-system/onproperty', pathMatch: 'full' },
      { path: '**', redirectTo: 'trailer-reservation-system' },
    ], {}),
    StoreModule.forRoot({
      requestFilters: filterReducer,
      trailerRequests: trailerRequestsReducer,
      locationList: locationListReducer,
      locationUsers: locationUsersReducer
    }),
    EffectsModule.forRoot([TrailerRequestEffects, LocationListEffects, AppEffects])
  ],
  exports: [RouterModule],
  providers: [
    CanDeactivateRequestFormService,
    CanDeactivateAddLocationUsersFormService,
    AuthService,
    AuthGuardService,
    LoginFallbackGuardService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    WhatFixService,
    DateService,
    EnvironmentService,
    FeatureFlagService,
    StringService,
    UserRoleService,
    LoadingIndicatorService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    MaintenanceService,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    LocationListService,
    UserDataService,
    StorageAccountService,
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
