export enum Lang {
  English = "en",
  French = "fr"
}

export enum LangSection {
  Breadcrumb = "Breadcrumb",
  Term = "Term",
  Role = "Role",
  ServiceLevel = "ServiceLevel",
  TrailerType = "TrailerType",
  Sort = "Sort",
  RequestType = "RequestType",
  RequestCategory = "RequestCategory",
  Legal = "Legal",
  No = "No",
  ModalText = "ModalText",
  DatePicker = "DatePicker",
  HomePage = "HomePage",
  FilterChip = "FilterChip",
  TrailerCard = "TrailerCard",
  LocationList = "LocationList",
  AddRequestPage = "AddRequestPage",
  RequestFormPage = "RequestFormPage",
  Header = "Header",
  Footer = "Footer",
  CancelModal = "CancelModal",
  RequestDetailsPage = "RequestDetailsPage",
  LocationManagerPage = "LocationManagerPage",
  LocationUserPage = "LocationUserPage",
  LocationPage = "LocationPage",
  LocationManagerEditRolePage = "LocationManagerEditRolePage",
  NoLocationPage = "NoLocationPage",
  UserRolesPage = "UserRolesPage",
  AddUserPage = "AddUserPage",
  AddNewRolesPage = "AddNewRolesPage",
  EditUserPage = "EditUserPage",
  EditRolesPage = "EditRolesPage",
  UserLookupPage = "UserLookupPage",
  ContactModal = "ContactModal",
  Language = "Language",
  CommentsModal = "CommentsModal",
  CustomNameModal = "CustomNameModal",
  RemoveUserModal = "RemoveUserModal",
  TimeZones = "TimeZones",
  RequestRejection = "RequestRejection",
  Months = "Months",
  SystemMessage = "SystemMessage",
  UserAccessHistory = "UserAccessHistory",
  UserAccessActivity = "UserAccessActivity",
  Toast = "Toast",
  ImportUsersErrorModal = "ImportUsersErrorModal",
  InvalidImportEntriesModal = "InvalidImportEntriesModal",
  ExportModal = "ExportModal",
  NotificationSettings = "NotificationSettings",
  DeliveryTypeModal = "DeliveryTypeModal",
  OnProperty = "OnProperty"
}

export enum LangText {
  Locality = "Locality",
  Name = "Name",
  Home = "Home",
  UserLookup = "UserLookup",
  UserRoles = "UserRoles",
  LocationManager = "LocationManager",
  LocationUserManager = "LocationUserManager",
  EditLocation = "EditLocation",
  AddNewUser = "AddNewUser",
  EditUserRole = "EditUserRole",
  DeleteUsersFromLocation = "DeleteUsersFromLocation",
  AddNewRequest = "AddNewRequest",
  RequestDetails = "RequestDetails",
  YourEditsWillNotBeSaved = "YourEditsWillNotBeSaved",
  LoadMore1 = "LoadMore1",
  LoadMore2 = "LoadMore2",
  EditRoles = "EditRoles",
  Sort = "Sort",
  Planned = "Planned",
  DateFormatting = "DateFormatting",
  AdHoc = "AdHoc",
  TermsOfServiceUrl = "TermsOfServiceUrl",
  PrivacyPolicyUrl = "PrivacyPolicyUrl",
  Next = "Next",
  Cancel = "Cancel",
  TrailerReservationSystem = "TrailerReservationSystem",
  TrailerReservationSuite = "TrailerReservationSuite",
  Edit = "Edit",
  Delete = "Delete",
  New = "New",
  Submit = "Submit",
  Enabled = "Enabled",
  Disabled = "Disabled",
  Continue = "Continue",
  UpsId = "UpsId",
  RemoveSelectedUsers = "RemoveSelectedUsers",
  Phone = "Phone",
  By = "By",
  Email = "Email",
  Role = "Role",
  Yes = "Yes",
  No = "No",
  Location = "Location",
  Locations = "Locations",
  User = "User",
  Quantity = "Quantity",
  EmptyTrailer = "EmptyTrailer",
  Or = "Or",
  To = "To",
  Copyright = "Copyright",
  CopyrightDisclaimer = "CopyrightDisclaimer",
  Date = "Date",
  Reason = "Reason",
  AreYouSure = "AreYouSure",
  RequiredFieldNotice = "RequiredFieldNotice",
  Selected = "Selected",
  Administrator = "Administrator",
  ViewEdit = "ViewEdit",
  ViewOnly = "ViewOnly",
  Ground = "Ground",
  Air = "Air",
  NextDayAir = "NextDayAir",
  SecondDayAir = "SecondDayAir",
  ThreeDaySelect = "ThreeDaySelect",
  EarlyAM = "EarlyAM",
  TermsAndConditionsUrl = "TermsAndConditionsUrl",
  TechnologyAgreementUrl = "TechnologyAgreementUrl",
  Short = "Short",
  Long = "Long",
  Sunrise = "Sunrise",
  Preload = "Preload",
  Morning = "Morning",
  Day = "Day",
  Afternoon = "Afternoon",
  Local = "Local",
  Twilight = "Twilight",
  Night = "Night",
  Other = "Other",
  Delivery = "Delivery",
  LiveLoad = "LiveLoad",
  Switch = "Switch",
  ValidLocation = "ValidLocation",
  RetrieveEmpty = "RetrieveEmpty",
  WeekendNotificationTitle = "WeekendNotificationTitle",
  WeekendNotificationMessage = "WeekendNotificationMessage",
  RequestEmpty = "RequestEmpty",
  RetrieveLoad = "RetrieveLoad",
  TermsNotice1 = "TermsNotice1",
  TermsNotice2 = "TermsNotice2",
  TermsNotice3 = "TermsNotice3",
  TermsNotice4 = "TermsNotice4",
  TermsNotice5 = "TermsNotice5",
  DeleteNewRole = "DeleteNewRole",
  CancelAddUsersSingle = "CancelAddUsersSingle",
  CancelAddUsersMultiple1 = "CancelAddUsersMultiple1",
  CancelAddUsersMultiple2 = "CancelAddUsersMultiple2",
  DenyUserRequest = "DenyUserRequest",
  DeleteEditRole = "DeleteEditRole",
  RemoveUser = "RemoveUser",
  DeleteFromLocation = "DeleteFromLocation",
  StartDate = "StartDate",
  EndDate = "EndDate",
  Today = "Today",
  Clear = "Clear",
  Close = "Close",
  Header = "Header",
  AddRequest = "AddRequest",
  RequestType = "RequestType",
  TrailerType = "TrailerType",
  Destination = "Destination",
  DestinationSort = "DestinationSort",
  ServiceLevel = "ServiceLevel",
  EarliestDueDate = "EarliestDueDate",
  LatestDueDate = "LatestDueDate",
  Apply = "Apply",
  ClearFilters = "ClearFilters",
  Status = "Status",
  Type = "Type",
  EarliestTime = "EarliestTime",
  LatestTime = "LatestTime",
  UPSLocationCode = "UPSLocationCode",
  LocationName = "LocationName",
  SearchRequests = "SearchRequests",
  SortBy = "SortBy",
  FSNoticeTitle = "FSNoticeTitle",
  FSNoticeText = "FSNoticeText",
  TotalRequests = "TotalRequests",
  NotFulfilled = "NotFulfilled",
  Pending = "Pending",
  Approved = "Approved",
  Scheduled = "Scheduled",
  InTransit = "InTransit",
  OnProperty = "OnProperty",
  Completed = "Completed",
  Canceled = "Canceled",
  Canceling = "Canceling",
  ArriveTime = "ArriveTime",
  Arriving = "Arriving",
  Arrived = "Arrived",
  PullTime = "PullTime",
  Earliest = "Earliest",
  Latest = "Latest",
  Job = "Job",
  Tractor = "Tractor",
  FromUPS = "FromUPS",
  ToUPS = "ToUPS",
  Bay = "Bay",
  Pallets = "Pallets",
  Complete = "Complete",
  Details = "Details",
  Contact = "Contact",
  Due = "Due",
  SearchLocations = "SearchLocations",
  AllLocations = "AllLocations",
  InProgress = "InProgress",
  Description = "Description",
  CustomerLocation = "CustomerLocation",
  RequestWindow = "RequestWindow",
  EarliestDate = "EarliestDate",
  LatestDate = "LatestDate",
  LoadedTrailer = "LoadedTrailer",
  DestinationFacility = "DestinationFacility",
  TrailerNumber = "TrailerNumber",
  FillOneOfThree = "FillOneOfThree",
  FillOneOfTwo = "FillOneOfTwo",
  Pieces = "Pieces",
  Fullness = "Fullness",
  AdditionalInformation = "AdditionalInformation",
  RequestReason = "RequestReason",
  DeliveryNumber = "DeliveryNumber",
  PickupNumber = "PickupNumber",
  SpecialInstructions = "SpecialInstructions",
  IfYouContinue = "IfYouContinue",
  ThisRequestWillNotBeCreated = "ThisRequestWillNotBeCreated",
  ThisRequestWillNotBeSaved = "ThisRequestWillNotBeSaved",
  MustBeInTheFuture = "MustBeInTheFuture",
  MustBeWithin30DaysFromToday = "MustBeWithin30DaysFromToday",
  MustBeBeforeLatestPickup = "MustBeBeforeLatestPickup",
  MustBeAfterEarliestPickup = "MustBeAfterEarliestPickup",
  ThisFieldIsRequired = "ThisFieldIsRequired",
  MustBeValidPercent = "MustBeValidPercent",
  MustBeGreaterThanZero = "MustBeGreaterThanZero",
  ProfileOverview = "ProfileOverview",
  MyInformation = "MyInformation",
  Addresses = "Addresses",
  AccountsPayment = "AccountsPayment",
  Preferences = "Preferences",
  ViewPayBill = "ViewPayBill",
  LogOut = "LogOut",
  SignUp = "SignUp",
  PrivacyPolicy = "PrivacyPolicy",
  TermsConditions = "TermsConditions",
  PleaseEnterAReason = "PleaseEnterAReason",
  GeneralInformation = "GeneralInformation",
  RequestId = "RequestId",
  RequestInstructions = "RequestInstructions",
  UserId = "UserId",
  Created = "Created",
  Edited = "Edited",
  Rejected = "Rejected",
  LocationCode = "LocationCode",
  CustomLocationName = "CustomLocationName",
  CustomNameWarning = "CustomNameWarning",
  EditLocationName = "EditLocationName",
  NewLocationName = "NewLocationName",
  CurrentLocationName = "CurrentLocationName",
  PickupAddress = "PickupAddress",
  Users = "Users",
  Actions = "Actions",
  AddUsers = "AddUsers",
  AddNewUsers = "AddNewUsers",
  LastUpdated = "LastUpdated",
  EditRole = "EditRole",
  NewUserRequests = "NewUserRequests",
  Approve = "Approve",
  Deny = "Deny",
  ChangesSaved = "ChangesSaved",
  CustomNameRequired = "CustomNameRequired",
  LocationPermissions = "LocationPermissions",
  NoticeTitle = "NoticeTitle",
  NoticeText = "NoticeText",
  AddNewRoles = "AddNewRoles",
  Remove = "Remove",
  RemoveFromListedLocations = "RemoveFromListedLocations",
  AddAnother = "AddAnother",
  UserExistsAtLocation = "UserExistsAtLocation",
  DuplicateId = "DuplicateId",
  EmailInvalid = "EmailInvalid",
  RoleInvalid = "RoleInvalid",
  AddAnotherLocation = "AddAnotherLocation",
  LocationIsRequired = "LocationIsRequired",
  RoleIsRequired = "RoleIsRequired",
  NoInputError = "NoInputError",
  NoResultsError = "NoResultsError",
  ResponsibleUpsLocation = "ResponsibleUpsLocation",
  Comments = "Comments",
  Loading = "Loading",
  AddComment = "AddComment",
  PreviousComments = "PreviousComments",
  EST = "EST",
  EDT = "EDT",
  CST = "CST",
  CDT = "CDT",
  MST = "MST",
  MDT = "MDT",
  PST = "PST",
  PDT = "PDT",
  AST = "AST",
  ADT = "ADT",
  Attention = "Attention",
  Needed = "Needed",
  SelectResponse = "SelectResponse",
  CancelResponse = "CancelResponse",
  FulfillRequestOn = "FulfillRequestOn",
  AndProcessOn = "AndProcessOn",
  EarliestTimeAfterCriticalPullTime = "EarliestTimeAfterCriticalPullTime",
  NetworkOverCapacity = "NetworkOverCapacity",
  SubmittedAfterCutoffTime = "SubmittedAfterCutoffTime",
  VolumeDoesNotJustifyPickup = "VolumeDoesNotJustifyPickup",
  VolumeOverAllocation = "VolumeOverAllocation",
  Weather = "Weather",
  RequestMovedTo = "RequestMovedTo",
  RequestMovedToCanceledStatus = "RequestMovedToCanceledStatus",
  PriorComments = "PriorComments",
  FutureComments = "FutureComments",
  PeakSeasonDateRangeWarning = "PeakSeasonDateRangeWarning",
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
  NotPlanned = "NotPlanned",
  Displaying = "Displaying",
  BasedOnSearchResults = "BasedOnSearchResults",
  BasedOnSearchResultsLocationUsers = "BasedOnSearchResultsLocationUsers",
  PeakVolumeOverAllocation = "PeakVolumeOverAllocation",
  UPSHoliday = "UPSHoliday",
  CustomerHasSufficientEquipment = "CustomerHasSufficientEquipment",
  NoEquipmentAvailable = "NoEquipmentAvailable",
  BayNumber = "BayNumber",
  DueDate = "DueDate",
  PercentFull = "PercentFull",
  CustomerSelected = "CustomerSelected",
  TransferRequested = "TransferRequested",
  TransferApproved = "TransferApproved",
  TransferRejected = "TransferRejected",
  PickedUp = "PickedUp",
  Transferred = "Transferred",
  UndoTransfer = "UndoTransfer",
  UnreadComments = "UnreadComments",
  NoResponseFromCustomer = "NoResponseFromCustomer",
  InvalidTime = "InvalidTime",
  InvalidDate = "InvalidDate",
  OptionalLoadFields = "OptionalLoadFields",
  Gaylords = "Gaylords",
  Bags = "Bags",
  Irregs = "Irregs",
  InvalidFieldNotification = "InvalidFieldNotification",
  ServerFailureNotification = "ServerFailureNotification",
  Legal = "Legal",
  OneOfOne = "OneOfOne",
  DownForMaintenance = "DownForMaintenance",
  UpsIdExistsAtLocation = "UpsIdExistsAtLocation",
  CustomNameHyperlink = "CustomNameHyperlink",
  PendingRemoval = "PendingRemoval",
  Holiday = "Holiday",
  VolumeDidNotGenerate = "Volume did not generate",
  DuplicateRequest = "Duplicate request",
  OtherPleaseSpecify = "OtherPleaseSpecify",
  OtherReason = "OtherReason",
  SchedulingError = "Scheduling error",
  CoveredByPackageCenter = "Covered by Package Center",
  LocationUserManagement = "LocationUserManagement",
  SearchUsers = "SearchUsers",
  NoAccess = "NoAccess",
  NoLocationsMatch = "NoLocationsMatch",
  AllUsers = "AllUsers",
  NoUserRequestsToReviewPart1 = "NoUserRequestsToReviewPart1",
  NoUserRequestsToReviewPart2 = "NoUserRequestsToReviewPart2",
  StateProvinceTerritory = "StateProvinceTerritory",
  PendingRequests = "PendingRequests",
  InProgressRequests = "InProgressRequests",
  CompletedRequests = "CompletedRequests",
  UserQueueCancelMessage = "UserQueueCancelMessage",
  NewUserQueue = "NewUserQueue",
  EntriesListed = "EntriesListed",
  SearchAndAddLocations = "SearchAndAddLocations",
  AddToQueue = "AddToQueue",
  UserAccessHistory = "UserAccessHistory",
  UserInformation = "UserInformation",
  Time = "Time",
  Activity = "Activity",
  Submitted = "Submitted",
  Added = "Added",
  Removed = "Removed",
  Denied = "Denied",
  Updated = "Updated",
  Entry = "Entry",
  Entries = "Entries",
  SearchChangeHistory = "SearchChangeHistory",
  Confirm = "Confirm",
  DeleteUser = "DeleteUser",
  SortLocationsBy = "SortLocationsBy",
  TotalUsers = "TotalUsers",
  CommentRefresh = "CommentRefresh",
  ExportRequests = "ExportRequests",
  TrailerRequestsExportFilename = "TrailerRequestsExportFilename",
  Export = "Export",
  LocationUserExportFilename = "LocationUserExportFilename",
  NumberOfLocations = "NumberOfLocations",
  SortUsersBy = "SortUsersBy",
  UserDataIsStale = "UserDataIsStale",
  StorageAccountIsDown = "StorageAccountIsDown",
  Custom = "Custom",
  DownloadTemplate = "DownloadTemplate",
  LocationUserImportTemplateFilename = "LocationUserImportTemplateFilename",
  ImportUsers = "ImportUsers",
  ImportErrorHeader = "ImportErrorHeader",
  ImportErrorBody = "ImportErrorBody",
  EntryNeedsAttention = "EntryNeedsAttention",
  EntriesNeedAttention = "EntriesNeedAttention",
  CountryCode = "CountryCode",
  LocationSlic = "LocationSlic",
  InvalidImportEntriesHeader = "InvalidImportEntriesHeader",
  InvalidImportEntriesDueTo = "InvalidImportEntriesDueTo",
  InvalidImportEntriesUsersWereNotImported = "InvalidImportEntriesUsersWereNotImported",
  InvalidImportEntriesNote = "InvalidImportEntriesNote",
  InvalidImportEntriesTheseWere = "InvalidImportEntriesTheseWere",
  InvalidImportEntriesErrorsFound = "InvalidImportEntriesErrorsFound",
  NoResultsMatched = "NoResultsMatched",
  DriverCreatedRequest = "DriverCreatedRequest",
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  HoursOfOperation = "HoursOfOperation",
  NoAvailableInformation = "NoAvailableInformation",
  Open24Hours = "Open24Hours",
  Closed = "Closed",
  Volume = "Volume",
  NoVolume = "NoVolume",
  TrailerVolume = "TrailerVolume",
  TrailerDirection = "TrailerDirection",
  ExportCommentsHeader = "ExportCommentsHeader",
  ExportCommentsBodyText = "ExportCommentsBodyText",
  ExportCommentsBodyExplanation = "ExportCommentsBodyExplanation",
  ExportCommentsCheckLabel = "ExportCommentsCheckLabel",
  TractorOnly = "TractorOnly",
  PlanNotMet = "PlanNotMet",
  NotificationSettings = "NotificationSettings",
  DeliveryTypeHeader = "DeliveryTypeHeader",
  DeliveryTypeSubText = "DeliveryTypeSubText",
  On = "On",
  Off = "Off",
  DeliveryType = "DeliveryType",
  EmailNotifications = "EmailNotifications",
  TextNotifications = "TextNotifications",
  PhoneInvalid = "PhoneInvalid",
  AddEmailAddress = "AddEmailAddress",
  AddPhoneNumber = "AddPhoneNumber",
  SaveFailed = "SaveFailed",
  AttentionNeeded = "AttentionNeeded",
  StatusChange = "StatusChange",
  AllToggles = "AllToggles",
  Comment = "Comment",
  RequestEdited = "RequestEdited",
  UserRequest = "UserRequest",
  NotificationsToReceive = "NotificationsToReceive",
  StatusChangeOn = "StatusChangeOn",
  CommentOn = "CommentOn",
  RequestEditedOn = "RequestEditedOn",
  AdminLocations = "AdminLocations",
  FailedToSaveChanges = "FailedToSaveChanges",
  FailedToRetrieveOnPropertyData = "FailedToRetrieveOnPropertyData",
  OfSingle = "OfSingle",
  OfMultiple = "OfMultiple",
  SortInstructions = "SortInstructions",
  OnPropertyView = "OnPropertyView",
  CreatedAs = "CreatedAs",
  OpenOnPropertyView = "OpenOnPropertyView",
  FailedToExport = "FailedToExport",
  FailedToSubmit = "FailedToSubmit",
  SearchYourLocations = "SearchYourLocations",
  Showing = "Showing",
  Trailers = "Trailers",
  DayOP = "DayOP",
  DaysOP = "DaysOP",
  Of = "Of",
  Requests = "Requests",
  LocationSetupIncomplete = "LocationSetupIncomplete",
  LocationSetupIncompleteMessage = "LocationSetupIncompleteMessage",
  LocationIncomplete = "LocationIncomplete",
  InvalidUPSId = "InvalidUPSId",
  DestinationSetupIncompleteMessage = "DestinationSetupIncompleteMessage",
  DefaultResponsibleLocation = "DefaultResponsibleLocation",
  SearchAllTrailers = "SearchAllTrailers",
  NoDataForLocation = "NoDataForLocation",
  REALIGN = "REALIGN",
  NetworkCarrierManagement = "NetworkCarrierManagement",
  AddAnotherEmail = "AddAnotherEmail"
}
