import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, tap, throwError } from 'rxjs';
import { ApiService } from '../api.service';
import { NotificationDeliveryType } from '../types/NotificationDeliveryType';
import { NotificationUserLocationSettings } from '../types/NotificationUserLocationSettings';
import { NotificationSettingsUpdateType, NotificationUserLocationSettingsUpdates } from '../types/NotificationUserLocationSettingsUpdates';
import { UserLocation } from '../types/userLocation';
import { LocationListService } from './location-list.service';

type saveLocationSettingsResponse = {
  updated: NotificationUserLocationSettings,
  previous: NotificationUserLocationSettings,
  saved: NotificationUserLocationSettings,
  outOfDate?: boolean
};

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  REFRESH_NOTIFICATION_SETTINGS_RATE = 30 * 1000;
  private notificationRefreshInterval: number = 0;

  public notificationSettingsSubject = new BehaviorSubject<NotificationUserLocationSettings[]>([]);
  public notificationSettings$: Observable<NotificationUserLocationSettings[]> = this.notificationSettingsSubject.asObservable();

  public deliveryTypeSubject = new BehaviorSubject<NotificationDeliveryType>(null);
  public deliveryType$: Observable<NotificationDeliveryType> = this.deliveryTypeSubject.asObservable();

  public notificationSettingsOutOfDate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public userLocations$ = new BehaviorSubject<UserLocation[]>([]);
  public refreshNotificationLocationData: EventEmitter<void> = new EventEmitter();

  savingDeliveryType: boolean = false;
  pendingDeliveryTypeChange: NotificationDeliveryType = null;
  deliveryTypeSaved$: Subject<NotificationDeliveryType>;
  deliveryTypelastModifiedUtc: string = null;

  public toggleAllStatusSuccess: EventEmitter<void> = new EventEmitter();
  public toggleAllCommentsSuccess: EventEmitter<void> = new EventEmitter();
  public toggleAllRequestEditedSuccess: EventEmitter<void> = new EventEmitter();

  private pauseDeliveryTypeRefresh: boolean = false;
  private saveLocationSettingsCount: number = 0;

  constructor(private apiService: ApiService, private locationListService: LocationListService) { }

  public saveLocationSettings(updates: NotificationUserLocationSettingsUpdates) {
    this.saveLocationSettingsCount++;
    return this.apiService.saveNotificationLocationSettings(updates)
      .pipe(
        tap(_ => this.saveLocationSettingsFinished()),
        catchError(err => {
          this.saveLocationSettingsFinished();
          return throwError(() => err);
        })
      );
  }

  public toggleAll(updateType: NotificationSettingsUpdateType, enabled: boolean) {
    this.saveLocationSettingsCount++;
    return this.apiService.toggleAllNotificationLocationSettings(updateType, enabled)
      .pipe(
        tap(_ => this.saveLocationSettingsFinished()),
        catchError(err => {
          this.saveLocationSettingsFinished();
          return throwError(() => err);
        })
      )
  }

  public saveNotificationDeliveryType(deliveryTypeSettings: NotificationDeliveryType) {
    if (this.savingDeliveryType) {
      this.pendingDeliveryTypeChange = deliveryTypeSettings;
      if (this.deliveryTypeSaved$ == null)
        this.deliveryTypeSaved$ = new Subject<NotificationDeliveryType>();

      return this.deliveryTypeSaved$;
    }

    this.savingDeliveryType = true;
    this.pauseDeliveryTypeRefresh = true;
    if (this.deliveryTypelastModifiedUtc) {
      deliveryTypeSettings.lastModifiedUtc = this.deliveryTypelastModifiedUtc;
    }
    return this.apiService.saveNotificationDeliveryType(deliveryTypeSettings)
      .pipe(
        catchError(error => {
          if (error?.error === 'DataOutOfDate')
            this.notificationSettingsOutOfDate$.next(true);

          this.savingDeliveryType = false;

          if (this.pendingDeliveryTypeChange) {
            this.pendingDeliveryTypeChange = null;
            this.deliveryTypeSaved$.error(error);
            this.deliveryTypeSaved$ = null;
          }

          return throwError(() => error);
        }),
        tap((saved) => {
          this.deliveryTypelastModifiedUtc = saved.lastModifiedUtc;
          this.savingDeliveryType = false;
          if (this.pendingDeliveryTypeChange) {
            deliveryTypeSettings = this.pendingDeliveryTypeChange;
            this.pendingDeliveryTypeChange = null;
            deliveryTypeSettings.lastModifiedUtc = saved.lastModifiedUtc;
            this.saveNotificationDeliveryType(deliveryTypeSettings).subscribe();
          }
          else {
            if (this.deliveryTypeSaved$ != null) {
              this.deliveryTypeSaved$.next(saved);
              this.deliveryTypeSaved$ = null;
            }
            this.pauseDeliveryTypeRefresh = false;
          }
        })
      );
  }

  public startRefreshTimer() {
    this.refreshSettings();
    this.stopRefreshTimer();
    this.notificationRefreshInterval = setInterval(() => {
      this.refreshSettings();
    }, this.REFRESH_NOTIFICATION_SETTINGS_RATE) as any;
  }

  public stopRefreshTimer() {
    clearInterval(this.notificationRefreshInterval);
    this.notificationRefreshInterval = 0;
  }

  private refreshSettings() {
    this.notificationSettingsOutOfDate$.next(false);
    this.refreshDeliveryType();
    this.refreshNotificationSettings();
  }

  private refreshNotificationSettings() {
    if (this.saveLocationSettingsCount > 0)
      return;

    this.apiService.getNotificationLocationSettings().subscribe(settings => {
      if (this.saveLocationSettingsCount > 0 || JSON.stringify(settings) === JSON.stringify(this.notificationSettingsSubject.value))
        return;

      this.notificationSettingsSubject.next(settings);
    });
  }

  public refreshDeliveryType() {
    if (this.pauseDeliveryTypeRefresh)
      return;

    this.apiService.getNotificationDeliveryType().subscribe(deliveryType => {
      if (!this.pauseDeliveryTypeRefresh)
        this.deliveryTypeSubject.next(deliveryType);
    })
  }

  private saveLocationSettingsFinished() {
    this.saveLocationSettingsCount--;
    if (this.saveLocationSettingsCount < 0)
      this.saveLocationSettingsCount = 0;
  }
}
