import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as pako from 'pako';
import { NEVER, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { StorageAccountService } from './services/storage-account.service';
import { DestinationInfo } from './types/destinationInfo';
import { LocationInfo } from './types/locationInfo';
import { LocationName } from './types/locationName';
import { LocationProfile } from './types/locationProfile';
import { LocationRequestPayload } from './types/locationRequestPayload';
import { LocationTrailer } from './types/locationTrailer';
import { LocationUser } from './types/locationUser';
import { LocationUserImportResponse } from './types/locationUserImportResponse';
import { LocationUsersExportPayload } from './types/locationUsersExportPayload';
import { NotificationDeliveryType } from './types/NotificationDeliveryType';
import { NotificationUserLocationSettings } from './types/NotificationUserLocationSettings';
import { NotificationSettingsUpdateType, NotificationUserLocationSettingsUpdates } from './types/NotificationUserLocationSettingsUpdates';
import { OnPropertyData } from './types/onPropertyData';
import { SaveRequestResponse } from './types/saveRequestResponse';
import { TrailerRequestAudit } from './types/trailerRequestAudit';
import { TrailerRequestCommentsFilter } from './types/trailerRequestCommentsFilter';
import { TrailerRequestCommentsResponse } from './types/trailerRequestCommentsResponse';
import { TrailerRequestDataResponse } from './types/trailerRequestDataResponse';
import { TrailerRequestExportPayload } from './types/trailerRequestExportPayload';
import { TrailerRequestPayload } from './types/trailerRequestPayload';
import { TrailerRequestResponse } from './types/trailerRequestResponse';
import { TrailerTypeInfo } from './types/trailerTypeInfo';
import { User } from './types/user';
import { UserAccessHistory } from './types/userAccessHistory';
import { UserApiLoginMetrics } from './types/userApiLoginMetrics';
import { UserWithLocations } from './types/userWithLocations';


@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {
  disconnect$: Subject<boolean> = new Subject<boolean>();
  refreshedToken$: Subject<boolean> = new Subject<boolean>();

  constructor(public jwtHelper: JwtHelperService, private httpClient: HttpClient, private router: Router, private storageAccountService: StorageAccountService) {

  }

  ngOnDestroy() {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  login(payload): Observable<User> {
    return this.post<User>(`user/authenticate`, payload, true);
  }

  getOAuthRedirect(): Observable<Response> {
    return this.httpClient.get('api/user/oauth/redirect', { responseType: 'text' as 'text', observe: 'response' as any }) as any;
  }

  authorize(code: string, state: string): Observable<User> {
    return this.get<User>(`user/oauth/authorize?code=${code}&state=${state}`, true);
  }

  logMetrics(metrics: UserApiLoginMetrics) {
    return this.post(`user/metrics`, metrics);
  }

  getAllRequests(payload: TrailerRequestPayload): Observable<TrailerRequestDataResponse> {
    return this.post<TrailerRequestDataResponse>(`TrailerRequest/all`, payload);
  }

  exportRequests(filename: string, payload: TrailerRequestExportPayload): Observable<void> {
    return this.download(filename, `TrailerRequest/export`, payload);
  }

  exportLocationUsers(filename: string, payload: LocationUsersExportPayload): Observable<void> {
    return this.download(filename, `Location/export`, payload);
  }

  downloadUserImportTemplate(filename: string, language: string): Observable<void> {
    return this.download(filename, `Location/importTemplate?language=${language}`, null);
  }

  getTrailerRequestUpdates(requests: TrailerRequestResponse[]): Observable<TrailerRequestResponse[]> {
    return this.post<TrailerRequestResponse[]>(`TrailerRequest/updates`, requests.map(r => ({ requestId: r.requestId, lastTimestamp: r._ts })));
  }

  getTrailerRequest(requestId: string): Observable<TrailerRequestResponse | null> {
    let request = this.get<TrailerRequestResponse | null>(`TrailerRequest/${requestId}`);
    return request.pipe(map(data => this.locationTimeToBrowserTime(data)));
  }

  getTrailerRequestAudit(requestId: string): Observable<TrailerRequestAudit | null> {
    return this.get<TrailerRequestAudit | null>(`TrailerRequest/${requestId}/audit`);
  }

  getRequestComments(payload: TrailerRequestCommentsFilter): Observable<TrailerRequestCommentsResponse> {
    return this.post<TrailerRequestCommentsResponse>(`TrailerRequest/comments`, payload);
  }

  getUnreadCommentDates(): Observable<Date[]> {
    return this.get<Date[]>('TrailerRequest/commentDates');
  }

  getLocationNames(term: string): Observable<LocationName[]> {
    return this.get<LocationName[]>(`Location/search?term=${term}`);
  }

  getLocationNamesWithAddress(term: string): Observable<LocationName[]> {
    return this.post<LocationName[]>(`Location/searchWithAddress`, { term });
  }

  getAuthorizedLocationNames(term: string, userId: string): Observable<LocationName[]> {
    return this.get<LocationName[]>(`Location/auth?term=${term}&userId=${userId}`);
  }

  searchLocations(term: string, payload: LocationRequestPayload): Observable<LocationInfo[]> {
    return this.post<LocationInfo[]>(`Location/search?term=${term}`, payload);
  }

  getTrailerTypes(slic: string): Observable<LocationTrailer[]> {
    return this.get<LocationTrailer[]>(`TrailerRequest/trailerTypes/${slic}`);
  }

  getTrailerInfo(trailerType: string): Observable<TrailerTypeInfo> {
    return this.get<TrailerTypeInfo>(`TrailerRequest/trailerInfo/${trailerType}`);
  }

  getDestinationInfo(slic: string): Observable<DestinationInfo[]> {
    return this.get<DestinationInfo[]>(`TrailerRequest/destinationInfo/${slic}`);
  }

  createRequest(request: TrailerRequestResponse): Observable<SaveRequestResponse> {
    let adjustedRequest = this.browserTimeToLocationTime(request);
    return this.post<SaveRequestResponse>(`TrailerRequest/create/?userDateTime=${new Date().toUTCString()}`, adjustedRequest);
  }

  editRequest(request: TrailerRequestResponse, adjustForBrowserTime: boolean = true): Observable<SaveRequestResponse> {
    let adjustedRequest = (adjustForBrowserTime) ? this.browserTimeToLocationTime(request) : request;
    return this.post<SaveRequestResponse>(`TrailerRequest/?userDateTime=${new Date().toUTCString()}`, adjustedRequest);
  }

  cancelRequest(request: TrailerRequestResponse) {
    return this.post(`TrailerRequest/cancel`, request);
  }

  getComment(requestId: string): Observable<TrailerRequestResponse | null> {
    return this.get<TrailerRequestResponse | null>(`TrailerRequest/${requestId}`);
  }

  addComment(request: TrailerRequestResponse): Observable<SaveRequestResponse> {
    return this.post<SaveRequestResponse>(`TrailerRequest`, request);
  }

  getLocations() {
    return this.get<LocationProfile[]>(`Location/list`);
  }

  updateCustomName(location: LocationProfile) {
    return this.post(`Location/customName`, location);
  }

  getLocationBySlicAbbrev(slic: string) {
    return this.get<LocationProfile>(`Location/${slic}`);
  }

  getLocation(country: string, slicNumber: string) {
    return this.get<LocationProfile>(`Location/${country}/${slicNumber}`);
  }

  getDefaultResponsibleSiteFromLocation(country: string, slicNumber: string) {
    return this.get<LocationProfile>(`Location/${country}/${slicNumber}/defaultResponsibleSite`);
  }

  getUsersWithLocations(locations: any[]) {
    return this.post<UserWithLocations[]>(`Location/users`, locations);
  }

  getLocationUsers(slicNumber: string, countryCode: string) {
    return this.get<LocationUser[]>(`Location/${countryCode}/${slicNumber}/users`);
  }

  getLocationUser(slic: string, userId: string) {
    return this.get<LocationUser>(`Location/${slic}/users/${userId}`);
  }

  saveUser(slic: string, user: LocationUser) {
    return this.post(`Location/${slic}/users/${user.userId}`, user);
  }

  removeUserFromAllLocations(userId: string): Observable<null> {
    return this.delete(`Location/users/${userId}`);
  }

  addUsers(slic: string, users: LocationUser[]) {
    return this.post(`Location/${slic}/users`, users);
  }

  addLocationUsers(locations: LocationProfile[]) {
    return this.post(`Location/addusers`, locations);
  }

  getOnPropertyDetailsForLocation(location: LocationProfile) {
    return this.get<OnPropertyData>(`OnProperty/${location.countryCode}/${location.slicNumber}/${location.slicName}`);
  }

  searchAllLocations(searchTerm: string) {
    return this.get<LocationName>(`Location/search-all?term=${searchTerm}`)
  }

  async refreshToken() {
    try {
      const response = await this.post<User>(`User/token/refresh`, { token: localStorage.getItem('refresh') }, true).toPromise();
      if (!response.token || !response.refreshToken || response.token === 'undefined' || response.refreshToken === 'undefined') {
        console.log(`Invalid token response from refresh. Logging out. Response: ${JSON.stringify(response)}`);
        this.logout();
        return;
      }
      
      localStorage.setItem('token', response.token);
      localStorage.setItem('refresh', response.refreshToken);
      this.refreshedToken$.next(true);
    } catch (error) {
      console.log(`Invalid token exception from refresh. Logging out. Error: ${JSON.stringify(error)}`);
      this.logout();
    }
  }

  private getAuthHeader(): Observable<HttpHeaders> {
    const observable = new Observable<HttpHeaders>(sub => {
      const token = localStorage.getItem('token') || "";
      const compressed = pako.deflate(token);
      const fr = new FileReader();
      fr.onload = () => {
        const result = fr.result as string;
        sub.next(new HttpHeaders({ 'Authorization': `Bearer ${result.split('base64,')[1]}` }));
        sub.complete();
      }
      fr.readAsDataURL(new Blob([compressed]));
    });
    return observable;
  }

  public checkIfTokenRefreshRequired(bypass: boolean): Observable<boolean> {
    if (bypass)
      return of(false);
    
    const refreshToken = localStorage.getItem('refresh');

    if (!refreshToken || this.jwtHelper.isTokenExpired(refreshToken)) {
      this.router.navigate(['gateway']);
      return NEVER;
    }

    const token = localStorage.getItem('token');
    
    // If token is going to expire within 2 minutes, 
    // consider it expired so that we can refresh it now before it expires
    const tokenOffsetSeconds = 120;
    const isExpired = this.jwtHelper.isTokenExpired(token, tokenOffsetSeconds);
    return of(isExpired);
  }

  public CheckMaintenance(): Observable<boolean> {
    return this.get<boolean>(`user/maintenance`);
  }

  public StorageAccountIsDown(): Observable<boolean> {
    return this.get<boolean>(`StorageAccount/StorageAccountIsDown`);
  }

  public getUserAccessHistory(userId: string): Observable<UserAccessHistory> {
    return this.get<UserAccessHistory>(`user/getUserAccessHistory/${userId}`);
  }

  public importUsers(importUserFile: File, locale: string): Observable<LocationUserImportResponse> {
    return this.postWithFile(`location/importLocationUsers/${locale}`, importUserFile);
  }

  public getNotificationDeliveryType(): Observable<NotificationDeliveryType> {
    return this.get<NotificationDeliveryType>(`notifications/deliveryType`);
  }

  public saveNotificationDeliveryType(deliveryTypeSettings: NotificationDeliveryType) {
    return this.post<NotificationDeliveryType>(`notifications/deliveryType`, deliveryTypeSettings);
  }

  public getNotificationLocationSettings(): Observable<NotificationUserLocationSettings[]> {
    return this.get<NotificationUserLocationSettings[]>(`notifications/settings`);
  }

  public saveNotificationLocationSettings(updates: NotificationUserLocationSettingsUpdates) {
    return this.post<NotificationUserLocationSettings>(`notifications/settings-type`, updates);
  }

  public toggleAllNotificationLocationSettings(updateType: NotificationSettingsUpdateType, enabled: boolean) {
    return this.post(`notifications/toggle-all`, { enabled, updateType});
  }

  public checkIfNotificationSettingsOutOfDate(payload) {
    return this.post<boolean>(`notifications/out-of-date`, payload);
  }

  private get<T>(endpoint: string, allowAnonymous: boolean = false): Observable<T> {
    return this.callWithAuth<T>(this.getFunc<T>(endpoint), allowAnonymous);
  }

  private getFunc<T>(endpoint: string): (headers: HttpHeaders) => Observable<T> {
    return (auth) => {
      return this.httpClient.get<T>(`/api/${endpoint}`, {
        headers: auth
      }).pipe(catchError(error => this.handleApiError(error)));
    }
  }

  private post<T>(endpoint: string, payload: any, allowAnonymous: boolean = false): Observable<T> {
    return this.callWithAuth<T>(this.postFunc<T>(endpoint, payload), allowAnonymous);
  }

  private postWithFile<T>(endpoint: string, fileToUpload: File, allowAnonymous: boolean = false): Observable<T> {
    return this.callWithAuth<T>(this.postWithFileFunc<T>(endpoint, fileToUpload), allowAnonymous);
  }

  private postWithFileFunc<T>(endpoint: string, fileToUpload: File): (headers: HttpHeaders) => Observable<T> {
    return (auth) => {
      const formData: FormData = new FormData();
      formData.append('uploadedFile', fileToUpload);
      return this.httpClient.post<T>(`/api/${endpoint}`, formData, {
        headers: auth
      }).pipe(catchError(error => this.handleApiError(error)));
    }
  }

  private handleApiError(error: any) {
    if (error.status === 401) {
      this.router.navigate(['gateway']);
      return NEVER;
    } else if (error.status === 504) {
      localStorage.setItem("storageAccountDown", "true");
      this.storageAccountService.isDown.emit(true);
      return of(error);
    }

    return throwError(() => error);
  }

  private postFunc<T>(endpoint: string, payload: any): (headers: HttpHeaders) => Observable<T> {
    return (auth) => {
      return this.httpClient.post<T>(`/api/${endpoint}`, payload, {
        headers: auth
      }).pipe(catchError(error => this.handleApiError(error)));
    };
  }

  private delete<T>(endpoint: string, allowAnonymous: boolean = false): Observable<T> {
    return this.callWithAuth<T>(this.deleteFunc<T>(endpoint), allowAnonymous);
  }

  private deleteFunc<T>(endpoint: string) {
    return (auth) => {
      return this.httpClient.delete<T>(`/api/${endpoint}`, {
        headers: auth
      }).pipe(catchError(error => this.handleApiError(error)));
    }
  }

  private download(fileName: string, endpoint: string, payload: any, allowAnonymous: boolean = false): Observable<void> {
    return this.callWithAuth(this.downloadFunc(fileName, endpoint, payload), allowAnonymous);
  }

  private downloadFunc(fileName: string, endpoint: string, payload: any): (headers: HttpHeaders) => Observable<any> {
    return (auth) => {
      return this.httpClient.post(`/api/${endpoint}`, payload, {
        headers: auth,
        responseType: "blob"
      }).pipe(tap(response => {
        this.downloadFile(fileName, response);
      }));
    }
  }

  private downloadFile(fileName: string, file: Blob) {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = fileName;
    a.href = URL.createObjectURL(file);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }


  public browserTimeToLocationTime(request: TrailerRequestResponse): TrailerRequestResponse {
    // Adjust times from local to UTC for the location's time zone
    const browserOffset = this.browserTimeZoneOffset();
    let adjustedRequest = { ...request };

    // Calculate offsets for times that do have associated offsets:
    //
    // arrivalPickupDateTime
    // arrivingPickupDateTime
    // canceledPickupDateTime
    // earliestPickupDateTime
    // latestPickupDateTime
    // rejectedPickupDateTime
    // scheduledPickupDateTime
    //
    // Note: the calculated "offset difference" values *should* be integers
    // as you're adding that value to a date's hours value, which is an
    // integer. TypeScript has no concept of integer, so if the value
    // we're given is a number but not an integer, we'll need to convert
    // it into one (Math.floor, for example, keeping in mind the sign of
    // the offset, which could be negative here in the US/Canada). The
    // check for a zero value is looking for unset offset data. Currently
    // we only work with US and Canada time zones, none of which would ever
    // have an offset value of zero. If/when this goes global, remove
    // that check (but hopefuly you have all good data).
    //
    // Note: It would be a wise idea to keep the commented console log
    // source below. Helpful in debugging.
    if (adjustedRequest.timezoneOffset && adjustedRequest.arrivalPickupDateTime) {
      let arrivalOffsetDifference = this.locationTimeZoneOffsetMinutes(adjustedRequest.timezoneOffset) - browserOffset;
      if (arrivalOffsetDifference != 0) {
        let newDate = new Date(adjustedRequest.arrivalPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() - arrivalOffsetDifference);
        adjustedRequest.arrivalPickupDateTime = newDate.toISOString();
      }
    }

    if (adjustedRequest.timezoneOffset && adjustedRequest.arrivingPickupDateTime) {
      let arrivingOffsetDifference = this.locationTimeZoneOffsetMinutes(adjustedRequest.timezoneOffset) - browserOffset;
      if (arrivingOffsetDifference != 0) {
        let newDate = new Date(adjustedRequest.arrivingPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() - arrivingOffsetDifference);
        adjustedRequest.arrivingPickupDateTime = newDate.toISOString();
      }
    }

    if (adjustedRequest.timezoneOffset && adjustedRequest.canceledPickupDateTime) {
      let canceledOffsetDifference = this.locationTimeZoneOffsetMinutes(adjustedRequest.timezoneOffset) - browserOffset;
      if (canceledOffsetDifference != 0) {
        let newDate = new Date(adjustedRequest.canceledPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() - canceledOffsetDifference);
        adjustedRequest.canceledPickupDateTime = newDate.toISOString();
      }
    }

    if (adjustedRequest.timezoneOffset && adjustedRequest.earliestPickupDateTime) {
      let earliestOffsetDifference = this.locationTimeZoneOffsetMinutes(adjustedRequest.timezoneOffset) - browserOffset;
      if (earliestOffsetDifference != 0) {
        let newDate = new Date(adjustedRequest.earliestPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() - earliestOffsetDifference);
        adjustedRequest.earliestPickupDateTime = newDate.toISOString();
      }
    }

    if (adjustedRequest.timezoneOffset && adjustedRequest.latestPickupDateTime) {
      let latestOffsetDifference = this.locationTimeZoneOffsetMinutes(adjustedRequest.timezoneOffset) - browserOffset;
      if (latestOffsetDifference != 0) {
        let newDate = new Date(adjustedRequest.latestPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() - latestOffsetDifference);
        adjustedRequest.latestPickupDateTime = newDate.toISOString();
      }
    }

    if (adjustedRequest.timezoneOffset && adjustedRequest.rejectedPickupDateTime) {
      let rejectedOffsetDifference = this.locationTimeZoneOffsetMinutes(adjustedRequest.timezoneOffset) - browserOffset;
      if (rejectedOffsetDifference != 0) {
        let newDate = new Date(adjustedRequest.rejectedPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() - rejectedOffsetDifference);
        adjustedRequest.rejectedPickupDateTime = newDate.toISOString();
      }
    }

    if (adjustedRequest.timezoneOffset && adjustedRequest.scheduledPickupDateTime) {
      let scheduledOffsetDifference = this.locationTimeZoneOffsetMinutes(adjustedRequest.timezoneOffset) - browserOffset;
      if (scheduledOffsetDifference != 0) {
        let newDate = new Date(adjustedRequest.scheduledPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() - scheduledOffsetDifference);
        adjustedRequest.scheduledPickupDateTime = newDate.toISOString();
      }
    }

    return adjustedRequest;
  }

  public locationTimeToBrowserTime(request: TrailerRequestResponse): TrailerRequestResponse {
    // Adjust times to local from UTC for the location's time zone
    const browserOffset = this.browserTimeZoneOffset();

    // Calculate offsets for times that do have associated offsets:
    //
    // arrivalPickupDateTime
    // arrivingPickupDateTime
    // canceledPickupDateTime
    // earliestPickupDateTime
    // latestPickupDateTime
    // rejectedPickupDateTime
    // scheduledPickupDateTime
    if (request.timezoneOffset && request.arrivalPickupDateTime) {
      let arrivalOffsetDifference = this.locationTimeZoneOffsetMinutes(request.timezoneOffset) - browserOffset;
      if (arrivalOffsetDifference != 0) {
        let newDate = new Date(request.arrivalPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() + arrivalOffsetDifference);
        request.arrivalPickupDateTime = newDate.toISOString();
      }
    }

    if (request.timezoneOffset && request.arrivingPickupDateTime) {
      let arrivingOffsetDifference = this.locationTimeZoneOffsetMinutes(request.timezoneOffset) - browserOffset;
      if (arrivingOffsetDifference != 0) {
        let newDate = new Date(request.arrivingPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() + arrivingOffsetDifference);
        request.arrivingPickupDateTime = newDate.toISOString();
      }
    }

    if (request.timezoneOffset && request.canceledPickupDateTime) {
      let canceledOffsetDifference = this.locationTimeZoneOffsetMinutes(request.timezoneOffset) - browserOffset;
      if (canceledOffsetDifference != 0) {
        let newDate = new Date(request.canceledPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() + canceledOffsetDifference);
        request.canceledPickupDateTime = newDate.toISOString();
      }
    }

    if (request.timezoneOffset && request.earliestPickupDateTime) {
      let earliestOffsetDifference = this.locationTimeZoneOffsetMinutes(request.timezoneOffset) - browserOffset;
      if (earliestOffsetDifference != 0) {
        let newDate = new Date(request.earliestPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() + earliestOffsetDifference);
        request.earliestPickupDateTime = newDate.toISOString();
      }
    }

    if (request.timezoneOffset && request.latestPickupDateTime) {
      let latestOffsetDifference = this.locationTimeZoneOffsetMinutes(request.timezoneOffset) - browserOffset;
      if (latestOffsetDifference != 0) {
        let newDate = new Date(request.latestPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() + latestOffsetDifference);
        request.latestPickupDateTime = newDate.toISOString();
      }
    }

    if (request.timezoneOffset && request.rejectedPickupDateTime) {
      let rejectedOffsetDifference = this.locationTimeZoneOffsetMinutes(request.timezoneOffset) - browserOffset;
      if (rejectedOffsetDifference != 0) {
        let newDate = new Date(request.rejectedPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() + rejectedOffsetDifference);
        request.rejectedPickupDateTime = newDate.toISOString();
      }
    }

    if (request.timezoneOffset && request.scheduledPickupDateTime) {
      let scheduledOffsetDifference = this.locationTimeZoneOffsetMinutes(request.timezoneOffset) - browserOffset;
      if (scheduledOffsetDifference != 0) {
        let newDate = new Date(request.scheduledPickupDateTime);
        newDate.setUTCMinutes(newDate.getUTCMinutes() + scheduledOffsetDifference);
        request.scheduledPickupDateTime = newDate.toISOString();
      }
    }

    return request;

  }

  private browserTimeZoneOffset(): number {
    // Note the minus sign is due to the offset being
    // positive f the local timezone is behind UTC and
    // negative if it is ahead. Eastern time would be
    // positive, but in reality Eastern is lagging
    // UTC by 4 or 5 hours depending on daylight
    // savings. Request offsets are negative if the time
    // zone is behind UTC. We want to work in the same
    // coordinate system.
    return -1 * (new Date()).getTimezoneOffset();
  }

  private locationTimeZoneOffsetMinutes(offset: number): number {
    // Time zone offsets as stored in the database are decimal
    // values, like -4, -2.5, etc. These are hours.minutes,
    // but the browser offset is in minutes, so convert
    // the database request offset to minutes.
    return offset * 60;
  }

  private callWithAuth<T>(call: (headers: HttpHeaders) => Observable<T>, allowAnonymous: boolean): Observable<T> {
    return this.checkIfTokenRefreshRequired(allowAnonymous)
      .pipe(concatMap(needsRefresh => {
        if (needsRefresh) {
          return this.refreshToken();
        } else {
          return of({});
        }
      }), concatMap(() => this.getAuthHeader()), concatMap(call)
      );
  }

  private logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    if (window.location.href.includes('://localhost')) {
      this.router.navigate(['gateway']);
      window.location.reload();
    } else {
      window.location.href = `https://www.ups.com/lasso/logout?returnto=${encodeURIComponent(window.location.href)}`;
    }
  }
}